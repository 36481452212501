<template>
  <div class="vl-parent">
    <loading v-model:active="carregando" color="red" loader="dots" />
  </div>
  <NavBar />
  <dialogPedido v-if="dialogPedido.ativo == true"></dialogPedido>
  <RouterView v-if="!logado" v-slot="{ Component }">
    <keep-alive>
     
      <component :is="Component" />
    </keep-alive>
  </RouterView>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { RouterView } from "vue-router";
import { mapGetters } from "vuex";
import dialogPedido from "./components/dialogPedido.vue";
export default {
  components: {
    NavBar,
    RouterView,
    Loading,
    dialogPedido
  },
  name: "App",
  data: () => ({
    logado: false,
    autenticado: getAuth(),
    info: null,
  }),
  computed: {
    ...mapGetters(["carregando", "dialogPedido"]),
  },
  methods: {},
  async mounted() {
    // document.addEventListener('contextmenu', event => event.preventDefault());
    onAuthStateChanged(this.autenticado, (user) => {
      if (user) {
        this.logado = true;
      } else {
        this.logado = false;
      }
    });
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
  border: solid 20px white;
  border-radius: 25px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(177, 20, 20);
  border: solid 2px white;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 20, 20);
}
td,
tr {
  font-size: 12px;
}
.v-list {
  max-height: 200px;
}
.swal2-container {
  z-index: 2;
}
.v-overlay__scrim {
  z-index: 0;
}
.v-overlay--active {
  z-index: 1 !important;
}
</style>
