import { auth } from "@/main";
import axios from "axios";
import { store } from "../store/store";
const pako = require('pako');

export default function insertPedido(database, pedido) {
  store.commit("incrementCarregando", true);

  return new Promise((resolve, reject) => {
    
    if (auth.currentUser != null) {
        pedido = pako.deflate(JSON.stringify({pedido: pedido, empresa: database}));
      auth.currentUser
        ?.getIdToken(true)
        .then((log) => {
          axios({
            method: "post",
            data: {
              data: pedido
            },
            url: `${process.env.VUE_APP_APIURL}/pedidos/insertdata`,
            headers: {
              authtoken: log,
            },
          })
            .then((res) => {
              store.commit("incrementCarregando", false);
              resolve(res);
            })
            .catch((error) => {
              store.commit("incrementCarregando", false);
              reject(error);
            });
        })
        .catch((error) => {
          store.commit("incrementCarregando", false);
          reject(error);
        });
    } else {
      store.commit("incrementCarregando", false);
      resolve("Unauthorized");
    }
  });
}
