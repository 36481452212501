export default function imprimePedidos(pedido, thisDadosEmpresa, thisClientes) {
  var openWindow = window.open("br", "h1", "popup");
  var produtos = [];
  var i = 0;
  for (i in pedido.produtos) {
    for (var b = 0; b < pedido.produtos.length; b++) {
      if (pedido.produtos[b].tamSelecionado) {
        pedido.produtos[b].valor = parseFloat(
          String(pedido.produtos[b].valor).replace(/[,]/g, ".")
        );
      }
    }
  }

  for (i in pedido.produtos) {
    var precoAdicionais = 0;

    if (pedido.produtos[i].adicionaisSelecionados) {
      for (
        var c = 0;
        c < pedido.produtos[i].adicionaisSelecionados.length;
        c++
      ) {
        precoAdicionais += parseFloat(
          pedido.produtos[i].adicionaisSelecionados[c].valor.replace(",", ".") *
            pedido.produtos[i].quantidade
        );
      }
    }
    var precoSabores = 0;

    if (pedido.produtos[i].saborSelecionado) {
      for (var z = 0; z < pedido.produtos[i].saborSelecionado.length; z++) {
        precoSabores += parseFloat(
          pedido.produtos[i].saborSelecionado[z].valor.replace(",", ".") *
            pedido.produtos[i].quantidade
        );
      }
    }
    if (isNaN(pedido.produtos[i].valor)) {
      pedido.produtos[i].valor = pedido.produtos[i].valor.replace(",", ".");
    }
    produtos.push(
      `<div style="display:flex; justify-content: left; margin-top: -10px;">
            <p style="font-size: 12px;">${parseInt(i) + 1}, ${
        pedido.produtos[i].codbarras ? pedido.produtos[i].codbarras : ""
      }, ${pedido.produtos[i].nome},</p>
            
              </div>
              <div class="produto"  style="display:flex;  margin-top: -20px;">
                  <div style="width: 62%; display:flex; justify-content: left;">
                      <p style="font-size: 12px;">${
                        pedido.produtos[i].quantidade
                      } x ${parseFloat(pedido.produtos[i].valor).toFixed(2)}</p>
                  </div>
                  <div style="width:38%; display:flex; justify-content: right;">
                      <p style="font-size: 12px;">${(
                        pedido.produtos[i].valor * pedido.produtos[i].quantidade
                      ).toFixed(2)}</p>
                  </div>
              </div>
              ${
                pedido.produtos[i].saboresDesc != undefined
                  ? `<div style="display:flex; justify-content: left; margin-top: -20px">
                  <p style="font-size: 12px;">Sabores: </p>
                </div>
                <div style="display:flex;  margin-top: -20px;">
                    <div style="width: 62%; display:flex; justify-content: left;">
                        <p style="font-size: 12px;">${
                          pedido.produtos[i].saboresDesc
                        }</p>
                    </div>
                    <div style="width:38%; display:flex; justify-content: right;">
                        <p style="font-size: 12px;">${precoSabores.toFixed(
                          2
                        )}</p>
                    </div>
                </div>`
                  : ``
              }
                ${
                  pedido.produtos[i].adicionaisSelecionados &&
                  pedido.produtos[i].adicionaisSelecionados.length > 0
                    ? `<div style="display:flex; justify-content: left; margin-top: -20px">
                      <p style="font-size: 12px;">Adicionais: </p>
                    </div>
                    <div style="display:flex;  margin-top: -20px;">
                        <div style="width: 62%; display:flex; justify-content: left;">
                            <p style="font-size: 12px;">${
                              pedido.produtos[i].adicionaisDesc
                            }</p>
                        </div>
                        <div style="width:38%; display:flex; justify-content: right;">
                            <p style="font-size: 12px;">${precoAdicionais.toFixed(
                              2
                            )}</p>
                        </div>
                    </div>`
                    : ``
                }
              <hr style="border-top: dotted 2px; margin-top: -10px;" />`
    );
  }
  var margin =
    "</body><style>@page {size: auto; margin: 5mm 5mm 5mm 5mm;}</style>";
  if (pedido.valorTroco == null && pedido.observacao != null) {
    openWindow.document.write(
      `<body style="text-transform: Uppercase; font-family:Calibri;">
          <div id="cabecalho">
              <div id="nomeEmpresa" style="display:flex; justify-content: center; font-weight: bold;">
                  <p style="font-size: 15px;">${thisDadosEmpresa.nome}</p>
              </div>
              <div id="enderecoEmpresa" style="display:flex; justify-content: center;">
                  <p style="font-size: 10px;">${thisDadosEmpresa.endereco}</p>
              </div>
          </div>
          <hr style="border-top: dotted 2px;" /> 
          <hr style="border-top: dotted 2px; margin-top: -8px" /> 
          <div id="pedido" >
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Pedido nº ${pedido.num} </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Método de Pagamento: ${
                  pedido.metodoPgto
                } </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Ambiente de Pagamento: ${
                  pedido.ambientePgto
                    ? pedido.ambientePgto
                    : "Pagar na tele/retirada"
                } </p>
            </div>
            ${
              pedido.ambientePgto == "Pagar no app"
                ? `<div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Status de Pagamento: ${
                  pedido.statusPgto == "CON"
                    ? "Concluido"
                    : pedido.statusPgto == "PEN"
                    ? "Pendente"
                    : pedido.statusPgto == "NOV"
                    ? "Gerado"
                    : pedido.statusPgto == "EXP"
                    ? "Expirado"
                    : pedido.statusPgto == "CAN"
                    ? "Cancelado"
                    : pedido.statusPgto == "NEG"
                    ? "Negado"
                    : "Erro no pagamento"
                } </p>
            </div>`
                : ""
            }
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 15px; font-weight: bold;">Cliente: ${
                  pedido.cliente &&
                  thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    ? pedido.cliente &&
                      thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    : pedido.dadosCliente.nome
                }</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Endereco: ${
                pedido.cliente &&
                thisClientes.find((val) => val.cpf == pedido.cliente)
                  .endereco != null
                  ? thisClientes.find((val) => val.cpf == pedido.cliente)
                      .endereco
                  : pedido.dadosCliente.endereco
                  ? pedido.dadosCliente.endereco
                  : "Não necessita"
              }${ pedido.dadosCliente.numero ? ', ' +  pedido.dadosCliente.numero : '' }${ pedido.dadosCliente.complemento ?  ', ' + pedido.dadosCliente.complemento : ''}</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Modo de Entrega: ${
                pedido.valorTele ? "Tele - R$" + pedido.valorTele : "Retirada"
              }</p>
            </div>
            <div id="data" style="display:flex; margin-top: -10px; font-weight: bold;">
                <div style="width: 62%">
                    <p style="font-size: 10px;">Data: ${new Date().toLocaleDateString()}</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 10px;">Hora: ${
                      new Date().getHours() +
                      ":" +
                      new Date().getMinutes() +
                      ":" +
                      new Date().getSeconds()
                    }</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px;" /> 
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 12px;">item, codigo, descricao,</p>
            </div>
            <div id="produtoDesc"  style="display:flex;  margin-top: -20px;">
                <div style="width: 62%; display:flex; justify-content: left;">
                    <p style="font-size: 12px;">qtd x unitario</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 12px;">valor(R$)</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px; margin-top: -10px;" /> 
          </div>` + String(produtos).replace(/[,]/g, ""),
      `
          <div style="margin-top: -15px; display:flex; justify-content: left;">
              <p style="font-size: 12px;font-weight:bold">Observações: ${
                pedido.observacao
              }</p>
          </div>
          <div style="display:flex;  margin-top: -15px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor total: </p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px; ">${parseFloat(
                    pedido.preco
                  ).toFixed(2)}</p>
              </div>
          </div>` + margin
    );
  } else if (pedido.valorTroco == null && pedido.observacao == null) {
    openWindow.document.write(
      `<body style="text-transform: Uppercase; font-family:Calibri;">
          <div id="cabecalho">
              <div id="nomeEmpresa" style="display:flex; justify-content: center; font-weight: bold;">
                  <p style="font-size: 15px;">${thisDadosEmpresa.nome}</p>
              </div>
              <div id="enderecoEmpresa" style="display:flex; justify-content: center;">
                  <p style="font-size: 10px;">${thisDadosEmpresa.endereco}</p>
              </div>
          </div>
          <hr style="border-top: dotted 2px;" /> 
          <hr style="border-top: dotted 2px; margin-top: -8px" /> 
          <div id="pedido" >
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Pedido nº ${pedido.num} </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Método de Pagamento: ${
                  pedido.metodoPgto
                } </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Ambiente de Pagamento: ${
                  pedido.ambientePgto
                    ? pedido.ambientePgto
                    : "Pagar na tele/retirada"
                } </p>
            </div>
            ${
              pedido.ambientePgto == "Pagar no app"
                ? `<div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Status de Pagamento: ${
                  pedido.statusPgto == "CON"
                    ? "Concluido"
                    : pedido.statusPgto == "PEN"
                    ? "Pendente"
                    : pedido.statusPgto == "NOV"
                    ? "Gerado"
                    : pedido.statusPgto == "EXP"
                    ? "Expirado"
                    : pedido.statusPgto == "CAN"
                    ? "Cancelado"
                    : pedido.statusPgto == "NEG"
                    ? "Negado"
                    : "Erro no pagamento"
                } </p>
            </div>`
                : ""
            }
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 15px; font-weight: bold;">Cliente: ${
                  pedido.cliente &&
                  thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    ? pedido.cliente &&
                      thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    : pedido.dadosCliente.nome
                }</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Endereco: ${
                pedido.cliente &&
                thisClientes.find((val) => val.cpf == pedido.cliente)
                  .endereco != null
                  ? thisClientes.find((val) => val.cpf == pedido.cliente)
                      .endereco
                  : pedido.dadosCliente.endereco
                  ? pedido.dadosCliente.endereco
                  : "Não necessita"
              }${ pedido.dadosCliente.numero ? ', ' +  pedido.dadosCliente.numero : '' }${ pedido.dadosCliente.complemento ?  ', ' + pedido.dadosCliente.complemento : ''}</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Modo de Entrega: ${
                pedido.valorTele ? "Tele - R$" + pedido.valorTele : "Retirada"
              }</p>
            </div>
            <div id="data" style="display:flex; margin-top: -10px; font-weight: bold;">
                <div style="width: 62%">
                    <p style="font-size: 10px;">Data: ${new Date().toLocaleDateString()}</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 10px;">Hora: ${
                      new Date().getHours() +
                      ":" +
                      new Date().getMinutes() +
                      ":" +
                      new Date().getSeconds()
                    }</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px;" /> 
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 12px;">item, codigo, descricao,</p>
            </div>
            <div id="produtoDesc"  style="display:flex;  margin-top: -20px;">
                <div style="width: 62%; display:flex; justify-content: left;">
                    <p style="font-size: 12px;">qtd x unitario</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 12px;">valor(R$)</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px; margin-top: -10px;" /> 
          </div>` + String(produtos).replace(/[,]/g, ""),
      `<div style="display:flex;  margin-top: -15px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor total: </p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px;">${parseFloat(
                    pedido.preco
                  ).toFixed(2)}</p>
              </div>
          </div>` + margin
    );
  } else if (pedido.valorTroco != null && pedido.observacao == null) {
    openWindow.document.write(
      `<body style="text-transform: Uppercase; font-family:Calibri;">
          <div id="cabecalho">
              <div id="nomeEmpresa" style="display:flex; justify-content: center; font-weight: bold;">
                  <p style="font-size: 15px;">${thisDadosEmpresa.nome}</p>
              </div>
              <div id="enderecoEmpresa" style="display:flex; justify-content: center;">
                  <p style="font-size: 10px;">${thisDadosEmpresa.endereco}</p>
              </div>
          </div>
          <hr style="border-top: dotted 2px;" /> 
          <hr style="border-top: dotted 2px; margin-top: -8px" /> 
          <div id="pedido" >
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Pedido nº ${pedido.num} </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Método de Pagamento: ${
                  pedido.metodoPgto
                } </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Ambiente de Pagamento: ${
                  pedido.ambientePgto
                    ? pedido.ambientePgto
                    : "Pagar na tele/retirada"
                } </p>
            </div>
            ${
              pedido.ambientePgto == "Pagar no app"
                ? `<div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Status de Pagamento: ${
                  pedido.statusPgto == "CON"
                    ? "Concluido"
                    : pedido.statusPgto == "PEN"
                    ? "Pendente"
                    : pedido.statusPgto == "NOV"
                    ? "Gerado"
                    : pedido.statusPgto == "EXP"
                    ? "Expirado"
                    : pedido.statusPgto == "CAN"
                    ? "Cancelado"
                    : pedido.statusPgto == "NEG"
                    ? "Negado"
                    : "Erro no pagamento"
                } </p>
            </div>`
                : ""
            }
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 15px; font-weight: bold;">Cliente: ${
                  pedido.cliente &&
                  thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    ? pedido.cliente &&
                      thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    : pedido.dadosCliente.nome
                }</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Endereco: ${
                pedido.cliente &&
                thisClientes.find((val) => val.cpf == pedido.cliente)
                  .endereco != null
                  ? thisClientes.find((val) => val.cpf == pedido.cliente)
                      .endereco
                  : pedido.dadosCliente.endereco
                  ? pedido.dadosCliente.endereco
                  : "Não necessita"
              }${ pedido.dadosCliente.numero ? ', ' +  pedido.dadosCliente.numero : '' }${ pedido.dadosCliente.complemento ?  ', ' + pedido.dadosCliente.complemento : ''}</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Modo de Entrega: ${
                pedido.valorTele ? "Tele - R$" + pedido.valorTele : "Retirada"
              }</p>
            </div>
            <div id="data" style="display:flex; margin-top: -10px; font-weight: bold;">
                <div style="width: 62%">
                    <p style="font-size: 10px;">Data: ${new Date().toLocaleDateString()}</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 10px;">Hora: ${
                      new Date().getHours() +
                      ":" +
                      new Date().getMinutes() +
                      ":" +
                      new Date().getSeconds()
                    }</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px;" /> 
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 12px;">item, codigo, descricao,</p>
            </div>
            <div id="produtoDesc"  style="display:flex;  margin-top: -20px;">
                <div style="width: 62%; display:flex; justify-content: left;">
                    <p style="font-size: 12px;">qtd x unitario</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 12px;">valor(R$)</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px; margin-top: -10px;" /> 
          </div>` + String(produtos).replace(/[,]/g, ""),
      `<div style="display:flex;  margin-top: -20px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor total: </p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px;">${parseFloat(
                    pedido.preco
                  ).toFixed(2)}</p>
              </div>
          </div>
          <div style="display:flex;  margin-top: -15px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor para troco:</p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px;">${parseFloat(
                    pedido.valorTroco
                  ).toFixed(2)}</p>
              </div>
          </div>` + margin
    );
  } else if (pedido.valorTroco != null && pedido.observacao != null) {
    openWindow.document.write(
      `<body style="text-transform: Uppercase; font-family:Calibri;">
          <div id="cabecalho">
              <div id="nomeEmpresa" style="display:flex; justify-content: center; font-weight: bold;">
                  <p style="font-size: 15px;">${thisDadosEmpresa.nome}</p>
              </div>
              <div id="enderecoEmpresa" style="display:flex; justify-content: center;">
                  <p style="font-size: 10px;">${thisDadosEmpresa.endereco}</p>
              </div>
          </div>
          <hr style="border-top: dotted 2px;" /> 
          <hr style="border-top: dotted 2px; margin-top: -8px" /> 
          <div id="pedido" >
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Pedido nº ${pedido.num} </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Método de Pagamento: ${
                  pedido.metodoPgto
                } </p>
            </div>
            <div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Ambiente de Pagamento: ${
                  pedido.ambientePgto
                    ? pedido.ambientePgto
                    : "Pagar na tele/retirada"
                } </p>
            </div>
            ${
              pedido.ambientePgto == "Pagar no app"
                ? `<div style="display:flex; justify-content: center;  font-weight: bold;">
                <p style="font-size: 15px; ">Status de Pagamento: ${
                  pedido.statusPgto == "CON"
                    ? "Concluido"
                    : pedido.statusPgto == "PEN"
                    ? "Pendente"
                    : pedido.statusPgto == "NOV"
                    ? "Gerado"
                    : pedido.statusPgto == "EXP"
                    ? "Expirado"
                    : pedido.statusPgto == "CAN"
                    ? "Cancelado"
                    : pedido.statusPgto == "NEG"
                    ? "Negado"
                    : "Erro no pagamento"
                } </p>
            </div>`
                : ""
            }
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 15px; font-weight: bold;">Cliente: ${
                  pedido.cliente &&
                  thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    ? pedido.cliente &&
                      thisClientes.find((val) => val.cpf == pedido.cliente).nome
                    : pedido.dadosCliente.nome
                }</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Endereco: ${
                pedido.cliente &&
                thisClientes.find((val) => val.cpf == pedido.cliente)
                  .endereco != null
                  ? thisClientes.find((val) => val.cpf == pedido.cliente)
                      .endereco
                  : pedido.dadosCliente.endereco
                  ? pedido.dadosCliente.endereco
                  : "Não necessita"
              }${ pedido.dadosCliente.numero ? ', ' +  pedido.dadosCliente.numero : '' }${ pedido.dadosCliente.complemento ?  ', ' + pedido.dadosCliente.complemento : ''}</p>
            </div>
            <div style="display:flex; justify-content: left; margin-top: -20px;">
              <p style="font-size: 15px; font-weight: bold;">Modo de Entrega: ${
                pedido.valorTele ? "Tele - R$" + pedido.valorTele : "Retirada"
              }</p>
            </div>
            <div id="data" style="display:flex; margin-top: -10px; font-weight: bold;">
                <div style="width: 62%">
                    <p style="font-size: 10px;">Data: ${new Date().toLocaleDateString()}</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 10px;">Hora: ${
                      new Date().getHours() +
                      ":" +
                      new Date().getMinutes() +
                      ":" +
                      new Date().getSeconds()
                    }</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px;" /> 
            <div style="display:flex; justify-content: left; margin-top: -10px;">
                <p style="font-size: 12px;">item, codigo, descricao,</p>
            </div>
            <div id="produtoDesc"  style="display:flex;  margin-top: -20px;">
                <div style="width: 62%; display:flex; justify-content: left;">
                    <p style="font-size: 12px;">qtd x unitario</p>
                </div>
                <div style="width:38%; display:flex; justify-content: right;">
                    <p style="font-size: 12px;">valor(R$)</p>
                </div>
            </div>
            <hr style="border-top: dotted 2px; margin-top: -10px;" /> 
          </div>` + String(produtos).replace(/[,]/g, ""),
      `<div style="margin-top: -15px; display:flex; justify-content: left;">
              <p style="font-size: 12px;font-weight:bold">Observações: ${pedido.observacao}</p>
          </div>`,
      `
          <div style="display:flex;  margin-top: -20px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor total: </p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px;">${parseFloat(
                    pedido.preco
                  ).toFixed(2)}</p>
              </div>
          </div>
          <div style="display:flex;  margin-top: -15px; font-weight:bold">
              <div style="width: 62%; display:flex; justify-content: left;">
                  <p style="font-size: 12px;">valor para troco:</p>
              </div>
              <div style="width:38%; display:flex; justify-content: right;">
                  <p style="font-size: 12px;">${parseFloat(
                    pedido.valorTroco
                  ).toFixed(2)}</p>
              </div>
          </div>` + margin
    );
  }

  openWindow.print();
  openWindow.close();
}
