<template>
  <v-container fluid class="grey lighten-5" style="min-height: 100vmin">
    <v-row>
      <v-col cols="10" md="8">
        <v-tabs v-if="this.$isMobile()">
          <v-tab
            v-for="i in controle"
            :to="i.route"
            :key="i"
            color="#E74242"
            class="elevation-0"
            @click="this.controleSelecionado = i.name"
            >{{ i.name }}</v-tab
          >
        </v-tabs>
        <v-tabs v-else>
          <v-tab
            v-for="i in controle"
            :to="i.route"
            :key="i"
            color="#E74242"
            class="elevation-0"
            @click="this.controleSelecionado = i.name"
            >{{ i.name }}
          </v-tab>
        </v-tabs>
        <h1>Controle de Encomendas</h1>
      </v-col>
      <v-col class="text-right" cols="2" md="4">
        <v-btn
          v-if="!this.$isMobile()"
          elevation="0"
          color="red"
          @click="this.setDialog()"
        >
          Nova Encomenda
        </v-btn>
        <v-btn
          v-else
          size="x-small"
          elevation="0"
          color="red"
          @click="this.setDialog()"
          icon
        >
          <v-icon>mdi-package-variant-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <StatusCount></StatusCount>

    <v-row v-if="controleSelecionado != 'Todos'" class="grey">
      <RouterView></RouterView>
    </v-row>
    <v-row v-if="controleSelecionado == 'Todos'" class="grey">
      <Recebimentos />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <Aceitos />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <EmProducao />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <Prontos />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <Calendario />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

    </v-row>
  </v-container>
  <v-dialog v-model="this.dialog">
    <Encomenda />
  </v-dialog>
</template>

<script>
import Aceitos from "./containers/Aceitos.vue";
import Recebimentos from "./containers/Recebimentos.vue";
import EmProducao from "./containers/EmProducao.vue";
import Prontos from "./containers/Prontos";
import Calendario from "./containers/Calendario.vue";
import Encomenda from "./containers/Encomenda.vue";

import setDialog from "../../libs/setDialog";
import { mapGetters } from "vuex";

import StatusCount from "../../components/Status.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Encomendas",
  components: {
    Aceitos,
    Recebimentos,
    EmProducao,
    Prontos,
    Calendario,
    Encomenda,
    StatusCount,
  },
  data: () => ({
    attributes: [],
    pedidosPush: [],
    controle: [
      { name: "Todos", route: "/encomendas" },
      { name: "Recebimentos", route: "/encomendas/recebimentos" },
      { name: "Aceitos", route: "/encomendas/aceitos" },
      { name: "Em Producao", route: "/encomendas/emproducao" },
      { name: "Prontos", route: "/encomendas/prontos" },
      { name: "Calendário", route: "/encomendas/calendario" },
    ],
    controleSelecionado: "Todos",
    snackbar: false,
    text: "",
  }),

  watch: {
    '$route': {
      handler(newRoute) {
        console.log(newRoute.path);
        // Check the path of the new route and update controleSelecionado accordingly
        if (newRoute.path === '/encomendas') {
          this.controleSelecionado = 'Todos';
        } else if (newRoute.path === '/encomendas/recebimentos') {
          this.controleSelecionado = 'Recebimentos';
        } else if (newRoute.path === '/encomendas/aceitos') {
          this.controleSelecionado = 'Aceitos';
        } else if (newRoute.path === '/encomendas/emproducao') {
          this.controleSelecionado = 'Em Producao';
        } else if (newRoute.path === '/encomendas/prontos') {
          this.controleSelecionado = 'Prontos';
        } else {
          // Handle other routes or set a default value if needed
          this.controleSelecionado = '';
        }
      },
      immediate: true // This will trigger the handler immediately when the component is mounted
    }
  },
  computed: {
    ...mapGetters(["clientes", "pedidos", "dadosEmpresa", "dialog"]),
  },
  methods: {
    setDialog() {
      setDialog();
    },
  },
  mounted() {
    if (this.$route.path != "/encomendas") {
      this.controleSelecionado = "";
    }
  },
  created() {
    //se o usuario for novo redireciona para dados cad
    if (localStorage.getItem("novoUser") == "true") {
      this.$router.push({
        name: "DadosCad",
      });
    }
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.grey {
  animation: transitionIn 0.75s;
}
@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
.icons {
  font-size: 175%;
}

:deep .column-from-end-2 .weekday-7 {
  background-color: #fff3e5f5 !important;
}
:deep .column-from-end-2 .weekday-1 {
  background-color: #fff3e5f5 !important;
}

:deep .column-from-end-2 .is-today {
  background-color: #5f5757f5 !important;
}

:deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}
:deep .custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
:deep .custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
:deep .custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
:deep .custom-calendar.vc-container .vc-day {
  /* for Firefox */

  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
:deep .tarefa {
  padding: 0 5px 3px 5px;
  margin-bottom: 1px;
  height: 60px;
  min-width: 10%;
  overflow-y: scroll;
}
:deep .tarefa::-webkit-scrollbar {
  display: none;
}

:deep .custom-calendar.vc-container .vc-day.weekday-1,
:deep .custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
:deep .custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
:deep .custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
:deep .custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
:deep .custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}
</style>
