<template>
  <div v-if="!logado" fluid style="height: 100vh; background-color: #ea4545">
    <div
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      "
    >
      <div class="text-center text-white text-h6 mb-4">
        Login - Delivery IBO Dashboard
      </div>
      <v-card
        class="d-flex flex-column pr-10 pl-10 pb-5"
        :min-width="widthPrincipal"
        :min-height="heightPrincipal"
      >
        <p class="text-left mt-8 font-weight-bold" style="color: #90a4ae">
          Por favor preencha com seu login e senha do Dashboard
        </p>
        <form class="mt-8" v-on:keyup.enter="(carregando = true), this.login()">
          <p style="color: #90a4ae">Login</p>
          <v-text-field
            v-model="usuario"
            color="red"
            variant="underlined"
            prepend-inner-icon="mdi-account"
            clearable
            clear-icon="mdi-close"
            required
          >
          </v-text-field>
          <p style="color: #90a4ae">Senha</p>
          <v-text-field
            v-model="senha"
            variant="underlined"
            :type="show1 ? 'text' : 'password'"
            color="red"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock-outline"
            clearable
            clear-icon="mdi-close"
            required
            :error-messages="mensagemErroSenha"
            @click:append-inner="show1 = !show1"
          >
          </v-text-field>
          <div class="d-flex justify-end">
            <p
              class="mb-5"
              style="color: #90a4ae; cursor: pointer; width: 150px"
              href=""
            >
              Esqueceu a senha?
            </p>
          </div>
          <v-btn
            style="width: 100%"
            @click="(carregando = true), this.login()"
            color="red"
            :height="heightButton"
            ><p class="font-weight-bold text-white">Login</p></v-btn
          >
        </form>
      </v-card>
    </div>
  </div>
  <v-col
    v-if="carregando"
    cols="12"
    class="text-center"
    style="position: absolute; z-index: 9; top: 45%"
  >
    <v-progress-circular indeterminate color="red"></v-progress-circular>
  </v-col>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../main";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data: () => ({
    show1: false,
    widthPrincipal: null,
    heightPrincipal: null,
    heightButton: null,
    usuario: null,
    senha: null,
    logado: localStorage.getItem(
      "firebase:authUser:AIzaSyDdIWlYQkEqKoN8WWJnaRDOMsc3-UOAtO8:[DEFAULT]"
    ),
    auth: auth,
    carregando: false,
    mensagemErroSenha: "",
    autenticado: getAuth(),
  }),

  methods: {
    isMobile() {
      if (this.$isMobile()) {
        this.widthPrincipal = "400";
        this.heightPrincipal = "250";
        this.heightButton = "40";
        return true;
      } else {
        this.widthPrincipal = "650";
        this.heightPrincipal = "450";
        this.heightButton = "50";
        return false;
      }
    },
    login() {
      signInWithEmailAndPassword(auth, this.usuario, this.senha)
        // eslint-disable-next-line
        .then((data) => {})
        .catch((error) => {
          switch (error.code) {
            case "auth/user-not-found":
              this.mensagemErroSenha = "E-mail ou senha incorretos";
              this.carregando = false;
              break;
            case "auth/wrong-password":
              this.mensagemErroSenha = "E-mail ou senha incorretos";
              this.carregando = false;
              break;
            case "auth/invalid-email":
              this.mensagemErroSenha = "Insira um e-mail válido";
              this.carregando = false;
              break;
            case "auth/missing-email":
              this.mensagemErroSenha = "Insira um e-mail válido";
              this.carregando = false;
          }
        });
    },
  },
  created() {
    if (this.logado) {
      this.carregando = true;
    } else {
      this.carregando = false;
    }
    this.isMobile();
  },
};
</script>
<style>
.mdi-eye-off,
.mdi-eye {
  cursor: pointer;
}
</style>
