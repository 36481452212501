// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDdIWlYQkEqKoN8WWJnaRDOMsc3-UOAtO8",
  authDomain: "deliverysomasys-dcf58.firebaseapp.com",
  projectId: "deliverysomasys-dcf58",
  storageBucket: "deliverysomasys-dcf58.appspot.com",
  messagingSenderId: "888030115062",
  appId: "1:888030115062:web:114fafc7af9f7ad28568d7",
  measurementId: "G-JB68XN5MPD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
