<template>
    <dialogFinanceiro></dialogFinanceiro>
    <v-container fluid class="grey lighten-5" id="printMe">
      <v-row>
        <v-col cols="12" style="min-height: 100vmin">
          <h1>Controle De Cancelamentos</h1>
          <v-row>
            <v-col
              class="mx-auto mt-5"
              cols="11"
              style="border: 1px solid grey; border-radius: 5px"
            >
              <v-col class="d-flex justify-end" cols="12">
                <input
                  type="date"
                  v-model="data"
                  class="px-1"
                  style="
                    border: 0.5px solid grey;
                    border-radius: 5px;
                    width: 150px;
                  "
                />
                <v-btn
                  class="ml-2"
                  elevation="0"
                  @click="print()"
                  icon="mdi-printer-outline"
                ></v-btn>
              </v-col>
              <v-col class="d-flex justify-end">
                <span>{{ data }}</span>
              </v-col>
              <v-col
                :cols="colsPreco"
                class="ml-2"
                :class="mxauto"
                style="border: 0.5px solid grey; border-radius: 5px"
              >
                <h2 @click="this.teste()" class="text-center">
                  Valor cancelado dia
                </h2>
                <v-divider></v-divider>
                <h3 class="text-center mt-1">R$ {{ tweened.toFixed(2) }}</h3>
              </v-col>
              <v-col>
                <h2>Pedidos cancelados do dia</h2>
                <v-table hover  density="compact" fixed-header height="500px">
                  <thead>
                    <tr>
                      <th style="width: 10%" class="text-left">Numero Pedido</th>
                      <th style="width: 10%" class="text-left">Tipo</th>
                      <th style="width: 30%" class="text-left">Cliente</th>
                      <th style="width: 40%" class="text-left">Produtos</th>
                      <th style="width: 10%" class="text-left">Valor</th>
                      <th style="width: 10%" class="text-left">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="i in this.pedidosPush" :key="i">
                      <tr>
                        <td @click="openDialogPedido({ativo: true, pedido: i})" style="cursor: pointer">
                          <v-btn elevation="0" variant='tonal'>{{ i.num }}</v-btn>
                        </td>
                        <td>{{ i.dataEntrega ? 'Encomenda' : 'Pedido'}}</td>
                        <td>
                          <v-tooltip>
                            <template v-slot:activator="{ props }">
                              <div>
                                <span
                                  v-bind="props"
                                  v-if="this.$isMobile()"
                                  style="cursor: pointer; font-size: 10px"
                                  >{{ i.dadosCliente.nome }}</span
                                >
                                <span
                                  v-bind="props"
                                  v-else
                                  style="cursor: pointer"
                                  >{{ i.dadosCliente.nome }}</span
                                >
                              </div>
                            </template>
                            <template v-if="!i.cliente || i.cliente">
                              <div>
                                <p v-if="i.dadosCliente.nome != ''">
                                  Nome: {{ i.dadosCliente.nome }}
                                </p>
                                <p v-if="i.dadosCliente.cpf != ''">
                                  Cpf: {{ i.dadosCliente.cpf }}
                                </p>
                                <p v-if="i.dadosCliente.telefone != ''">
                                  Telefone: {{ i.dadosCliente.telefone }}
                                </p>
                                <p>
                                  Endereco:
                                  {{
                                    i.dadosCliente.endereco
                                      ? i.dadosCliente.endereco
                                      : "Não necessita"
                                  }}
                                </p>
                              </div>
                            </template>
                          </v-tooltip>
                        </td>
                        <td v-if="!this.$isMobile()">
                          <span v-for="p in i.produtos" :key="p.nome">
                            {{ p.nome + " " + p.quantidade + "x, " }}
                          </span>
                        </td>
                        <td v-if="this.$isMobile()">
                          <p
                            style="font-size: 10px"
                            v-for="p in i.produtos"
                            :key="p.nome"
                          >
                            {{ p.nome + " " + p.quantidade + "x, " }}
                          </p>
                        </td>
                        <td>
                          <p>R$ {{ parseFloat(i.preco).toFixed(2) }}</p>
                        </td>
                        <td>
                          <v-btn
                            @click="setDialogFinanceiro(i)"
                            icon
                            size="md"
                            elevation="0"
                          >
                            <v-icon color="green">mdi-eye-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </v-table>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { doc, updateDoc } from "firebase/firestore";
  import { db } from "../../firebase";
  import gsap from "gsap";
  import { mapGetters } from "vuex";
  import getDataPerDateCancelados from "../../libs/Pedidos/getDataPerDateCancelados";
  import { store } from "../../store/store";
  import dialogFinanceiro from "../../components/dialogFinanceiro.vue";
  
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Financeiro",
    components: {
      dialogFinanceiro,
    },
    data: () => ({
      data: "",
      produtosVendidos: [],
      produtosNome: [],
      maisVendidos: [],
      valorTotal: 0,
      tweened: 0,
      pedidosPush: [],
      pedidos: [],
    }),
    computed: {
      ...mapGetters(["database", "clientes"]),
    },
    watch: {
      //assiste o ValorTotal ser alterado e implementa um efeito de crescente no valorTotal que aparece em tela
      valorTotal(n) {
        gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 });
      },
      //Assiste o valor da data
      data(n) {
        if (n != "") {
          getDataPerDateCancelados(this.database.cnpj, n).then((val) => {
            this.pedidos = val.data.result;
          });
        }
      },
      pedidos() {
        //Envia pedidos concluidos da data especificada, soma o valor total, pega os produtos e nome de produtos e salva em variaveis diferentes, para posteriormente realizar a validacao
        const data = this.data;
        var i = 0;
        this.valorTotal = 0;
        this.produtosVendidos = [];
        this.produtosNome = [];
        this.maisVendidos = [];
        this.pedidosPush = [];
        for (i in this.pedidos) {
          if (
            (this.pedidos[i].data == data &&
              this.pedidos[i].status == "cancelado") 
          ) {
            if (this.pedidos[i].num) {
              this.pedidosPush.push(this.pedidos[i]);
              this.valorTotal =
                parseFloat(this.valorTotal) + parseFloat(this.pedidos[i].preco);
              var o = 0;
              for (o in this.pedidos[i].produtos[o].nome) {
                if (this.pedidos[i].produtos[o] != undefined) {
                  this.produtosVendidos.push(this.pedidos[i].produtos[o]);
                  this.produtosNome.push(this.pedidos[i].produtos[o].nome);
                }
              }
            }
          }
        }
        this.getmaisVendidos();
      },
    },
    methods: {
      setDialogFinanceiro(i) {
        let dialog = {
          ativo: true,
          pedido: i,
        };
        store.commit("incrementDialogFinanceiro", dialog);
      },
      print() {
        // Pass the element id here
        this.$htmlToPaper("printMe");
      },
      enviaPDV(i) {
        if (window.confirm("Deseja enviar pedido para o pdv?")) {
          i.status = "concluido";
          this.alteraDados(i);
          setTimeout(() => {
            for (var o = 0; o < this.pedidosPush.length; o++) {
              if (
                i.num == this.pedidosPush[o].num &&
                this.pedidosPush[o].status == "naoenviado"
              ) {
                alert("Falha ao enviar pedido ao pdv");
              } else if (
                i.num == this.pedidosPush[o].num &&
                this.pedidosPush[o].status == "geroucupom"
              ) {
                alert(
                  "Falha ao enviar pedido ao pdv, cupom fiscal já foi gerado"
                );
              }
            }
          }, 4000);
        }
      },
      alteraDados(data) {
        const docRef = doc(db, this.database.cnpj, "pedidos");
        updateDoc(docRef, {
          [data.key]: data,
        });
      },
      /*Funcao que pega os nomes dos produtos e seta o array para nao aceitar repetidos, depois faz um loop nos pedidos vendidos coletando a quantidade e o valor das vendas que possuem o mesmo nome que os produtos anteriormente definidos*/
      getmaisVendidos() {
        var i = 0;
        var o = 0;
        var vendido = [];
        this.produtosNome = [...new Set(this.produtosNome)];
        for (i in this.produtosNome) {
          vendido[i] = [
            {
              nome: "",
              codbarras: "",
              quantidade: 0,
              valor: 0,
            },
          ];
          vendido[i].quantidade = 0;
          vendido[i].valor = 0;
          for (o in this.produtosVendidos) {
            if (this.produtosNome[i] == this.produtosVendidos[o].nome) {
              vendido[i] = {
                nome: this.produtosNome[i],
                codbarras: this.produtosVendidos[o].codbarras,
                quantidade:
                  parseInt(vendido[i].quantidade) +
                  parseInt(this.produtosVendidos[o].quantidade),
                valor: (
                  parseInt(vendido[i].valor) +
                  parseFloat(this.produtosVendidos[o].valor) *
                    parseInt(this.produtosVendidos[o].quantidade)
                ).toFixed(2),
              };
            }
            this.maisVendidos = vendido;
          }
          this.maisVendidos.sort(function (a, b) {
            return b.quantidade - a.quantidade;
          });
        }
      },
      //Verfica se é mobile ou não para melhor adaptação das colunas da página
      isMobile() {
        if (this.$isMobile()) {
          this.colsPreco = 11;
          this.mxauto = "mx-auto";
        } else {
          this.colsPreco = 4;
          this.mxauto = "";
        }
      },
    },
    created() {
      this.isMobile();
    },
    mounted() {
      setTimeout(() => {
        const options = { timeZone: "America/Sao_Paulo" };
        const brasiliaTime = new Date().toLocaleString("en-GB", options);
        const [day, month, year] = brasiliaTime.split(",")[0].split("/");
        this.data = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  
        getDataPerDateCancelados(this.database.cnpj, this.data).then((val) => {
          this.pedidos = val.data.result;
        });
      }, 250);
      //Assim que a página é criada insere o dia atual na variavel
    },
  };
  </script>
  
  <style scoped>
  .grey {
    animation: transitionIn 0.75s;
  }
  .icons {
    font-size: 175%;
  }
  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-10deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0);
    }
  }
  </style>
  