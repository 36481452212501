import { auth } from "@/main";
import { connectWebSocket } from "./websocket";

export default function getPedidos(database) {
  const getPedidos = async () => {
    if (auth.currentUser != null) {
      await auth.currentUser?.getIdToken(true).then((log) => {
        // Send a 'subscribe' message to the server
        const message = {
          action: "subscribe",
          empresa: database, // Replace with the desired empresa value
          authToken: log,
        };
        let socket = connectWebSocket();
        socket.send(JSON.stringify(message));
      });
    } else {
      return "Unauthorized";
    }
  };
  getPedidos();
}
