<template>
  <v-container fluid class="grey lighten-5 pb-10">
    <v-row class="grey">
      <v-col cols="12">
        <h2 class="mt-10">Aceitos</h2>

        <v-table hover  density="compact" fixed-header height="500px">
          <thead>
            <tr>
              <th
                :class="'text-left ' + cor.num"
                @click="
                  ((this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    (cor.num = 'text-red'),
                    sortedIconPedido == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.reverse(),
                        (sortedIconPedido = 'mdi-arrow-up-thin'))
                      : (this.getPedidos(),
                        (sortedIconPedido = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Numero Pedido <v-icon :icon="sortedIconPedido"></v-icon>
              </th>
              <th>Pagamento</th>
              <th :class="'text-left '">Cliente</th>
              <th
                :class="'text-left ' + cor.produtos"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    (cor.produtos = 'text-red'),
                    sortedIconProdutos == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          a.produtos.length <= b.produtos.length ? 1 : -1
                        ),
                        (sortedIconProdutos = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          a.produtos.length >= b.produtos.length ? 1 : -1
                        ),
                        (sortedIconProdutos = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Produtos <v-icon :icon="sortedIconProdutos"></v-icon>
              </th>
              <th :class="'text-left '">Sabores</th>
              <th
                :class="'text-left ' + cor.observacao"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    sortedIconObservacao == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          a.observacao == null && b.observacao != null ? 1 : -1
                        ),
                        (sortedIconObservacao = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          a.observacao != null && b.observacao == null ? 1 : -1
                        ),
                        (sortedIconObservacao = 'mdi-arrow-down-thin')),
                    (cor.observacao = 'text-red')
                "
                style="cursor: pointer"
              >
                Possui Observação <v-icon :icon="sortedIconObservacao"></v-icon>
              </th>
              <th
                :class="'text-left ' + cor.valorTroco"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    (cor.valorTroco = 'text-red'),
                    sortedIconTroco == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          !a.valorTroco ? 1 : -1
                        ),
                        (sortedIconTroco = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          a.valorTroco ? 1 : -1
                        ),
                        (sortedIconTroco = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Troco para <v-icon :icon="sortedIconTroco"></v-icon>
              </th>
              <th
                :class="'text-left ' + cor.possuiAdicionais"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    (cor.possuiAdicionais = 'text-red'),
                    sortedIconAdicionais == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          !a.possuiAdicionais ? 1 : -1
                        ),
                        (sortedIconAdicionais = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          a.possuiAdicionais ? 1 : -1
                        ),
                        (sortedIconAdicionais = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Possui Adicionais<v-icon :icon="sortedIconAdicionais"></v-icon>
              </th>
              <th
                :class="'text-left ' + cor.valorTele"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconDataEntrega = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    (cor.valorTele = 'text-red'),
                    sortedIconTele == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          !a.valorTele ? 1 : -1
                        ),
                        (sortedIconTele = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          a.valorTele ? 1 : -1
                        ),
                        (sortedIconTele = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Tele Entrega <v-icon :icon="sortedIconTele"></v-icon>
              </th>
              <th
                :class="'text-left ' + cor.dataEntrega"
                @click="
                  ((this.sortedIconPedido = 'mdi-swap-vertical'),
                  (this.sortedIconProdutos = 'mdi-swap-vertical'),
                  (this.sortedIconObservacao = 'mdi-swap-vertical'),
                  (this.sortedIconTroco = 'mdi-swap-vertical'),
                  (this.sortedIconAdicionais = 'mdi-swap-vertical'),
                  (this.sortedIconTele = 'mdi-swap-vertical')),
                    this.zeraCor(),
                    this.getPedidos(),
                    (cor.dataEntrega = 'text-red'),
                    sortedIconDataEntrega == 'mdi-arrow-down-thin'
                      ? (this.pedidosPush.sort((a, b) =>
                          new Date(a.dataEntrega) <= new Date(b.dataEntrega)
                            ? 1
                            : -1
                        ),
                        (sortedIconDataEntrega = 'mdi-arrow-up-thin'))
                      : (this.pedidosPush.sort((a, b) =>
                          new Date(a.dataEntrega) >= new Date(b.dataEntrega)
                            ? 1
                            : -1
                        ),
                        (sortedIconDataEntrega = 'mdi-arrow-down-thin'))
                "
                style="cursor: pointer"
              >
                Data Entrega <v-icon :icon="sortedIconDataEntrega"></v-icon>
              </th>
              <th class="text-left" style="">Hora Entrega</th>
              <th style="z-index: 9" class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="i in pedidosPush" :key="i.key">
              <tr>
                <td v-if="i.status == 'Aceito'" @click="openDialogPedido({ativo: true, pedido: i})" style="cursor: pointer"><v-btn elevation="0" variant='tonal'>{{ i.num }}</v-btn></td>
                <td v-if="i.status == 'Aceito'">
                  <v-tooltip>
                    <template v-slot:activator="{ props }">
                      <div>
                        <span
                          v-bind="props"
                          v-if="this.$isMobile()"
                          style="cursor: pointer; font-size: 10px"
                          >{{ i.metodoPgto }} - R${{parseFloat(i.preco)
                              .toFixed(2)
                              .replace(".", ",")}}</span
                        >
                        <span
                          v-bind="props"
                          :class="[
                            i.ambientePgto && i.ambientePgto == 'Pagar no app'
                              ? i.statusPgto === 'CON'
                                ? 'text-green'
                                : i.statusPgto === 'PEN'
                                ? 'text-yellow'
                                : i.statusPgto === 'NOV'
                                ? 'text-yellow'
                                : 'text-red'
                              : ''
                          ]" style="cursor: pointer"
                        >{{ i.metodoPgto }} - R${{parseFloat(i.preco)
                              .toFixed(2)
                              .replace(".", ",")}}
                        </span>
                      </div>
                    </template>
                    <template v-if="true">
                      <div>
                        <p>
                          Ambiente de Pgto:
                          {{
                            i.ambientePgto
                              ? i.ambientePgto
                              : "Pagar na entrega/retirada"
                          }}
                        </p>
                        <p
                          v-if="
                            i.ambientePgto
                              ? i.ambientePgto == 'Pagar no app'
                              : false
                          "
                        >
                          Status do pagamento:
                          <strong>
                            {{
                              i.statusPgto == "CON"
                                ? "Concluido"
                                : i.statusPgto == "PEN"
                                ? "Pendente"
                                : i.statusPgto == "NOV"
                                ? "Gerado"
                                : i.statusPgto == "EXP"
                                ? "Expirado"
                                : i.statusPgto == "CAN"
                                ? "Cancelado"
                                : i.statusPgto == "NEG"
                                ? "Negado"
                                : i.statusPgto == "NEG"
                                ? "Negado"
                                : "Erro no pagamento"
                            }}</strong
                          >
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </td>
                <td v-if="i.status == 'Aceito'">
                  <v-tooltip>
                    <template v-slot:activator="{ props }">
                      <div>
                        <span
                          v-bind="props"
                          v-if="this.$isMobile()"
                          style="cursor: pointer; font-size: 10px"
                          >{{ i.dadosCliente ? i.dadosCliente.nome : "" }}</span
                        >
                        <span v-bind="props" v-else style="cursor: pointer">{{
                          i.dadosCliente ? i.dadosCliente.nome : ""
                        }}</span>
                      </div>
                    </template>

                    <template v-if="!i.cliente || i.cliente">
                      <div>
                        <p v-if="i.dadosCliente.nome != ''">
                          Nome: {{ i.dadosCliente.nome }}
                        </p>
                        <p v-if="i.dadosCliente.cpf != ''">
                          Cpf: {{ i.dadosCliente.cpf }}
                        </p>
                        <p v-if="i.dadosCliente.telefone != ''">
                          Telefone: {{ i.dadosCliente.telefone }}
                        </p>
                        <p>
                          Endereco:
                          {{
                            i.dadosCliente.endereco
                              ? i.dadosCliente.endereco
                              : "Não necessita"
                          }}{{ i.dadosCliente.numero ? ', ' +  i.dadosCliente.numero: '' }}{{ i.dadosCliente.complemento ? ', ' + i.dadosCliente.complemento : ''}}
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </td>
                <!-- <td v-if="i.status == 'Aceito'">{{ i.cliente }}</td> -->
                <td v-if="i.status == 'Aceito' && !this.$isMobile()">
                  <span v-for="p in i.produtos" :key="p.nome">
                    {{ p.nome + " " + p.quantidade + "x, " }}
                  </span>
                </td>
                <td v-if="i.status == 'Aceito' && this.$isMobile()">
                  <span
                    style="font-size: 10px"
                    v-for="p in i.produtos"
                    :key="p.nome"
                  >
                    {{ p.nome + " " + p.quantidade + "x, " }}
                  </span>
                </td>
                <td v-if="i.status == 'Aceito'">
                  <v-tooltip left>
                    <template v-slot:activator="{ props }">
                      <span
                        v-bind="props"
                        v-if="
                          i.produtos.find((val) => val.saboresDesc)
                            ? true
                            : false
                        "
                        style="cursor: pointer"
                        >Possui</span
                      >
                      <span
                        v-if="
                          i.produtos.find((val) => val.saboresDesc)
                            ? false
                            : true
                        "
                        style="cursor: pointer"
                        >Não Possui</span
                      >
                    </template>
                    <div v-for="produto in i.produtos" v-bind:key="produto">
                      <span v-if="produto.saboresDesc">
                        {{ produto.nome }} x {{ produto.quantidade }}:
                        {{ produto.saboresDesc }}
                      </span>
                    </div>
                  </v-tooltip>
                </td>
                <td
                  v-if="
                    (i.status == 'Aceito' && !i.observacao) ||
                    (i.status == 'Aceito' && i.observacao != null)
                  "
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ props }">
                      <span v-if="i.status == 'Aceito' && !i.observacao"
                        >Não possui</span
                      >
                      <span
                        v-bind="props"
                        v-if="
                          i.status == 'Aceito' &&
                          i.observacao != null &&
                          i.observacao != ''
                        "
                        style="cursor: pointer"
                        >Possui</span
                      >
                    </template>
                    <p v-if="i.status == 'Aceito' && i.observacao != null">
                      {{ i.observacao }}
                    </p>
                  </v-tooltip>
                </td>

                <td v-if="i.status == 'Aceito' && i.valorTroco">
                  R${{ i.valorTroco }}
                </td>
                <td v-if="i.status == 'Aceito' && !i.valorTroco">
                  Não necessita
                </td>

                <td
                  v-if="
                    (i.status == 'Aceito' && i.possuiAdicionais == null) ||
                    (i.status == 'Aceito' && i.possuiAdicionais != null)
                  "
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ props }">
                      <span
                        v-if="
                          i.status == 'Aceito' && i.possuiAdicionais == null
                        "
                        >Não possui</span
                      >
                      <span
                        v-bind="props"
                        v-if="
                          i.status == 'Aceito' && i.possuiAdicionais != null
                        "
                        style="cursor: pointer"
                        >Possui</span
                      >
                    </template>
                    <div v-for="produto in i.produtos" v-bind:key="produto">
                      <span v-if="produto.adicionaisDesc">
                        {{ produto.nome }} x {{ produto.quantidade }}:
                        {{ produto.adicionaisDesc }}
                      </span>
                    </div>
                  </v-tooltip>
                </td>
                <td
                  v-if="i.status == 'Aceito'"
                  class="text-left"
                  :style="`color: ${i.valorTele ? 'green' : 'red'}`"
                >
                  <strong>{{ i.valorTele ? "Sim" : "Nao" }}</strong>
                </td>
                <td v-if="i.status == 'Aceito'">
                  {{ i.dataEntrega.split("-").reverse().join("/") }}
                </td>
                <td v-if="i.status == 'Aceito'">{{ i.horaEntrega }}</td>

                <td v-if="i.status == 'Aceito'">
                  <v-menu transition="slide-y-transition" style="z-index: 99">
                    <template v-slot:activator="{ props }">
                      <v-btn elevation="0" v-bind="props" icon>
                        <v-icon>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          ><v-btn
                            elevation="0"
                            style="width: 100%"
                            @click="(i.status = 'recebido'), alteraDados(i)"
                          >
                            Enviar Produção<v-icon
                              color="green"
                              style="cursor: pointer"
                              class="faa-pulse animated-hover faa-slow icons"
                              >mdi-receipt-text-check-outline</v-icon
                            >
                          </v-btn></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          ><v-btn
                            elevation="0"
                            style="width: 100%"
                            @click="(i.status = 'cancelado'), alteraDados(i)"
                            >Cancelar<v-icon
                              color="red"
                              style="cursor: pointer"
                              class="faa-pulse animated-hover faa-slow icons"
                              >mdi-receipt-text-remove-outline</v-icon
                            ></v-btn
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn
                            elevation="0"
                            style="width: 100%"
                            @click="setDialog(i)"
                          >
                            Editar Encomenda
                            <v-icon color="green">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn
                            elevation="0"
                            style="width: 100%"
                            @click="
                              imprimePedido(i, this.dadosEmpresa, this.clientes)
                            "
                          >
                            Imprime Comanda
                            <v-icon color="black">mdi-printer</v-icon>
                          </v-btn></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn
                            elevation="0"
                            style="width: 100%"
                            @click="abreWhats(i.clienteNum)"
                          >
                            Abrir Whatsapp
                            <v-icon color="green">mdi-whatsapp</v-icon>
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import setDialog from "../../../libs/setDialog";

import imprimeEncomenda from "../../../libs/imprimeEncomenda";
import updatePedido from "../../../libs/updatePedido";
import enviaWhatsCancelado from "../../../libs/enviaWhatsCancelado";
import { store } from "../../../store/store";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Aceitos",
  form: "#form",
  data: () => ({
    sortedIconPedido: "mdi-swap-vertical",
    sortedIconProdutos: "mdi-swap-vertical",
    sortedIconObservacao: "mdi-swap-vertical",
    sortedIconTroco: "mdi-swap-vertical",
    sortedIconAdicionais: "mdi-swap-vertical",
    sortedIconTele: "mdi-swap-vertical",
    sortedIconDataEntrega: "mdi-swap-vertical",

    cor: {
      num: "text-black",
      cliente: "text-black",
      produtos: "text-black",
      observacao: "text-black",
      valorTroco: "text-black",
      possuiAdicionais: "text-black",
      valorTele: "text-black",
      dataEntrega: "text-black",
    },
    pedidosPush: [],
    horarios: [],
  }),
  watch: {
    pedidos: {
      handler() {
        this.getPedidos();
      },
      deep: true,
    },
    pagamentos: {
      handler() {
        this.pedidosPush.map((val) => this.pagamentos.find((val2) => val2.num == val.num) ? val.statusPgto = this.pagamentos.find((val2) => val2.num == val.num).statusPgto : val)
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters(["database", "clientes", "pedidos", "dadosEmpresa", "pagamentos"]),
  },
  methods: {
    openDialogPedido(i){
      store.commit("incrementDialogPedido", i)
    },
    abreWhats(telefone) {
      window.open(
        "https://api.whatsapp.com/send?phone=" + telefone + "&text=" + "Olá"
      );
    },
    zeraCor() {
      this.cor = {
        num: "text-black",
        cliente: "text-black",
        produtos: "text-black",
        observacao: "text-black",
        valorTroco: "text-black",
        possuiAdicionais: "text-black",
        valorTele: "text-black",
        dataEntrega: "text-black",
      };
    },
    setDialog(i) {
      setDialog("edit", i);
    },
    async alteraDados(data) {
      data.num = data.num.toString();
      const result = await updatePedido(this.database.cnpj, data);
      if (data.status == "cancelado") {
        enviaWhatsCancelado(
          data.clienteNum,
          data.num,
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(data.preco)
        );
      }
      console.log(result);
    },
    getPedidos() {
      var i = 0;
      var count = 0;
      this.pedidosPush = [];

      if (this.pedidos !== undefined) {
        for (i in this.pedidos) {
          if (this.pedidos[i] !== "" && this.pedidos[i].dataEntrega) {
            this.pedidosPush.push(this.pedidos[i]);
          }
        }
        this.pedidosPush.sort((a, b) => a.num - b.num);
      }
      if (count == 1) {
        this.text = "Possui " + count + " encomenda nova";
      } else {
        this.text = "Possui " + count + " encomendas novas";
      }
    },

    imprimePedido(i, dadosEmpresa, clientes) {
      imprimeEncomenda(i, dadosEmpresa, clientes);
    },
  },
  mounted() {
    this.getPedidos();
  },
};
</script>
<style scoped>
.v-input--selection-controls__input > input {
  display: block;
}
.grey {
  animation: transitionIn 0.75s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
</style>
