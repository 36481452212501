<template>
  <v-container fluid class="grey lighten-5" style="min-height: 100vmin">
    <v-row>
      <v-col cols="10" md="8">
        <v-tabs>
          <v-tab
            v-for="i in controle"
            :to="i.route"
            :key="i"
            color="#E74242"
            class="elevation-0"
            @click="this.controleSelecionado = i.name"
            >{{ i.name }}
          </v-tab>
        </v-tabs>
        <h1>Controle de Pedidos</h1>
      </v-col>
      <v-col class="text-right" cols="2" md="4">
        <v-btn
          v-if="!this.$isMobile()"
          elevation="0"
          color="red"
          @click="this.setDialog()"
        >
          Novo Pedido
        </v-btn>
        <v-btn
          v-else
          size="x-small"
          elevation="0"
          color="red"
          @click="this.setDialog()"
          icon
        >
          <v-icon>mdi-table-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <StatusCount></StatusCount>

    <v-row v-if="controleSelecionado != 'Todos'" class="grey">
      <RouterView></RouterView>
    </v-row>
    <v-row v-else class="grey">
      <Recebimentos></Recebimentos>
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <EmProducao />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <Prontos />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

      <Tele />
      <v-divider v-if="controleSelecionado == 'Todos'"></v-divider>

    </v-row>
  </v-container>
  <v-dialog v-model="this.dialog" style="z-index: 1000000">
    <Pedido />
  </v-dialog>

  <!-- <v-snackbar
    v-model="snackbar"
    class="mt-2"
    right
    top
    color="deep-purple accent-4"
    timeout="600000"
  >
    {{ text }}
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false"> x </v-btn>
    </template>
  </v-snackbar> -->
</template>

<script>
import { mapGetters } from "vuex";

import setDialog from "../../libs/setDialog";

// import Recebimentos from "./containers/Recebimentos.vue";

import EmProducao from "./containers/EmProducao.vue";

import Prontos from "./containers/Prontos.vue";

import Tele from "./containers/Tele.vue";

import Recebimentos from "./containers/Recebimentos.vue";

import Pedido from "./containers/Pedido.vue";

import StatusCount from "../../components/Status.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pedidos",
  components: {
    EmProducao,
    Prontos,
    Tele,
    Recebimentos,
    Pedido,
    StatusCount,
  },
  data: () => ({
    pedidosPush: [],
    dadosempresa: [],
    controle: [
      { name: "Todos", route: "/pedidos" },
      { name: "Recebimentos", route: "/pedidos/recebimentos" },
      { name: "Em Producao", route: "/pedidos/emproducao" },
      { name: "Pronto", route: "/pedidos/pronto" },
      { name: "Tele | Aguardando Retirada", route: "/pedidos/tele" },
    ],
    controleSelecionado: "Todos",
    snackbar: false,
    horarios: [],
    text: "",
  }),
  watch: {
    '$route': {
      handler(newRoute) {
        // Check the path of the new route and update controleSelecionado accordingly
        if (newRoute.path === '/pedidos') {
          this.controleSelecionado = 'Todos';
        } else if (newRoute.path === '/pedidos/recebimentos') {
          this.controleSelecionado = 'Recebimentos';
        } else if (newRoute.path === '/pedidos/emproducao') {
          this.controleSelecionado = 'Em Producao';
        } else if (newRoute.path === '/pedidos/pronto') {
          this.controleSelecionado = 'Pronto';
        } else if (newRoute.path === '/pedidos/tele') {
          this.controleSelecionado = 'Tele | Aguardando Retirada';
        }  else {
          // Handle other routes or set a default value if needed
          this.controleSelecionado = '';
        }
      },
      immediate: true // This will trigger the handler immediately when the component is mounted
    }
  },
  computed: {
    ...mapGetters([
      "clientes",
      "database",
      "pedidos",
      "dadosEmpresa",
      "dialog",
    ]),
  },
  methods: {
    setDialog() {
      setDialog();
    },
  },
  mounted() {
    if (this.$route.path != "/pedidos") {
      this.controleSelecionado = "";
    }
    //se o usuario for novo redireciona para dados cad
    if (localStorage.getItem("novoUser") == "true") {
      this.$router.push({
        name: "DadosCad",
      });
    }
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: solid; /* for Chrome, Safari, and Opera */
}

.grey {
  animation: transitionIn 0.75s;
}
@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
.icons {
  font-size: 175%;
}
</style>
