<template>
  <v-container fluid class="grey lighten" style="min-height: 100vmin">
    <v-row>
      <v-col cols="12" style="height: 200%">
        <h1>Cadastro de Horários</h1>
        <v-col :cols="colsForm" class="mx-auto text-center">
          <h2>Status</h2>
          <v-card style="border: solid 1px grey">
            <v-card-title :class="cor + ' text-center '">{{
              status
            }}</v-card-title></v-card
          >
        </v-col>
        <v-divider></v-divider>
        <v-col :cols="colsForm" class="mx-auto text-center">
          <h2>Horários</h2>
          <v-btn
            color="#00007f"
            class="mr-1 text-white"
            @click="
              this.limpaForm(),
                (this.textoButton = 'CRIAR'),
                (this.dialog = true)
            "
            >Criar novo Horario</v-btn
          >
        </v-col>
        <v-col :cols="colsForm" class="mx-auto">
          <v-divider></v-divider>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="i in diasSelect"
              :key="i"
              @click="selectHorario(i), (horarioSelecionado = i)"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col cols="6" class="d-flex justify-start">
                      {{ i }}
                    </v-col>
                    <v-col cols="6" class="text-grey">
                      <v-fade-transition leave-absolute>
                        <span v-if="!expanded" key="0">
                          Clique para mais informações
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row no-gutters>
                  <v-col cols="4">
                    <p>Horários</p>
                    <p v-for="i in hora" :key="i">
                      {{ i.abre }} - {{ i.fecha }}
                    </p>
                  </v-col>

                  <v-divider vertical class="mx-4"></v-divider>

                  <v-col cols="6"> Edite ou exclua seu horario </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="font-weight: bold"
                    v-if="this.horarioSelecionado != ''"
                    @click="
                      selectHorario(horarioSelecionado),
                        (this.dialog = true),
                        (expanded = false)
                    "
                    >Alterar</v-btn
                  >

                  <v-btn
                    style="font-weight: bold"
                    v-if="this.horarioSelecionado != ''"
                    color="#E74242"
                    @click="removeHorario(horarioSelecionado)"
                    >Excluir</v-btn
                  >
                </v-card-actions>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-dialog v-model="dialog" fullscreen>
          <v-card>
            <v-col :cols="colsDialog" class="mx-auto text-center">
              <h2>{{ textoHor }}</h2>
              <form @submit.prevent="this.validacoes()">
                <v-select
                  v-model="hora[0].dia"
                  variant="outlined"
                  label="Dia da Semana"
                  color="red"
                  :items="dias"
                  clearable
                  clear-icon="mdi-close"
                  :error-messages="erroDia"
                  @update:modelValue="diasSelect.find((val) => val == hora[0].dia) ? selectHorario(hora[0].dia): ''"
                />
                <div v-for="(i, index) in hora" :key="index">
                  <v-col class="d-flex">
                    <v-text-field
                      class="mr-1"
                      v-mask="'##:##'"
                      v-model="hora[index].abre"
                      counter="5"
                      color="red"
                      variant="outlined"
                      label="Horário de abertura"
                      clearable
                      clear-icon="mdi-close"
                      :error-messages="errohorAbre[index]"
                    >
                    </v-text-field>
                    <v-text-field
                      class="ml-1"
                      v-model="hora[index].fecha"
                      v-mask="'##:##'"
                      counter="5"
                      variant="outlined"
                      label="Horário de fechamento"
                      color="red"
                      clearable
                      clear-icon="mdi-close"
                      :error-messages="errohorFecha[index]"
                    ></v-text-field>
                    <v-btn
                      v-if="index == 0"
                      class="mt-1 ml-1"
                      elevation="0"
                      icon="mdi-plus"
                      @click="this.hora.push({})"
                    ></v-btn>
                    <v-btn
                      v-if="index > 0"
                      class="mt-1 ml-1"
                      elevation="0"
                      icon="mdi-minus"
                      @click="removeTurno(index)"
                    ></v-btn>
                  </v-col>
                </div>

                <v-row class="d-flex justify-space-between mt-n10">
                  <v-col cols="4">
                    <v-checkbox
                      class="left"
                      v-model="hora[0].util"
                      label="Dia útil"
                      color="success"
                      value="true"
                      :error-messages="erroCheckbox"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-checkbox
                          v-model="hora[0].util"
                          label="Dia não útil"
                          color="error"
                          value="false"
                          v-bind="props"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <p>
                        Caso marque esta opcao e insira horarios, o proximo dia
                        comecara com o horario definido,
                      </p>
                      <p>
                        e, apos isso, se excluira na proxima vez que abrir o
                        sistema
                      </p>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-btn color="#96F46A" type="submit" class="mr-1 text-white">{{
                  textoButton
                }}</v-btn>

                <v-btn
                  class="ml-1 text-white"
                  color="#E74242"
                  @click="this.limpaForm()"
                  >CANCELAR</v-btn
                >
              </form>
            </v-col>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { mapGetters } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Horarios",
  data: () => ({
    turnos: 1,
    dialog: false,
    panel: -1,
    erroDia: "",
    errohorAbre: [],
    errohorFecha: [],
    erroCheckbox: "",
    status: "",
    cor: "justify-center",
    dias: [
      "Domingo",
      "Segunda-Feira",
      "Terca-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sabado",
    ],
    diasSelect: [
      "Domingo",
      "Segunda-Feira",
      "Terca-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sabado",
    ],
    textoHor: "Inserção de Horário",
    textoButton: "CRIAR",
    horarioSelecionado: "",
    colsForm: "",
    hora: [{}],
    horarios: [],
  }),
  watch:{
  },
  computed: {
    ...mapGetters(["database"]),
  },
  methods: {
    removeTurno(n) {
      if (n != this.hora.length - 1) {
        this.hora[n] = {
          abre: this.hora[n + 1].abre == undefined ? "" : this.hora[n + 1].abre,
          fecha:
            this.hora[n + 1].fecha == undefined ? "" : this.hora[n + 1].fecha,
          util: this.hora[n + 1].util == undefined ? "" : this.hora[n + 1].util,
        };
        this.hora.splice(n + 1, 1);
      } else {
        this.hora.pop();
      }
    },
    //Validacoes do formulário de preenchimento de horário
    validacoes() {
      this.errohorAbre = [];
      this.errohorFecha = [];
      this.erroDia = "";
      this.erroCheckbox = "";
      if (this.hora[0].dia == undefined) {
        this.erroDia = "Dia não pode ser vazio";
      } else if (
        (this.hora[0].dia == this.diasSelect[0] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[1] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[2] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[3] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[4] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[5] &&
          this.textoButton == "CRIAR") ||
        (this.hora[0].dia == this.diasSelect[6] && this.textoButton == "CRIAR")
      ) {
        this.erroDia = "Dia ja cadastrado";
      } else {
        var validado = true;
        for (var i = 0; i < this.hora.length; i++) {
          if (this.hora[i].abre == undefined) {
            this.errohorAbre[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (this.hora[i].abre == "") {
            this.errohorAbre[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (this.hora[i].fecha == undefined) {
            this.errohorFecha[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (
            this.hora[i].fecha == "" ||
            this.hora[i].fecha == undefined
          ) {
            this.errohorFecha[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (
            this.hora[i].abre.length < 5 ||
            this.hora[i].abre.length > 5
          ) {
            this.errohorAbre[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (
            this.hora[i].fecha.length < 5 ||
            this.hora[i].fecha.length > 5
          ) {
            this.errohorFecha[i] =
              "Por favor, preencha o horario corretamente (00:00)";
            validado = false;
          } else if (this.hora[i].abre >= this.hora[i].fecha) {
            this.errohorAbre[i] =
              "Horario de abertura deve ser menor que de fechamento";
            validado = false;
          } else if (
            this.hora[i].abre >= "24:00" ||
            this.hora[i].fecha >= "24:00"
          ) {
            this.errohorAbre[i] = "Horário não pode passar de 23:59";
            this.errohorFecha[i] = "Horário não pode passar de 23:59";
            validado = false;
          } else if (this.hora[i].abre.slice(-2) >= "60") {
            this.errohorAbre[i] = "Horário não pode ter mais de 59 minutos";
            validado = false;
          } else if (this.hora[i].fecha.slice(-2) >= "60") {
            this.errohorFecha[i] = "Horário não pode ter mais de 59 minutos";
            validado = false;
          } else if (this.hora[0].util == "") {
            this.erroCheckbox = "Por favor, selecione se o dia é util ou não";
            validado = false;
          } else if (
            i >= 1 &&
            this.hora[i - 1].abre >= this.hora[i].abre &&
            this.hora[i - 1].abre <= this.hora[i].fecha
          ) {
            this.errohorAbre[i] = "Horários não podem estar na mesma faixa";
            this.errohorFecha[i] = "Horários não podem estar na mesma faixa";
          } else if (
            (i >= 1 &&
              this.hora[i - 1].abre <= this.hora[i].abre &&
              this.hora[i - 1].fecha >= this.hora[i].fecha) ||
            (i >= 1 &&
              this.hora[i - 1].abre <= this.hora[i].fecha &&
              this.hora[i - 1].fecha >= this.hora[i].fecha)
          ) {
            this.errohorAbre[i] = "Horários não podem estar na mesma faixa";
            this.errohorFecha[i] = "Horários não podem estar na mesma faixa";
          } else if (
            i >= 1 &&
            this.hora[i].abre >= this.hora[i - 1].abre &&
            this.hora[i].abre <= this.hora[i - 1].fecha
          ) {
            this.errohorAbre[i] = "Horários não podem estar na mesma faixa";
            this.errohorFecha[i] = "Horários não podem estar na mesma faixa";
          } else if (
            (i >= 1 &&
              this.hora[i].abre <= this.hora[i - 1].abre &&
              this.hora[i].fecha >= this.hora[i - 1].fecha) ||
            (i >= 1 &&
              this.hora[i].abre <= this.hora[i - 1].fecha &&
              this.hora[i].fecha >= this.hora[i - 1].fecha)
          ) {
            this.errohorAbre[i] = "Horários não podem estar na mesma faixa";
            this.errohorFecha[i] = "Horários não podem estar na mesma faixa";
          } else if (i == this.hora.length - 1 && validado == true) {
            this.alteraHorario(this.horarioSelecionado);
          }
        }
      }
    },
    //Verfica se é mobile ou não para melhor adaptação das colunas da página
    isMobile() {
      if (this.$isMobile()) {
        this.colsForm = 12;
      } else {
        this.colsForm = 4;
        this.colsDialog = 6;
      }
    },
    //coloca um 0 antes do numero inserido caso o mesmo tenha somente uma casa decimal
    coloca0Antes(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    //Recebe o numero do dia atual e o horário e faz a comparação para mostrar o status
    recebeHorario() {
      var aberto = false;
      var horarioAtual =
        this.coloca0Antes(new Date().getHours(), 2) +
        ":" +
        this.coloca0Antes(new Date().getMinutes(), 2);
      var diaSemana = new Date().getDay();
      if (diaSemana == 6) {
        for (var i = 0; i < this.horarios.sabado.length; i++) {
          if (
            horarioAtual <= this.horarios.sabado[i].fecha &&
            horarioAtual >= this.horarios.sabado[i].abre &&
            this.horarios.sabado[i].abre != "" &&
            this.horarios.sabado[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 5) {
        for (i = 0; i < this.horarios.sexta.length; i++) {
          if (
            horarioAtual <= this.horarios.sexta[i].fecha &&
            horarioAtual >= this.horarios.sexta[i].abre &&
            this.horarios.sexta[i].abre != "" &&
            this.horarios.sexta[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 4) {
        for (i = 0; i < this.horarios.quinta.length; i++) {
          if (
            horarioAtual <= this.horarios.quinta[i].fecha &&
            horarioAtual >= this.horarios.quinta[i].abre &&
            this.horarios.quinta[i].abre != "" &&
            this.horarios.quinta[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 3) {
        for (i = 0; i < this.horarios.quarta.length; i++) {
          if (
            horarioAtual <= this.horarios.quarta[i].fecha &&
            horarioAtual >= this.horarios.quarta[i].abre &&
            this.horarios.quarta[i].abre != "" &&
            this.horarios.quarta[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 2) {
        for (i = 0; i < this.horarios.terca.length; i++) {
          if (
            horarioAtual <= this.horarios.terca[i].fecha &&
            horarioAtual >= this.horarios.terca[i].abre &&
            this.horarios.terca[i].abre != "" &&
            this.horarios.terca[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 1) {
        for (i = 0; i < this.horarios.segunda.length; i++) {
          if (
            horarioAtual <= this.horarios.segunda[i].fecha &&
            horarioAtual >= this.horarios.segunda[i].abre &&
            this.horarios.segunda[i].abre != "" &&
            this.horarios.segunda[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      } else if (diaSemana == 0) {
        for (i = 0; i < this.horarios.domingo.length; i++) {
          if (
            horarioAtual <= this.horarios.domingo[i].fecha &&
            horarioAtual >= this.horarios.domingo[i].abre &&
            this.horarios.domingo[i].abre != "" &&
            this.horarios.domingo[i].fecha != ""
          ) {
            this.status = "Aberto";
            this.cor = "justify-center text-green";
            aberto = true;
          }
        }
        if (aberto == false) {
          this.status = "Fechado";
          this.cor = "justify-center text-red";
        }
      }
    },
    //Pega as informações de horário do banco de dados, faz um filtro se não existe ou esta vazio, para não mostrar no select de horarios e ou criar caso seja vazio
    getHorarios() {
      onSnapshot(doc(db, this.database.cnpj, "dadosempresa"), (doc) => {
        this.horarios = [];
        if (doc.data().dadosempresa.horarios[0] == undefined) {
          this.horarios = {};
        } else {
          this.horarios = doc.data().dadosempresa.horarios[0];
        }
        this.diasSelect = [
          "Domingo",
          "Segunda-Feira",
          "Terca-Feira",
          "Quarta-Feira",
          "Quinta-Feira",
          "Sexta-Feira",
          "Sabado",
        ];
        var casosGet = [
          this.horarios.domingo == undefined ||
            this.horarios.domingo[0].abre == "",
          this.horarios.segunda == undefined ||
            this.horarios.segunda[0].abre == "",
          this.horarios.terca == undefined || this.horarios.terca[0].abre == "",
          this.horarios.quarta == undefined ||
            this.horarios.quarta[0].abre == "",
          this.horarios.quinta == undefined ||
            this.horarios.quinta[0].abre == "",
          this.horarios.sexta == undefined || this.horarios.sexta[0].abre == "",
          this.horarios.sabado == undefined ||
            this.horarios.sabado[0].abre == "",
        ];
        if (casosGet[0]) {
          var domingo = [{ abre: "", fecha: "", util: "" }];
          this.horarios.domingo = domingo;
          var index0 = this.diasSelect.indexOf("Domingo");
          this.diasSelect.splice(index0, 1);
        }
        if (casosGet[1]) {
          var segunda = [{ abre: "", fecha: "", util: "" }];
          this.horarios.segunda = segunda;
          var index1 = this.diasSelect.indexOf("Segunda-Feira");
          this.diasSelect.splice(index1, 1);
        }
        if (casosGet[2]) {
          var terca = [{ abre: "", fecha: "", util: "" }];
          this.horarios.terca = terca;
          var index2 = this.diasSelect.indexOf("Terca-Feira");
          this.diasSelect.splice(index2, 1);
        }
        if (casosGet[3]) {
          var quarta = [{ abre: "", fecha: "", util: "" }];
          this.horarios.quarta = quarta;
          var index3 = this.diasSelect.indexOf("Quarta-Feira");
          this.diasSelect.splice(index3, 1);
        }
        if (casosGet[4]) {
          var quinta = [{ abre: "", fecha: "", util: "" }];
          this.horarios.quinta = quinta;
          var index4 = this.diasSelect.indexOf("Quinta-Feira");
          this.diasSelect.splice(index4, 1);
        }
        if (casosGet[5]) {
          var sexta = [{ abre: "", fecha: "", util: "" }];
          this.horarios.sexta = sexta;
          var index5 = this.diasSelect.indexOf("Sexta-Feira");
          this.diasSelect.splice(index5, 1);
        }
        if (casosGet[6]) {
          var sabado = [{ abre: "", fecha: "", util: "" }];
          this.horarios.sabado = sabado;
          var index6 = this.diasSelect.indexOf("Sabado");
          this.diasSelect.splice(index6, 1);
        }
        this.recebeHorario();
        this.dianaoUtil();
      });
    }, //Faz um filtro e dependendo do horário selecionado puxa as informações para o form
    selectHorario(horarioSelecionado) {
      this.horarioSelecionado = horarioSelecionado
      this.hora = [{}];
      var casosSelect = [
        horarioSelecionado == "Domingo",
        horarioSelecionado == "Segunda-Feira",
        horarioSelecionado == "Terca-Feira",
        horarioSelecionado == "Quarta-Feira",
        horarioSelecionado == "Quinta-Feira",
        horarioSelecionado == "Sexta-Feira",
        horarioSelecionado == "Sabado",
      ];
      switch (casosSelect.indexOf(true)) {
        case 0:
          for (var i = 0; i < this.horarios.domingo.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[0];
            this.hora[i].abre = this.horarios.domingo[i].abre;
            this.hora[i].fecha = this.horarios.domingo[i].fecha;
            this.hora[0].util = this.horarios.domingo[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 1:
          i = 0;
          for (i; i < this.horarios.segunda.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[1];
            this.hora[i].abre = this.horarios.segunda[i].abre;
            this.hora[i].fecha = this.horarios.segunda[i].fecha;
            this.hora[0].util = this.horarios.segunda[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 2:
          i = 0;
          for (i; i < this.horarios.terca.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[2];
            this.hora[i].abre = this.horarios.terca[i].abre;
            this.hora[i].fecha = this.horarios.terca[i].fecha;
            this.hora[0].util = this.horarios.terca[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 3:
          i = 0;
          for (i; i < this.horarios.quarta.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[3];
            this.hora[i].abre = this.horarios.quarta[i].abre;
            this.hora[i].fecha = this.horarios.quarta[i].fecha;
            this.hora[0].util = this.horarios.quarta[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 4:
          i = 0;
          for (i; i < this.horarios.quinta.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[4];
            this.hora[i].abre = this.horarios.quinta[i].abre;
            this.hora[i].fecha = this.horarios.quinta[i].fecha;
            this.hora[0].util = this.horarios.quinta[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 5:
          i = 0;
          for (i; i < this.horarios.sexta.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[5];
            this.hora[i].abre = this.horarios.sexta[i].abre;
            this.hora[i].fecha = this.horarios.sexta[i].fecha;
            this.hora[0].util = this.horarios.sexta[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
        case 6:
          i = 0;
          for (i; i < this.horarios.sabado.length; i++) {
            this.hora[i] = { abre: "", fecha: "", util: "" };
            this.hora[0].dia = this.dias[6];
            this.hora[i].abre = this.horarios.sabado[i].abre;
            this.hora[i].fecha = this.horarios.sabado[i].fecha;
            this.hora[0].util = this.horarios.sabado[0].util;
            this.textoHor = "Alteracao de Horário";
            this.textoButton = "SALVAR";
          }
          break;
      }
    },
    //Caso o usuário esteja criando um horário inexistente ele cria, caso tenha apertado em alterar muda os valores do horário ja existente
    alteraHorario(horarioSelecionado) {
      const docRef = doc(db, this.database.cnpj, "dadosempresa");
      if (this.textoButton == "CRIAR") {
        var casosCria = [
          this.hora[0].dia == "Domingo",
          this.hora[0].dia == "Segunda-Feira",
          this.hora[0].dia == "Terca-Feira",
          this.hora[0].dia == "Quarta-Feira",
          this.hora[0].dia == "Quinta-Feira",
          this.hora[0].dia == "Sexta-Feira",
          this.hora[0].dia == "Sabado",
        ];
        switch (casosCria.indexOf(true)) {
          case 0:
            this.horarios.domingo = [{}];
            for (var i = 0; i < this.hora.length; i++) {
              this.horarios.domingo[i] = { abre: "", fecha: "", util: "" };
              this.horarios.domingo[0].util = this.hora[0].util;
              this.horarios.domingo[i].abre = this.hora[i].abre;
              this.horarios.domingo[i].fecha = this.hora[i].fecha;
            }
            break;
          case 1:
            i = 0;
            this.horarios.segunda = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.segunda[i] = { abre: "", fecha: "", util: "" };
              this.horarios.segunda[i].abre = this.hora[i].abre;
              this.horarios.segunda[i].fecha = this.hora[i].fecha;
              this.horarios.segunda[0].util = this.hora[0].util;
            }
            break;
          case 2:
            i = 0;
            this.horarios.terca = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.terca[i] = { abre: "", fecha: "", util: "" };
              this.horarios.terca[i].abre = this.hora[i].abre;
              this.horarios.terca[i].fecha = this.hora[i].fecha;
              this.horarios.terca[0].util = this.hora[0].util;
            }
            break;
          case 3:
            i = 0;
            this.horarios.quarta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.quarta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.quarta[i].abre = this.hora[i].abre;
              this.horarios.quarta[i].fecha = this.hora[i].fecha;
              this.horarios.quarta[0].util = this.hora[0].util;
            }
            break;
          case 4:
            i = 0;
            this.horarios.quinta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.quinta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.quinta[i].abre = this.hora[i].abre;
              this.horarios.quinta[i].fecha = this.hora[i].fecha;
              this.horarios.quinta[0].util = this.hora[0].util;
            }
            break;
          case 5:
            i = 0;
            this.horarios.sexta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.sexta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.sexta[i].abre = this.hora[i].abre;
              this.horarios.sexta[i].fecha = this.hora[i].fecha;
              this.horarios.sexta[0].util = this.hora[0].util;
            }
            break;
          case 6:
            i = 0;
            this.horarios.sabado = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.sabado[i] = { abre: "", fecha: "", util: "" };
              this.horarios.sabado[i].abre = this.hora[i].abre;
              this.horarios.sabado[i].fecha = this.hora[i].fecha;
              this.horarios.sabado[0].util = this.hora[0].util;
            }
            break;
        }
      } else if (this.textoButton == "SALVAR") {
        var casosAltera = [
          horarioSelecionado == "Domingo",
          horarioSelecionado == "Segunda-Feira",
          horarioSelecionado == "Terca-Feira",
          horarioSelecionado == "Quarta-Feira",
          horarioSelecionado == "Quinta-Feira",
          horarioSelecionado == "Sexta-Feira",
          horarioSelecionado == "Sabado",
        ];
        switch (casosAltera.indexOf(true)) {
          case 0:
            i = 0;
            this.horarios.domingo = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.domingo[i] = { abre: "", fecha: "", util: "" };
              this.horarios.domingo[0].util = this.hora[0].util;
              this.horarios.domingo[i].abre = this.hora[i].abre;
              this.horarios.domingo[i].fecha = this.hora[i].fecha;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 1:
            i = 0;
            this.horarios.segunda = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.segunda[i] = { abre: "", fecha: "", util: "" };
              this.horarios.segunda[i].abre = this.hora[i].abre;
              this.horarios.segunda[i].fecha = this.hora[i].fecha;
              this.horarios.segunda[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 2:
            i = 0;
            this.horarios.terca = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.terca[i] = { abre: "", fecha: "", util: "" };
              this.horarios.terca[i].abre = this.hora[i].abre;
              this.horarios.terca[i].fecha = this.hora[i].fecha;
              this.horarios.terca[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 3:
            i = 0;
            this.horarios.quarta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.quarta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.quarta[i].abre = this.hora[i].abre;
              this.horarios.quarta[i].fecha = this.hora[i].fecha;
              this.horarios.quarta[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 4:
            i = 0;
            this.horarios.quinta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.quinta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.quinta[i].abre = this.hora[i].abre;
              this.horarios.quinta[i].fecha = this.hora[i].fecha;
              this.horarios.quinta[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 5:
            i = 0;
            this.horarios.sexta = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.sexta[i] = { abre: "", fecha: "", util: "" };
              this.horarios.sexta[i].abre = this.hora[i].abre;
              this.horarios.sexta[i].fecha = this.hora[i].fecha;
              this.horarios.sexta[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
          case 6:
            i = 0;
            this.horarios.sabado = [{}];
            for (i; i < this.hora.length; i++) {
              this.horarios.sabado[i] = { abre: "", fecha: "", util: "" };
              this.horarios.sabado[i].abre = this.hora[i].abre;
              this.horarios.sabado[i].fecha = this.hora[i].fecha;
              this.horarios.sabado[0].util = this.hora[0].util;
              this.textoHor = "Inserção de Horário";
              this.textoButton = "CRIAR";
            }
            break;
        }
      }
      this.limpaForm();
      var horarios = [this.horarios];
      updateDoc(docRef, {
        "dadosempresa.horarios": horarios,
      });
    },
    //Pede se o usuario realmente deseja excluir o horário e apaga as informações de horario e dia util após envia para o banco de dados
    removeHorario(horarioSelecionado) {
      if (
        window.confirm(
          "Realmente deseja excluir o Horário de " + horarioSelecionado + "?"
        )
      ) {
        const docRef = doc(db, this.database.cnpj, "dadosempresa");
        var casosRemove = [
          horarioSelecionado == "Domingo",
          horarioSelecionado == "Segunda-Feira",
          horarioSelecionado == "Terca-Feira",
          horarioSelecionado == "Quarta-Feira",
          horarioSelecionado == "Quinta-Feira",
          horarioSelecionado == "Sexta-Feira",
          horarioSelecionado == "Sabado",
        ];
        switch (casosRemove.indexOf(true)) {
          case 0:
            this.horarios.domingo = [{ abre: "", fecha: "", util: "" }];
            break;
          case 1:
            this.horarios.segunda = [{ abre: "", fecha: "", util: "" }];
            break;
          case 2:
            this.horarios.terca = [{ abre: "", fecha: "", util: "" }];
            break;
          case 3:
            this.horarios.quarta = [{ abre: "", fecha: "", util: "" }];
            break;
          case 4:
            this.horarios.quinta = [{ abre: "", fecha: "", util: "" }];
            break;
          case 5:
            this.horarios.sexta = [{ abre: "", fecha: "", util: "" }];
            break;
          case 6:
            this.horarios.sabado = [{ abre: "", fecha: "", util: "" }];
            break;
        }
        this.limpaForm();
        var horarios = [this.horarios];
        updateDoc(docRef, {
          "dadosempresa.horarios": horarios,
        });
      }
    },
    //Limpa o formulário
    limpaForm() {
      this.dialog = false;
      this.panel = -1;
      this.horarioSelecionado = "";
      this.hora = [{}];
      this.errohorAbre = [];
      this.errohorFecha = [];
      this.erroDia = "";
      this.erroCheckbox = "";
      this.textoHor = "Inserção de Horário";
      this.textoButton = "CRIAR";
    },
    //Se o dia anterior é não util o sistema apaga do banco de dados ao logar
    dianaoUtil() {
      const docRef = doc(db, this.database.cnpj, "dadosempresa");
      var diaSemana = new Date().getDay();
      var casosdianaoUtil = [
        diaSemana == 6 && this.horarios.sexta[0].util == "false",
        diaSemana == 5 && this.horarios.quinta[0].util == "false",
        diaSemana == 4 && this.horarios.quarta[0].util == "false",
        diaSemana == 3 && this.horarios.terca[0].util == "false",
        diaSemana == 2 && this.horarios.segunda[0].util == "false",
        diaSemana == 1 && this.horarios.domingo[0].util == "false",
        diaSemana == 0 && this.horarios.sabado[0].util == "false",
      ];
      if (casosdianaoUtil[0]) {
        this.horarios.sexta = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[1]) {
        this.horarios.quinta = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[2]) {
        this.horarios.quarta = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[3]) {
        this.horarios.terca = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[4]) {
        this.horarios.segunda = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[5]) {
        this.horarios.domingo = [{ abre: "", fecha: "", util: "" }];
      }
      if (casosdianaoUtil[6]) {
        this.horarios.sabado = [{ abre: "", fecha: "", util: "" }];
      }
      var horarios = [this.horarios];
      updateDoc(docRef, {
        "dadosempresa.horarios": horarios,
      });
    },
  },
  created() {
    this.getHorarios();

    this.isMobile();
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: solid; /* for Chrome, Safari, and Opera */
}
.v-overlay__content {
  width: 150px !important;
  display: block !important;
}

.grey {
  animation: transitionIn 0.75s;
}
@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
.left .v-input__control {
  justify-content: start;
}
</style>
