<template>
  <v-dialog v-model="dialogFi.ativo">
    <v-row>
      <v-col cols="12" md="4" class="mx-auto" style="">
        <v-card style="max-height: 80vh !important; overflow: scroll">
          <v-row>
            <v-col>
              <v-card-title>
                <h2>Pedido Nº {{ dialogFi.pedido.num }}</h2>
              </v-card-title>
              <v-card-text class="d-flex">
                <p class="font-weight-bold">Status:</p>
                <p class="px-2 font-weight-bold text-black rounded-xl">
                  {{dialogFi.pedido.status == 'cancelado' ? 'Cancelado' :'Finalizado'}}
                </p>
              </v-card-text>
              <v-card-subtitle>
                Nome:
                {{
                  dialogFi.pedido.cliente &&
                  this.clientes.find(
                    (val) => val.cpf == dialogFi.pedido.cliente
                  ).nome
                    ? this.clientes.find(
                        (val) => val.cpf == dialogFi.pedido.cliente
                      ).nome
                    : dialogFi.pedido.dadosCliente.nome
                }}
              </v-card-subtitle>
              <v-card-subtitle>
                Cpf:
                {{
                  dialogFi.pedido.cliente
                    ? dialogFi.pedido.cliente
                    : "Nao informado"
                }}
              </v-card-subtitle>
              <v-card-text>
                <p>Número: {{ dialogFi.pedido.clienteNum }}</p>
                <template v-if="dialogFi.pedido.dataEntrega">
                  Data Entrega:
                  {{
                    dialogFi.pedido.dataEntrega.split("-").reverse().join("/")
                  }}
                  <p>
                    Hora Entrega:
                    {{
                      dialogFi.pedido.horaEntrega.split("-").reverse().join("/")
                    }}
                  </p>
                </template>
                <template v-else>
                  <p class="text-green">Pedido</p>
                </template>
                <p>
                  Endereco:
                  {{
                    this.clientes.find(
                      (val) => val.cpf == dialogFi.pedido.cliente
                    )
                      ? this.clientes.find(
                          (val) => val.cpf == dialogFi.pedido.cliente
                        ).endereco
                        ? this.clientes.find(
                            (val) => val.cpf == dialogFi.pedido.cliente
                          ).endereco
                        : "Não Necessita"
                      : this.dialogFi.pedido.dadosCliente.endereco
                      ? this.dialogFi.pedido.dadosCliente.endereco
                      : "Não Necessita"
                  }}{{ this.dialogFi.pedido.dadosCliente.numero ? ', ' +  this.dialogFi.pedido.dadosCliente.numero : '' }}{{ this.dialogFi.pedido.dadosCliente.complemento ? + ', ' + this.dialogFi.pedido.dadosCliente.complemento : ''}}
                </p>
              </v-card-text>
              <v-card-text>
                <h2>Produtos:</h2>
              </v-card-text>
              <v-card-text
                v-for="(produto, index) in dialogFi.pedido.produtos"
                :key="produto"
              >
                <div class="d-flex mt-4">
                  <div class="justify-content-left" style="width: 100%">
                    <p>{{ index + 1 }} -- {{ produto.nome }}</p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="justify-content-left ml-2" style="width: 80%">
                    <p>{{ produto.quantidade }} x {{ produto.valor }}</p>
                  </div>
                  <div
                    class="justify-content-right text-left"
                    style="width: 20%"
                  >
                    <p>
                      {{
                        (parseFloat(produto.valor) * produto.quantidade)
                          .toFixed(2)
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </div>

                <div v-if="produto.saborComp">
                  <div class="d-flex">
                    <div class="justify-content-left" style="width: 100%">
                      <strong>Sabores: </strong>
                    </div>
                  </div>
                  <div class="d-flex ml-2">
                    <div class="justify-content-left" style="width: 80%">
                      <p>{{ produto.saboresDesc }}</p>
                    </div>
                    <div
                      class="justify-content-right text-left"
                      style="width: 20%"
                    >
                      <p>
                        {{
                          parseFloat(produto.valorSaboresDesc)
                            .toFixed(2)
                            .replace(".", ",")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="produto.adicionaisDesc">
                  <div class="d-flex">
                    <div class="justify-content-left" style="width: 100%">
                      <p>Adicionais:</p>
                    </div>
                  </div>
                  <div class="d-flex ml-2">
                    <div class="justify-content-left" style="width: 80%">
                      <p>{{ produto.adicionaisDesc }}</p>
                    </div>
                    <div
                      class="justify-content-right text-left"
                      style="width: 20%"
                    >
                      <p>
                        {{
                          parseFloat(produto.precoAdicionais)
                            .toFixed(2)
                            .replace(".", ",")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="parseInt(index) == dialogFi.pedido.produtos.length - 1"
                  class="mt-4"
                >
                  <div class="d-flex" v-if="dialogFi.pedido.valorTele">
                    <div class="justify-content-left" style="width: 80%">
                      <p>Valor Tele:</p>
                    </div>
                    <div
                      class="justify-content-right text-left"
                      style="width: 20%"
                    >
                      <p>
                        {{
                          parseFloat(dialogFi.pedido.valorTele)
                            .toFixed(2)
                            .replace(".", ",")
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="justify-content-left" style="width: 80%">
                      <p>Valor total:</p>
                    </div>
                    <div
                      class="justify-content-right text-left"
                      style="width: 20%"
                    >
                      <strong>{{
                        parseFloat(dialogFi.pedido.preco)
                          .toFixed(2)
                          .replace(".", ",")
                      }}</strong>
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-card-text>
                <p v-if="dialogFi.pedido.observacao">
                  Observação: <strong>{{ dialogFi.pedido.observacao }}</strong>
                </p>
                <p>
                  Forma de Pagamento:
                  <strong>{{ dialogFi.pedido.metodoPgto }}</strong>
                </p>
                <p>
                  Ambiente de Pgto:
                  {{
                    dialogFi.pedido.ambientePgto
                      ? dialogFi.pedido.ambientePgto
                      : "Pagar na entrega/retirada"
                  }}
                </p>
                <p
                  v-if="
                    dialogFi.pedido.ambientePgto
                      ? dialogFi.pedido.ambientePgto == 'Pagar no app'
                      : false
                  "
                >
                  Status do pagamento:
                  <strong>
                    {{
                      dialogFi.pedido.statusPgto == "CON"
                        ? "Concluido"
                        : dialogFi.pedido.statusPgto == "PEN"
                        ? "Pendente"
                        : dialogFi.pedido.statusPgto == "NOV"
                        ? "Gerado"
                        : dialogFi.pedido.statusPgto == "EXP"
                        ? "Expirado"
                        : dialogFi.pedido.statusPgto == "CAN"
                        ? "Cancelado"
                        : dialogFi.pedido.statusPgto == "NEG"
                        ? "Negado"
                        : "Erro no pagamento"
                    }}</strong
                  >
                </p>

                <p v-if="dialogFi.pedido.valorTroco">
                  Valor Troco:
                  <strong>{{
                    parseFloat(dialogFi.pedido.valorTroco)
                      .toFixed(2)
                      .replace(".", ",")
                  }}</strong>
                </p>
                <p>
                  Modo de entrega:
                  <strong>{{
                    dialogFi.pedido.valorTele ? "Tele" : "Retirada"
                  }}</strong>
                </p>
              </v-card-text>
              <v-row class="d-flex justify-space-around">
                <v-col cols="12" class="text-center">
                  <span style="color: grey">Fechar</span>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around pb-2">
                <v-col cols="12" class="text-center">
                  <v-btn
                    @click="dialogFi.ativo = false"
                    size="small"
                    icon="mdi-close"
                    label="Fechar"
                    color="red"
                  ></v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn
            v-if="dialogFi.pedido.dataEntrega"
            icon
            @click="
              imprimeEncomenda(
                this.dialogFi.pedido,
                this.dadosEmpresa,
                this.clientes
              )
            "
            elevation="0"
            style="position: absolute; right: 10px; top: 10px; cursor: pointer"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            @click="
              imprimePedido(
                this.dialogFi.pedido,
                this.dadosEmpresa,
                this.clientes
              )
            "
            elevation="0"
            style="position: absolute; right: 10px; top: 10px; cursor: pointer"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="abreWhats(this.dialogFi.pedido.clienteNum)"
            elevation="0"
            style="position: absolute; right: 50px; top: 10px; cursor: pointer"
          >
            <v-icon color="green">mdi-whatsapp</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import imprimeEncomenda from "../libs/imprimeEncomenda";
import imprimePedidos from "../libs/imprimePedidos";

export default {
  name: "dialogFinanceiro",
  data: () => ({}),
  watch: {},

  computed: {
    ...mapGetters(["dialogFi", "clientes", "dadosEmpresa"]),
  },
  methods: {
    enviaWhats(numero, numerodopedido, valor, metodoPgto, valorTele) {
      var prodWpp = null;
      if (valorTele) {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e logo sera entregue. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      } else {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e aguardand retirada. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      }
    },
    imprimeEncomenda(i, dadosEmpresa, clientes) {
      imprimeEncomenda(i, dadosEmpresa, clientes);
    },
    imprimePedido(i, dadosEmpresa, clientes) {
      imprimePedidos(i, dadosEmpresa, clientes);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
<style scoped>
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
