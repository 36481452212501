import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../store/store";

export default function getProdutos(database) {
  onSnapshot(doc(db, database, "produtos"), (doc) => {
    var data = Object.values(doc.data());
    data = data[0];
    store.commit("incrementProdutos", data);
  });
}
