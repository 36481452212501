<template>
  <v-card v-if="logado" style="">
    <v-layout>
      <v-navigation-drawer
        id="drawer"
        class="d-flex flex-column"
        style="background-color: #ea4545"
        v-model="drawer"
        :rail="rail"
        permanent
      >
        <v-list>
          <v-list-item v-if="rail == false">
            <div class="d-flex justify-center">
              <v-avatar size="125" rounded="0">
                <v-img :src="this.dadosEmpresa.img_url"></v-img>
              </v-avatar>
            </div>
          </v-list-item>
          <v-list-item
            class="text-center text-white"
            v-if="rail == false"
            :title="this.dadosEmpresa.nome"
          >
          </v-list-item>
        </v-list>

        <v-list v-model:opened="open" id="navbarList">
          <v-list-group value="Controle" prepend-icon="mdi-gamepad-round-left">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Controle"></v-list-item>
            </template>
            <div class="scrollable">
              <v-list-item
                v-for="([title, icon, to], i) in controles"
                :key="i"
                :to="to"
                :title="title"
                :prepend-icon="icon"
                :value="title"
                :style="rail ? 'margin-left: -55px;' : ''"
                ><v-tooltip activator="parent" location="end">{{
                  title
                }}</v-tooltip></v-list-item
              >
            </div>
          </v-list-group>

          <v-list-group
            value="Cadastro"
            prepend-icon="mdi-file-edit-outline"
            v-if="this.cadastros"
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Cadastro"></v-list-item>
            </template>

            <div class="scrollable">
              <v-list-item
                v-for="([title, icon, to], i) in cruds"
                :key="i"
                :to="to"
                :value="title"
                :title="title"
                :prepend-icon="icon"
                :style="rail ? 'margin-left: -55px;' : ''"
              >
                <v-tooltip activator="parent" location="end">{{
                  title
                }}</v-tooltip></v-list-item
              >
            </div>
          </v-list-group>

          <v-tooltip v-if="dadosCadastrais == true" right>
            <template v-slot:activator="{ props }">
              <div v-bind="props">
                <v-list-item
                  prepend-icon="mdi-office-building"
                  title="Dados Cadastrais"
                  :to="{ name: 'DadosCad' }"
                ></v-list-item>
              </div>
            </template>
            <p>Dados Cadastrais</p>
          </v-tooltip>
        </v-list>

        <div class="text-right">
          <v-btn
            style="margin-top: 150px"
            variant="text"
            icon="mdi-chevron-right"
            color="black"
            @click="rail = false"
            v-if="rail == true"
          ></v-btn>
        </div>
        <div class="text-right">
          <v-btn
            variant="text"
            icon="mdi-chevron-left"
            color="black"
            @click="rail = true"
            v-if="rail == false"
          ></v-btn>
        </div>

        <template v-slot:append>
          <v-list-item
            class="mb-5 text-center"
            v-if="!rail"
            @click="sair()"
            :to="{ name: 'Login' }"
          >
            <v-icon class="mr-2">mdi-exit-to-app</v-icon>Sair<v-tooltip
              activator="parent"
              location="end"
              >Sair</v-tooltip
            ></v-list-item
          >
          <v-list-item
            class="mb-5 text-center"
            v-if="rail"
            @click="sair()"
            :to="{ name: 'Login' }"
            style="margin-left: -30px"
          >
            <v-icon>mdi-exit-to-app</v-icon
            ><v-tooltip activator="parent" location="end"
              >Sair</v-tooltip
            ></v-list-item
          >
        </template>
      </v-navigation-drawer>
      <v-main style="height: 100%"><router-view /><NotificacoesBar /></v-main>
    </v-layout>
  </v-card>
  <v-col
    v-if="carregamento"
    cols="12"
    class="text-center"
    style="position: absolute; z-index: 9; top: 45%"
  >
    <v-progress-circular indeterminate color="red"></v-progress-circular>
  </v-col>
</template>
<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, updateDoc, collection, query, getDocs } from "firebase/firestore";
import { mapGetters } from "vuex";
import { db } from "../firebase";
import getDatabase from "../libs/getDatabase";
import getClientes from "../libs/getCliente";
import getProdutos from "../libs/getProdutos";
import getCategorias from "../libs/getCategorias";
import getPedidos from "../libs/getPedidos";
import getDadosEmpresa from "../libs/getDadosEmpresa";
import getPagamento from "../libs/getPagamento";
import getAdicionais from "../libs/getAdicionais";
import getCombos from "../libs/getCombos";
import fetchPeriodicImages from "../libs/images/getData";

import NotificacoesBar from "./NotificacoesBar.vue";

import { connectWebSocket } from "../libs/websocket";

import { store } from "../store/store";

export default {
  name: "NavBar",
  components: { NotificacoesBar },
  data: () => ({
    open: ["Pedidos"],
    controles: [],
    cruds: [
      [
        "Categorias",
        "mdi-format-list-bulleted-triangle",
        "/cadastro/categorias",
      ],
      ["Produtos", "mdi-view-list-outline", "/cadastro/produtos"],
      ["Adicionais", "mdi-cookie-plus-outline", "/cadastro/adicionais"],
      ["Combos", "mdi-tag-multiple-outline", "/cadastro/combos"],
      ["Vitrine", "mdi-store-outline", "/cadastro/vitrine"],
      ["Ordem", "mdi-format-vertical-align-center", "/cadastro/ajustedeordem"],
    ],
    status: "",
    carregamento: false,
    cadastros: false,
    horarios: {},
    dadosCadastrais: false,
    drawer: true,
    novoUser: "false",
    rail: false,
    logado: false,
    autenticado: getAuth(),
  }),
  watch: {
    dadosEmpresa(n) {
      if (n) {
        this.getHorarios();
        if (n.nome) {
          this.getDb();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["database", "dadosEmpresa"]),
  },
  methods: {
    async getDb() {
      /*se o estado do autenticado for true ele pega os dados do usuario e procura na tabela usuarios para
      solicitar a informação de banco de dados daquele usuário. Caso tenha, redireciona para a tela de pedidos com o bancos de dados correto do usuario. Verifica quais paginas estao liberadas para aquele mesmo usuario**/
      //Pega o banco onde estão as informações da empresa

      if (this.database) {
        this.logado = true;
        let controles = [
          {
            nome: "pedidos",
            status: false,
          },
          {
            nome: "Encomendas",
            status: false,
          },
          {
            nome: "Horários",
            status: false,
          },
          {
            nome: "Financeiro",
            status: false,
          },
          {
            nome: "Cancelados",
            status: false,
          },
        ];
        for (let o = 0; o < this.database.acessos.length; o++) {
          if (
            this.database.acessos[o] == "Pedidos" &&
            this.novoUser == "false"
          ) {
            controles[0].status = true;
          }
          if (
            this.database.acessos[o] == "Encomendas" &&
            this.novoUser == "false"
          ) {
            // this.encomendas = true
            controles[1].status = true;
          }
          if (
            this.database.acessos[o] == "Horários" &&
            this.novoUser == "false"
          ) {
            controles[2].status = true;
          }
          if (
            this.database.acessos[o] == "Financeiro" &&
            this.novoUser == "false"
          ) {
            controles[3].status = true;
            controles[4].status = true
          }
          if (
            this.database.acessos[o] == "Cadastros" &&
            this.novoUser == "false"
          ) {
            this.cadastros = true;
          }
          if (this.database.acessos[o] == "Dados Cadastrais") {
            this.dadosCadastrais = true;
          }
        }
        this.controles = [
          ["Pedidos", "mdi-moped-outline", { name: "Pedidos" }],
          ["Encomendas", "mdi-package-variant", { name: "Encomendas" }],
          ["Horários", "mdi-clock-time-five-outline", { name: "Horarios" }],
          ["Financeiro", "mdi-poll", { name: "Financeiro" }],
          ["Cancelados", "mdi-close-circle-outline", { name: "Cancelados" }],
        ];
        if (controles[0].status != true) {
          this.controles.splice(0, 1);
        }
        if (controles[1].status != true) {
          this.controles.splice(1, 1);
        }
        if (controles[2].status != true) {
          this.controles.splice(2, 1);
        }
        if (controles[3].status != true) {
          this.controles.splice(3, 1);
        }
        if (
          this.dadosCadastrais == false &&
          this.cadastros == false &&
          controles[0].status != true &&
          controles[1].status != true &&
          controles[2].status != true &&
          controles[3].status != true
        ) {
          alert(
            "Acesso Negado. Solicite ao suporte para inserir abas a este usuário."
          );
          this.logado = false;
          signOut(this.autenticado).then(() => {
            this.$router.push({ name: "Login" });
            localStorage.removeItem("logado");
            localStorage.removeItem("novoUser");
          });
        }
        if (this.$route.path == "/" && this.pedidos == true) {
          this.$router.push({
            name: "Pedidos",
          });
        } else if (this.$route.path != "/") {
          this.$router.push({
            path: this.$route.path,
          });
        } else if (this.cadastros == true) {
          this.$router.push({
            name: "Cadastro",
          });
        } else if (this.encomendas == true) {
          this.$router.push({
            name: "Encomendas",
          });
        } else if (this.horariosAba == true) {
          this.$router.push({
            name: "Horarios",
          });
        } else if (this.financeiro == true) {
          this.$router.push({
            name: "Financeiro",
          });
        } else if (this.dadosCadastrais == true) {
          this.$router.push({
            name: "DadosCad",
          });
        }
        if (this.logado == true) {
          setTimeout(() => {
            this.getEmpresa();
          }, 0);
        }
      } else {
        this.logado = false;
      }
      setTimeout(() => {
        this.carregamento = false;
      }, 250);
    },
    getEmpresa() {
      if (this.dadosEmpresa && this.dadosEmpresa.nome != "") {
        this.novoUser = "false";
        localStorage.setItem("novoUser", false);
      } else {
        //se dados empresa estiver vazio define novoUser como true
        this.novoUser = "true";
        localStorage.setItem("novoUser", true);
        var data = {
          endereco: "",
          cnpj: "",
          horarios: [{}],
          img_url: "",
          nome: "",
          telefones: [{}],
          whats: "",
        };
        store.commit("incrementDadosEmpresa", data);
        if (localStorage.getItem("novoUser") == "true") {
          this.$router.push({
            name: "DadosCad",
          });
        }
      }
    },
    getHorarios() {
      this.horarios = [];
      setTimeout(() => {
        if (this.dadosEmpresa != undefined && this.dadosEmpresa != "") {
          if (this.dadosEmpresa.horarios[0].length != undefined) {
            this.horarios = this.dadosEmpresa.horarios[0];
          }
        } else {
          this.horarios = {};
        }
        if (this.novoUser == "false" && this.horarios.length > 0) {
          this.dianaoUtil();
        }
      }, 250);
    },
    dianaoUtil() {
      const docRef = doc(db, this.database.cnpj, "dadosempresa");
      var diaSemana = new Date().getDay();
      var casosdianaoUtil = [
        diaSemana == 6 && this.horarios.sexta.util == "false",
        diaSemana == 5 && this.horarios.quinta.util == "false",
        diaSemana == 4 && this.horarios.quarta.util == "false",
        diaSemana == 3 && this.horarios.terca.util == "false",
        diaSemana == 2 && this.horarios.segunda.util == "false",
        diaSemana == 1 && this.horarios.domingo.util == "false",
        diaSemana == 0 && this.horarios.sabado.util == "false",
      ];
      if (casosdianaoUtil[0]) {
        this.horarios.sexta.abre = "";
        this.horarios.sexta.fecha = "";
        this.horarios.sexta.util = "";
      }
      if (casosdianaoUtil[1]) {
        this.horarios.quinta.abre = "";
        this.horarios.quinta.fecha = "";
        this.horarios.quinta.util = "";
      }
      if (casosdianaoUtil[2]) {
        this.horarios.quarta.abre = "";
        this.horarios.quarta.fecha = "";
        this.horarios.quarta.util = "";
      }
      if (casosdianaoUtil[3]) {
        this.horarios.terca.abre = "";
        this.horarios.terca.fecha = "";
        this.horarios.terca.util = "";
      }
      if (casosdianaoUtil[4]) {
        this.horarios.segunda.abre = "";
        this.horarios.segunda.fecha = "";
        this.horarios.segunda.util = "";
      }
      if (casosdianaoUtil[5]) {
        this.horarios.domingo.abre = "";
        this.horarios.domingo.fecha = "";
        this.horarios.domingo.util = "";
      }
      if (casosdianaoUtil[6]) {
        this.horarios.sabado.abre = "";
        this.horarios.sabado.fecha = "";
        this.horarios.sabado.util = "";
      }
      var horarios = [this.horarios];
      updateDoc(docRef, {
        "dadosempresa.horarios": horarios,
      });
    },

    sair() {
      signOut(this.autenticado).then(() => {
        this.carregamento = false;
        this.$router.push("/");
        this.logado = false;
        localStorage.removeItem("logado");
        localStorage.removeItem("novoUser");
        let socket = connectWebSocket();
        socket.close();
      });
    },
    async validaUsuario(n) {
      this.carregamento = true;
      const empresasRef = collection(db, "empresas");
      const q = query(empresasRef);
      const result = await getDocs(q);

      result.forEach((val) => {
        if (Object.keys(val.data()).length == 4) {
          if (val.data().estabelecimento.cnpj == n) {
            this.status = val.data().status;
          }
        }
      });
      if (this.status == "Inativo") {
        alert("Empresa Inativa. Entre em contato e regularize seu cadastro.");
        this.logado = false;
        signOut(this.autenticado).then(() => {
          this.$router.push({ name: "Login" });
          localStorage.removeItem("logado");
          localStorage.removeItem("novoUser");
        });
      }
    },
  },
  activated() {
    this.validaUsuario();
  },
  async created() {
    this.rail = this.$isMobile() ? true : false;
    onAuthStateChanged(this.autenticado, (user) => {
      if (user) {
        this.carregamento = true;

        getDatabase(user).then(() => {
          this.getDb(user);
          getDadosEmpresa(this.database.cnpj);
          getPagamento(this.database.cnpj)
          this.validaUsuario(this.database.cnpj);
          if (this.novoUser == "false") {
            getClientes(this.database.cnpj);
            getProdutos(this.database.cnpj);
            getCategorias(this.database.cnpj);
            getPedidos(this.database.cnpj);
            getAdicionais(this.database.cnpj);
            getCombos(this.database.cnpj);
            fetchPeriodicImages(this.database.cnpj, true);
            this.getHorarios();
          }
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.v-list-item:hover {
  color: black;
}

a.router-link {
  width: 100%;
}
a.router-link-active {
  pointer-events: none;
  div {
    filter: opacity(120%);
  }
}
</style>
<style scoped>
.v-list-item,
span {
  min-width: 112px !important;
}
#navbarList {
  max-height: 200% !important;
}
/* .scrollable {
    overflow-y:auto;
    overflow-x: hidden;
    max-height:150px !important; 
  } */
/deep/ .v-navigation-drawer__content {
  overflow-y: auto;
  overflow-x: hidden;
}
/deep/ ::-webkit-scrollbar {
  width: 5px !important;
}
/deep/ ::-webkit-scrollbar-thumb {
  border: solid 0px rgb(255, 198, 198);
  background: rgb(255, 198, 198);
  border-radius: 25px;
}
/deep/ ::-webkit-scrollbar-track {
  background: #ea4545;
}
</style>
