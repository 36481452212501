<template>
  <v-container fluid class="grey lighten-5 pb-10" style="min-height: 100vmin">
    <v-row>
      <v-col cols="12">
        <v-tabs v-if="this.$isMobile()">
          <v-tab
            v-for="i in controle"
            :to="i.route"
            :key="i"
            color="#E74242"
            class="elevation-0"
            @click="this.controleSelecionado = i.name"
            >{{ i.name }}</v-tab
          >
          > >
        </v-tabs>
        <v-tabs v-else>
          <v-tab
            v-for="i in controle"
            :to="i.route"
            :key="i"
            color="#E74242"
            class="elevation-0"
            @click="this.controleSelecionado = i.name"
            >{{ i.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <RouterView></RouterView>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  // eslint-disable-next-line vue/multi-word-component-names
  name: "dadosCad",
  data: () => ({
    adicionais: [],
    controleSelecionado: "Dados da Empresa",
    controle: [
      { name: "Dados da Empresa", route: "/dadoscadastrais/dadosempresa" },
      { name: "Tele", route: "/dadoscadastrais/tele" },
      { name: "Formas de Pagamento", route: "/dadoscadastrais/formapgto" },
      { name: "Banner", route: "/dadoscadastrais/banner" },
      { name: "Pagamento no app", route: "/dadoscadastrais/pagamento" },
    ],
  }),
  watch: {},
  computed: {
    ...mapGetters(["database", "produtos", "categorias"]),
  },
  methods: {
    // getAdicionais() {
    //   onSnapshot(doc(db, this.database.cnpj, "adicionais"), (doc) => {
    //     this.adicionais = [];
    //     if (doc.data() !== undefined) {
    //       var i = 0;
    //       for (i in doc.data().adicionais) {
    //         this.adicionais.push(doc.data().adicionais[i]);
    //       }
    //     }
    //   });
    // },
  },
  async created() {
    if (this.$route.path == "/dadoscadastrais") {
      this.$router.push("/dadoscadastrais/dadosempresa");
    }
  },
  mounted() {},
};
</script>
<style scoped>
.grey {
  animation: transitionIn 0.75s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
</style>
