import { createWebHistory, createRouter } from "vue-router";

import Login from "../pages/Login/Login.vue";
import Pedidos from "../pages/Pedidos/Pedidos.vue";
import Encomendas from "../pages/Encomendas/Encomendas.vue";
import Horarios from "../pages/Horarios/Horarios.vue";
import Financeiro from "../pages/Financeiro/Financeiro.vue";
import Cancelados from "../pages/Cancelados/Cancelados.vue";

import DadosCad from "../pages/DadosCad/DadosCad.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/dadoscadastrais",
    name: "dadosCadastraisParent",
    component: () => import("../pages/DadosCad/DadosCadParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: DadosCad,
        name: "DadosCad",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "tele",
            component: () => import("../pages/DadosCad/containers/Tele"),
            name: "dadosCadTele",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "dadosempresa",
            component: () => import("../pages/DadosCad/containers/DadosCad"),
            name: "dadosCadEmpresa",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "formapgto",
            component: () => import("../pages/DadosCad/containers/FormaPgto"),
            name: "dadosCadFormaPgto",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "banner",
            component: () => import("../pages/DadosCad/containers/Banner"),
            name: "dadosCadBanner",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "pagamento",
            component: () => import("../pages/DadosCad/containers/Pagamento"),
            name: "dadosCadPagamento",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/encomendas",
    name: "EncomendasParent",
    component: () => import("../pages/Encomendas/EncomendasParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: Encomendas,
        name: "Encomendas",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "recebimentos",
            component: () =>
              import("../pages/Encomendas/containers/Recebimentos"),
            name: "encomendaRecebimentos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "aceitos",
            component: () => import("../pages/Encomendas/containers/Aceitos"),
            name: "encomendaAceitos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "emproducao",
            component: () =>
              import("../pages/Encomendas/containers/EmProducao"),
            name: "encomendaEmProducao",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "prontos",
            component: () => import("../pages/Encomendas/containers/Prontos"),
            name: "encomendasPronto",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "calendario",
            component: () =>
              import("../pages/Encomendas/containers/Calendario"),
            name: "encomendaCalendario",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          
        ],
      },
    ],
  },
  {
    path: "/pedidos",
    name: "PedidosParent",
    props: true,
    component: () => import("../pages/Pedidos/PedidosParent"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: Pedidos,
        name: "Pedidos",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "recebimentos",
            component: () => import("../pages/Pedidos/containers/Recebimentos"),
            name: "pedidoRecebimentos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "emproducao",
            component: () => import("../pages/Pedidos/containers/EmProducao"),
            name: "pedidoEmProducao",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "pronto",
            component: () => import("../pages/Pedidos/containers/Prontos"),
            name: "pedidoPronto",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "tele",
            component: () => import("../pages/Pedidos/containers/Tele"),
            name: "pedidoRecebimento",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          
        ],
      },
    ],
  },
  {
    path: "/financeiro",
    name: "FinanceiroParent",
    component: () => import("../pages/Financeiro/FinanceiroParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: Financeiro,
        name: "Financeiro",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "historico",
            component: () =>
              import("../pages/Financeiro/containers/HistoricoPedidos"),
            name: "HistoricoPedidos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "produtos",
            component: () => import("../pages/Financeiro/containers/ProdutosVendidos"),
            name: "ProdutosVendidos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "quantitativo",
            component: () =>
              import("../pages/Financeiro/containers/Quantitativo"),
            name: "Quantitativo",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "totalizadores",
            component: () => import("../pages/Financeiro/containers/Totalizadores"),
            name: "Totalizadores",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/cadastro",
    name: "CadastroParent",
    component: () => import("../pages/Cadastro/CadastroParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("../pages/Cadastro/Cadastro"),
        name: "Cadastro",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "produtos",
            component: () => import("../pages/Cadastro/containers/Produtos"),
            name: "cadastroProdutos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "categorias",
            component: () => import("../pages/Cadastro/containers/Categorias"),
            name: "cadastroCategorias",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "adicionais",
            component: () => import("../pages/Cadastro/containers/Adicionais"),
            name: "cadastroAdicionais",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "combos",
            component: () => import("../pages/Cadastro/containers/Combos"),
            name: "cadastroCombos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "vitrine",
            component: () => import("../pages/Cadastro/containers/Encomendas"),
            name: "cadastroEncomendas",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "ajustedeordem",
            component: () => import("../pages/Cadastro/containers/Ordem"),
            name: "cadastroOrdem",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/horarios",
    name: "Horarios",
    component: Horarios,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cancelados",
    name: "Cancelados",
    component: Cancelados,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
import { getAuth, onAuthStateChanged } from "firebase/auth";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        next();
      } else {
        next("/");
      }
    });
  } else {
    next();
  }
});
export default router;
