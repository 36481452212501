import { auth } from "@/main";
import axios from "axios";
import { Buffer } from "buffer";

export default function checkMultiplePayment(database, order_ids, sitef) {
    return new Promise((resolve) => {
      if (auth.currentUser != null) {
        auth.currentUser
          .getIdToken(true)
          .then((log) => {
            axios({
              method: "post",
              data: {
                empresa: database,
                merchant_id: sitef.merchantId,
                merchant_key: sitef.merchantKey,
                order_ids: Buffer.from(JSON.stringify(order_ids)).toString(
                  "base64"
                ),
              },
              url: `${process.env.VUE_APP_APIURL}/pagamentos/sitef/checkmultiple`,
              headers: {
                authtoken: log,
              },
            })
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                resolve(error);
              });
          })
          .catch((error) => {
            resolve(error);
          });
      } else {
        resolve("Unauthorized");
      }
    });
  
}
