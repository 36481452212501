import { store } from "../store/store";
import checkMultiplePayment from "./checkMultiplePayments";

let socket = null;
let maxAttempts = 10; // Define the maximum number of attempts
let currentAttempt = 0; // Attempt counter

export function connectWebSocket() {
  if (socket && socket.readyState === WebSocket.OPEN) {
    // WebSocket connection is already open
    console.log("WebSocket connection already open");
    return socket;
  }

  socket = new WebSocket(process.env.VUE_APP_WSURL);

  // WebSocket open event
  socket.onopen = () => {
    console.log("WebSocket connection opened");
    currentAttempt = 0; // Reset the attempt counter after a successful connection
  };

  // WebSocket close event
  socket.onclose = () => {
    console.log("WebSocket connection closed");
    if (currentAttempt < maxAttempts) {
      currentAttempt++;
      // Try to reconnect after a delay
      setTimeout(() => {
        connectWebSocket();
      }, 5000); // 5 seconds
    } else {
      console.log("Maximum reconnection attempts reached"); // Maximum attempts reached
    }
  };

  // WebSocket error event
  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
    if (currentAttempt < maxAttempts) {
      currentAttempt++;
      // Try to reconnect after a delay
      setTimeout(() => {
        connectWebSocket();
      }, 5000); // 5 seconds
    } else {
      console.log("Maximum reconnection attempts reached"); // Maximum attempts reached
    }
  };

  socket.onmessage = async (event) => {
    let data = JSON.parse(event.data)[0];
    const pedidos = store.getters.pedidos
    const count = JSON.parse(event.data)[1];
    data.map((val) => !val.statusPgto ? val.statusPgto = 'PEN' : '')
    for(let pedido of pedidos){
      for(let pedidoApi of data){
        if(pedidoApi.num == pedido.num){
          pedidoApi.statusPgto = pedido.statusPgto ? pedido.statusPgto : 'PEN'
        } 
      }
    }
    store.commit("incrementCount", count);
    store.commit("incrementPedidos", data);
  };

  return socket;
}

setInterval(() => {
  check()
}, 15000)
async function check() {
  let pedidos = [];
  let empresa = [];
  let maxBatchSize = 29;
  const sitef = store.getters.dadosEmpresa.sitef;
  let data = store.getters.pedidos
  data.map((val) => !val.statusPgto ? val.statusPgto = 'PEN' : '')
  for (let [index, pedido] of data.entries()) {
    if(!pedido.ambientePgto){
      pedido.ambientePgto = 'Pagar na entrega/retirada'
    } 
    if(pedido.status != 'geroucupom' && pedido.status != 'naoenviado' && pedido.status != 'pdf' && pedido.status != 'concluido' && pedido.ambientePgto == 'Pagar no app' && pedido.statusPgto != 'CON'){
      empresa = empresa != "" ? empresa : pedido.empresa;
      pedidos.push(pedido.num.toString());
      if (
        pedidos.length === maxBatchSize && pedidos != ''
      ) {
        await checkMultiplePayment(empresa, pedidos, sitef).then((val) => {
          if (val.data.data) {
            for (let pedido of val.data.data) {
              data.find((val) => val.num == pedido.order_id).statusPgto =
              pedido.payment ? pedido.payment.status : 'Não encontrado';
            }
          }
        });
        
        store.commit("incrementCarregando", false);
        pedidos = [];
      }
    }
    if(
      index === data.length - 1 && pedidos != ''
    ){
      await checkMultiplePayment(empresa, pedidos, sitef).then((val) => {
        if (val.data.data) {
          for (let pedido of val.data.data) {
            data.find((val) => val.num == pedido.order_id).statusPgto =
            pedido.payment ? pedido.payment.status : 'Não encontrado';
          }
        }
      });
      
      store.commit("incrementCarregando", false);
      pedidos = [];
    }
  }
  store.commit("incrementPagamentos", data);
}

// Start the WebSocket connection
connectWebSocket();