<template>
  <div 
    id="floating" 
    class="d-flex" 
    ref="draggableComponent"
    >
    <v-card :v-if="true" v-if="timer < 100" class="mb-5 mr-2" style="pointer-events: auto">
      <v-card-subtitle> Você deseja mutar as notificações? </v-card-subtitle>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn @click="(muted = true), (this.timer = 100)">
          <v-icon color="red"> mdi-bell-off </v-icon>
        </v-btn>
        <v-btn @click="(muted = false), (this.timer = 100)"
          ><v-icon color="green"> mdi-bell-ring </v-icon></v-btn
        >
      </v-card-actions>
      <v-progress-linear :model-value="timer" color="green"></v-progress-linear>
    </v-card>
    <div class="text-center text-subtitle-1" style="pointer-events: auto">
      <v-menu location="left" attach="#btnT">
        <template v-slot:activator="{ props }">
          <v-btn
            @mousedown="startDragging"
            style="margin-top: -115px; margin-right: -78px;"
            icon
            elevation="0"
            stacked
            variant="plain"
            size="x-small"
            :ripple="false"
          >
            <v-icon size="small">mdi-arrow-all</v-icon>
          </v-btn>
          <v-btn
            id="btnT"
            :disabled="timer < 100"
            :class="'text-none ' + this.timer != 100 ? 'mb-11' : 'mb-7'"
            stacked
            v-bind="props"
            icon
            size="x-small"
            elevation="0"
            variant="plain"
            :ripple="false"
          >
            <v-badge
              color="yellow"
              floating
              :content="counter == 0 ? `` : counter"
            >
              <v-icon
                size="x-large"
                :class="this.timer == 100 ? '' : ''"
                >{{ this.muted ? "mdi-bell-off" : "mdi-bell-ring" }}</v-icon
              >
            </v-badge>
          </v-btn>
        </template>
        <v-list id="draggableComponent2">
            <v-list-item v-if="countPedidos > 0" :to="{ name: 'Pedidos' }">
              <v-list-item-subtitle class="text-bold">{{
                this.texto[1]
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="countEncomendas > 0" :to="{ name: 'Encomendas' }">
              <v-list-item-subtitle class="text-bold">{{
                this.texto[0]
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              @click="muted = false"
              v-if="muted == true"
              class="text-center"
            >
              <v-icon color="green" size="small"> mdi-bell-ring </v-icon>
            </v-list-item>
            <v-list-item
              @click="muted = true"
              v-if="muted == false"
              class="text-center"
            >
              <v-icon color="red" size="small"> mdi-bell-off </v-icon>
            </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificacoesBar",
  data: () => ({
    timer: 0,
    interval: 0,
    texto: [],
    quantPedidosTotal: 0,
    counter: 0,
    countEncomendas: 0,
    countPedidos: 0,
    muted: false,
    isDragging: false,
    isDraggable: false,
    initialMouseX: 0,
    initialMouseY: 0,
    initialComponentX: 0,
    initialComponentY: 0,
    initialComponent2X: 0,
    initialComponent2Y: 0,
  }),
  watch: {
    pedidos() {
      this.getPedidos();
      if (this.quantPedidos == 0) {
        this.quantPedidosTotal = this.pedidos.length;
      }
    },
  },

  computed: {
    ...mapGetters(["pedidos"]),
  },
  methods: {
    
    startDragging(event) {
      if(this.isDraggable){
        this.isDragging = true;
        this.initialMouseX = event.clientX;
        this.initialMouseY = event.clientY;
        const rect = this.$refs.draggableComponent.getBoundingClientRect();
        let rect2 = document.querySelector("#draggableComponent2")

        if(rect2){
          rect2 = rect2.getBoundingClientRect();
          this.initialComponent2X = rect2.left;
          this.initialComponent2Y = rect2.top;
        }
        this.initialComponentX = rect.left;
        this.initialComponentY = rect.top;

        
        document.addEventListener("mousemove", this.dragComponent);
        document.addEventListener("mouseup", this.stopDragging);
        
      }
    },
    dragComponent(event) {
      if (this.isDragging) {
        const dx = event.clientX - this.initialMouseX;
        const dy = event.clientY - this.initialMouseY;
        this.$refs.draggableComponent.style.left =
          this.initialComponentX + dx + "px";
        this.$refs.draggableComponent.style.top =
          this.initialComponentY + dy + "px";
        let draggableComponent2 = document.querySelector("#draggableComponent2")
        if(draggableComponent2){
          draggableComponent2.style.left =
            this.initialComponent2X + dx + "px";
          draggableComponent2.style.top =
            this.initialComponent2Y + dy + "px";
        }
      }
    },

    stopDragging() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.dragComponent);
      document.removeEventListener("mouseup", this.stopDragging);
    },
    getPedidos() {
      
      var i = 0;
      this.counter = 0;
      this.texto = [];
      var countPedidos = 0;
      var countEncomendas = 0;

      if (this.pedidos !== undefined) {
        if (this.quantPedidosTotal != this.pedidos.length) {
          this.playNotification();
        }
        for (i in this.pedidos) {
          if (this.pedidos[i] !== "" && this.pedidos[i].dataEntrega) {
            if (this.pedidos[i].status == null) {
              countEncomendas++;
            }
          } else if (
            !this.pedidos[i].dataEntrega
          ) {
            if (this.pedidos[i].status == null) {
              countPedidos++;
            }
          }
        }
      }
      if (countEncomendas == 1) {
        var text = "Possui " + countEncomendas + " Encomenda em recebimento.";
        this.texto.push(text);
      } else if (countEncomendas != 0) {
        text = "Possui " + countEncomendas + " Encomendas em recebimento.";
        this.texto.push(text);
      }
      if (countPedidos == 1) {
        text = "Possui " + countPedidos + " pedido em recebimento.";
        this.texto.push(text);
      } else if (countPedidos != 0) {
        text = "Possui " + countPedidos + " pedidos em recebimento.";
        this.texto.push(text);
      }
      this.counter = countEncomendas + countPedidos;
      this.quantPedidosTotal = this.pedidos.length;
      this.countPedidos = countPedidos;
      this.countEncomendas = countEncomendas;
    },
    playNotification() {
      var someSound = require("@/assets/notify.mp3");
      var note = new Audio(someSound);
      note.muted = this.muted;
      note.play();
    },
    progressBar() {
      this.interval = setInterval(() => {
        if (this.timer < 100) {
          this.timer += 1.5;
        } else {
          clearInterval(this.interval);
          this.isDraggable = true
        }
      }, 100);
    },
  },
  mounted() {
    this.getPedidos();
    this.progressBar();
  },
};
</script>
<style lang="scss" scoped></style>
<style scoped>
#floating {
  z-index: 90;
  position: fixed;
  bottom: 20px;
  right: 35px;
  cursor: pointer; /* Add a grab cursor to indicate draggability */
  pointer-events: none; /* Disable pointer events on the draggable component */

}


</style>
