import { auth } from "@/main";
import axios from "axios";
import { store } from "../../store/store";
const pako = require('pako');

export default function getDataPerDateCancelados(database, data) {
  store.commit("incrementCarregando", true);

  return new Promise((resolve, reject) => {
    if (auth.currentUser != null) {
      auth.currentUser
        ?.getIdToken(true)
        .then((log) => {
          axios({
            method: "post",
            data: {
              empresa: database,
              data: data,
            },
            url: `${process.env.VUE_APP_APIURL}/pedidos/getdataperdatecanceled`,
            headers: {
              authtoken: log,
            },
          })
            .then((res) => {
              store.commit("incrementCarregando", false);
              let data = res.data.result;
              let inflatedData = JSON.parse(pako.inflate(new Uint8Array(data.data), { to: 'string' }));
              res.data.result = inflatedData
              resolve(res);
            })
            .catch((error) => {
              store.commit("incrementCarregando", false);
              reject(error);
            });
        })
        .catch((error) => {
          store.commit("incrementCarregando", false);
          reject(error);
        });
    } else {
      store.commit("incrementCarregando", false);
      resolve("Unauthorized");
    }
  });
}
