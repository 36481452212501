import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../store/store";

export default function getDadosEmpresa(database) {
  onSnapshot(doc(db, database, "dadosempresa"), (doc) => {
    var data = Object.values(doc.data())[0];
    store.commit("incrementDadosEmpresa", data);
  });
}
