import { store } from "../store/store";
// import {mapGetters} from 'vuex'

export default function setDialog(edit, pedido) {
  var dialog = store.state.dialog;
  dialog = !dialog;

  store.commit("incrementDialog", dialog);
  store.commit("incrementPedido", null);

  if (edit && pedido) {
    store.commit("incrementPedido", pedido);
  }
}
