<template>

    <v-container fluid class="grey lighten-5" style="min-height: 100vmin">
    <v-row>
        <v-col cols="12" md="12">
            <v-tabs v-if="this.$isMobile()">
            <v-tab
                v-for="i in controle"
                :to="i.route"
                :key="i"
                color="#E74242"
                class="elevation-0"
                @click="this.controleSelecionado = i.name"
                >{{ i.name }}</v-tab
            >
            </v-tabs>
            <v-tabs v-else>
            <v-tab
                v-for="i in controle"
                :to="i.route"
                :key="i"
                color="#E74242"
                class="elevation-0"
                @click="this.controleSelecionado = i.name"
                >{{ i.name }}
            </v-tab>
            </v-tabs>
        </v-col>
    </v-row>
      <v-row v-if="controleSelecionado != 'Todos'" class="grey">
        <RouterView></RouterView>
      </v-row>
    </v-container>
  </template>
  
  <script>

import getDataPerInterval from "../../libs/Pedidos/getDataPerInterval";
  
  import setDialog from "../../libs/setDialog";
  import { mapGetters } from "vuex";
import { store } from "../../store/store";

  
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Encomendas",
    components: {
      
    },
    data: () => ({
      attributes: [],
      pedidosPush: [],
      controle: [
        { name: "Histórico de Pedidos", route: "/financeiro/historico" },
        { name: "Produtos mais vendidos", route: "/financeiro/produtos" },
        { name: "Totalizadores", route: "/financeiro/totalizadores" },
        { name: "Quantitativo", route: "/financeiro/quantitativo" },
      ],
      controleSelecionado: "Histórico de Pedidos",
      snackbar: false,
      text: "",
      firstTime: true
    }),
  
    watch: {
      buttonSearch() {
      if (this.firstTime == false) {
        store.commit("incrementSelectedDate", '')
        getDataPerInterval(this.database.cnpj, this.dataInicioFinanceiro, this.dataFimFinanceiro).then((val) => {
          this.pedidos = val.data.result;
        });
      }
    },
      '$route': {
        handler(newRoute) {
          console.log(newRoute.path);
          // Check the path of the new route and update controleSelecionado accordingly
          if(newRoute.path === '/financeiro') {
            this.controleSelecionado = 'Histórico de Pedidos';
            this.$router.push("/financeiro/historico")
          } else if (newRoute.path === '/financeiro/historico') {
            this.controleSelecionado = 'Histórico de Pedidos';
          } else if (newRoute.path === '/financeiro/produtos') {
            this.controleSelecionado = 'Produtos mais vendidos';
          } else if (newRoute.path === '/financeiro/totalizadores') {
            this.controleSelecionado = 'Totalizadores';
          } else if (newRoute.path === '/financeiro/quantitativo') {
            this.controleSelecionado = 'Quantitativo';
          } else {
            // Handle other routes or set a default value if needed
            this.controleSelecionado = '';
          }
        },
        immediate: true // This will trigger the handler immediately when the component is mounted
      }
    },
    computed: {
      ...mapGetters(["buttonSearch", "clientes", "pedidos", "dadosEmpresa", "dialog", "database", "dataInicioFinanceiro", "dataFimFinanceiro"]),
    },
    
    methods: {
      setDialog() {
        setDialog();
      },
    },
    mounted() {
      if (this.$route.path != "/encomendas") {
        this.controleSelecionado = "";
      }
      setTimeout(() => {
        getDataPerInterval(this.database.cnpj, this.dataInicioFinanceiro, this.dataFimFinanceiro).then((val) => {
          this.firstTime = false
          this.pedidos = val.data.result;
        }).catch(() => {
          this.firstTime = false
        });
      }, 500);
    },
    created() {
      //se o usuario for novo redireciona para dados cad
      if (localStorage.getItem("novoUser") == "true") {
        this.$router.push({
          name: "DadosCad",
        });
      }
    },
  };
  </script>
  <style scoped>
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  
  .grey {
    animation: transitionIn 0.75s;
  }
  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-10deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0);
    }
  }
  .icons {
    font-size: 175%;
  }
  
  :deep .column-from-end-2 .weekday-7 {
    background-color: #fff3e5f5 !important;
  }
  :deep .column-from-end-2 .weekday-1 {
    background-color: #fff3e5f5 !important;
  }
  
  :deep .column-from-end-2 .is-today {
    background-color: #5f5757f5 !important;
  }
  
  :deep .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;
  }
  :deep .custom-calendar.vc-container .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  :deep .custom-calendar.vc-container .vc-weeks {
    padding: 0;
  }
  :deep .custom-calendar.vc-container .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  :deep .custom-calendar.vc-container .vc-day {
    /* for Firefox */
  
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
  }
  :deep .tarefa {
    padding: 0 5px 3px 5px;
    margin-bottom: 1px;
    height: 60px;
    min-width: 10%;
    overflow-y: scroll;
  }
  :deep .tarefa::-webkit-scrollbar {
    display: none;
  }
  
  :deep .custom-calendar.vc-container .vc-day.weekday-1,
  :deep .custom-calendar.vc-container .vc-day.weekday-7 {
    background-color: #eff8ff;
  }
  :deep .custom-calendar.vc-container .vc-day:not(.on-bottom) {
    border-bottom: var(--day-border);
  }
  :deep .custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
    border-bottom: var(--day-border-highlight);
  }
  :deep .custom-calendar.vc-container .vc-day:not(.on-right) {
    border-right: var(--day-border);
  }
  :deep .custom-calendar.vc-container .vc-day-dots {
    margin-bottom: 5px;
  }
  </style>
  