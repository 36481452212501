<template>
  <v-container fluid class="grey lighten-5" style="min-height: 100vmin">
    <v-row class="grey">
      <v-col cols="12">
        <div class="text-left">
          <h2 class="h2">Calendário de encomendas</h2>
          <v-row class="d-flex justify-end">
            <v-tabs v-model="controleSelecionado">
              <v-tab
                v-for="i in ['Mês', 'Semana', 'Dia']"
                @click="setDate()"
                :key="i"
                :value="i"
                color="#E74242"
                class="elevation-0"
                >{{ i }}
              </v-tab>
            </v-tabs>
          </v-row>
          <p class="text-lg font-medium text-gray-600 mb-6">
            <!-- Roll your own calendars using scoped slots -->
          </p>
          <v-calendar
            v-if="controleSelecionado == 'Mês'"
            :columns="1"
            class="custom-calendar"
            :attributes="attributes"
          >
            <template v-slot:day-content="{ day, attributes }">
              <div>
                <span class="day-label text-sm text-gray-900">{{
                  day.day
                }}</span>
                <div class="tarefa">
                  <div
                    style="cursor: pointer"
                    v-for="attr in attributes"
                    :key="attr.key"
                    :class="attr.customData.class"
                    @click="setDialog(attr.customData.dadosPedido)"
                  >
                    <div class="text-left">
                      <span style="font-size: 12px" class="text-left">{{
                        attr.customData.title
                      }}</span>
                    </div>
                    {{
                      this.pedidos.find(
                            (val) => val.num == attr.customData.title
                          ).dadosCliente
                        ? this.pedidos.find(
                            (val) => val.num == attr.customData.title
                          ).dadosCliente.nome
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </template>
          </v-calendar>
        </div>
      </v-col>
      <v-col v-if="controleSelecionado == 'Semana'">
        <div
          class="text-center"
          style="
            background-color: #f1f5f8;
            border: 1px solid #b8c2cc;
            border-radius: 4px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
          "
        >
          <h3 class="font-weight-regular">
            {{ this.primeiroDiaSemana.toLocaleDateString() }} --
            {{ this.setimoDiaSemana.toLocaleDateString() }}
          </h3>
          <v-btn
            icon
            elevation="0"
            color="#F1F5F8"
            @click="
              (this.primeiroDiaSemana = new Date(
                diaMesSelecionado.setDate(diaMesSelecionado.getDate() - 7)
              )),
                this.setDiasSemana()
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            icon
            elevation="0"
            color="#F1F5F8"
            @click="
              (this.primeiroDiaSemana = new Date(
                diaMesSelecionado.setDate(diaMesSelecionado.getDate() + 7)
              )),
                this.setDiasSemana()
            "
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
        <v-table hover 
          style="
            border: 1px solid #b8c2cc;
            border-radius: 4px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          "
        >
          <thead>
            <tr>
              <th class="text-center">
                Domingo
                {{ this.primeiroDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Segunda
                {{ this.segundoDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Terça
                {{ this.terceiroDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Quarta
                {{ this.quartoDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Quinta
                {{ this.quintoDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Sexta
                {{ this.sextoDiaSemana.toLocaleDateString() }}
              </th>
              <th class="text-center">
                Sábado
                {{ this.setimoDiaSemana.toLocaleDateString() }}
              </th>
            </tr>
          </thead>
          <tbody>
            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.primeiroDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            item.dadosCliente.nome
                              
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.segundoDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            item.dadosCliente.nome
                            
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.terceiroDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            item.dadosCliente.nome
                              
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.quartoDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            item.dadosCliente.nome
                             
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.quintoDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            
                             item.dadosCliente.nome
                             
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.sextoDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                           
                             item.dadosCliente.nome
                             
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>

            <td>
              <template v-for="item in this.pedidosPush" :key="item.num">
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.setimoDiaSemana.toISOString().split('T')[0]
                  "
                >
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12">
                      <v-btn
                        class="text-white font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                              item.dadosCliente.nome
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </template>
            </td>
          </tbody>
        </v-table>
      </v-col>
      <v-col v-if="controleSelecionado == 'Dia'">
        <div
          class="text-center"
          style="
            background-color: #f1f5f8;
            border: 1px solid #b8c2cc;
            border-radius: 4px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
          "
        >
          <h3 class="font-weight-regular">
            {{
              [
                "Domingo",
                "Segunda-Feira",
                "Terça-Feira",
                "Quarta-Feira",
                "Quinta-Feira",
                "Sexta-Feira",
                "Sábado",
              ][this.diaMesSelecionado.getDay()]
            }}
          </h3>
          <h3 class="font-weight-regular">
            {{ new Date(diaMesSelecionado).toLocaleDateString() }}
          </h3>
          <v-btn
            icon
            elevation="0"
            color="#F1F5F8"
            @click="
              this.diaMesSelecionado = new Date(
                diaMesSelecionado.setDate(diaMesSelecionado.getDate() - 1)
              )
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            icon
            elevation="0"
            color="#F1F5F8"
            @click="
              this.diaMesSelecionado = new Date(
                diaMesSelecionado.setDate(diaMesSelecionado.getDate() + 1)
              )
            "
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <div class="d-flex justify-start">
            <input
              type="date"
              v-model="data"
              class="mb-5 ml-5"
              style="border: 0.5px solid grey; border-radius: 5px; width: 150px"
            />
          </div>
        </div>
        <v-table hover 
          style="
            border: 1px solid #b8c2cc;
            border-radius: 4px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          "
        >
          <thead>
            <tr>
              <th width="10">Horario</th>
              <th class="text-center">Encomenda</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in this.pedidosPush" :key="item.num">
              <tr>
                <template
                  v-if="
                    item.status != null &&
                    item.status != `cancelado` &&
                    item.status != `concluido` &&
                    item.status != `pdv` &&
                    item.status != `naoenviado` &&
                    item.status != `geroucupom` &&
                    item.dataEntrega ==
                      this.diaMesSelecionado.toISOString().split('T')[0]
                  "
                >
                  <td class="font-weight-black">{{ item.horaEntrega }}</td>
                  <td class="d-flex justify-center" @click="setDialog(item)">
                    <v-col cols="12" md="6">
                      <v-btn
                        class="text-white 1 font-weight-bold"
                        width="100%"
                        elevation="0"
                        :color="
                          item.status == null
                            ? '#9C27B0'
                            : item.status == 'Aceito'
                            ? '#3F51B5'
                            : item.status == 'recebido'
                            ? '#FFC107'
                            : item.status.match('tele')
                            ? '#4CAF50'
                            : null
                        "
                      >
                        <p
                          style="
                            font-size: 1vh;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                          "
                        >
                          {{ item.num }}
                        </p>
                        <p>
                          {{
                            item.dadosCliente.nome
                          }}
                        </p>
                      </v-btn></v-col
                    >
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-col>
      <v-dialog v-model="dialog.ativo">
        <v-row>
          <v-col cols="12" md="4" class="mx-auto" style="">
            <v-card style="max-height: 80vh !important; overflow: scroll">
              <v-row>
                <v-col>
                  <v-card-title>
                    <h2>Pedido Nº {{ dialog.pedido.num }}</h2>
                  </v-card-title>
                  <v-card-text class="d-flex">
                    <p class="font-weight-bold">Status:</p>
                    <p
                      class="px-2 font-weight-bold text-white rounded-xl"
                      :style="dialog.classStatus"
                    >
                      {{
                        dialog.pedido.status == "Aceito"
                          ? "Aceito"
                          : dialog.pedido.status == "recebido"
                          ? "Em Producao"
                          : dialog.pedido.status.match("tele")
                          ? "Pronto"
                          : "null"
                      }}
                    </p>
                  </v-card-text>
                  <v-card-subtitle>
                    Nome:
                    {{
                       dialog.pedido.dadosCliente.nome
                    }}
                  </v-card-subtitle>
                  <v-card-subtitle>
                    Cpf:
                    {{
                      dialog.pedido.cliente
                        ? dialog.pedido.cliente
                        : "Nao informado"
                    }}
                  </v-card-subtitle>
                  <v-card-text>
                    <p>Número: {{ dialog.pedido.clienteNum }}</p>
                    Data Entrega:
                    {{
                      dialog.pedido.dataEntrega.split("-").reverse().join("/")
                    }}
                    <p>
                      Hora Entrega:
                      {{
                        dialog.pedido.horaEntrega.split("-").reverse().join("/")
                      }}
                    </p>
                    <p>
                      Endereco:
                      {{
                        
                          this.dialog.pedido.dadosCliente.endereco
                          ? this.dialog.pedido.dadosCliente.endereco
                          : "Não Necessita"
                      }}{{ this.dialog.pedido.dadosCliente.numero ? ', ' +  this.dialog.pedido.dadosCliente.numero: '' }}{{ this.dialog.pedido.dadosCliente.complemento ?  ', ' + this.dialog.pedido.dadosCliente.complemento : ''}}
                    </p>
                  </v-card-text>
                  <v-card-text>
                    <h2>Produtos:</h2>
                  </v-card-text>
                  <v-card-text
                    v-for="(produto, index) in dialog.pedido.produtos"
                    :key="produto"
                  >
                    <div class="d-flex mt-4">
                      <div class="justify-content-left" style="width: 100%">
                        <p>{{ index + 1 }} -- {{ produto.nome }}</p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="justify-content-left ml-2" style="width: 80%">
                        <p>{{ produto.quantidade }} x {{ produto.valor }}</p>
                      </div>
                      <div
                        class="justify-content-right text-left"
                        style="width: 20%"
                      >
                        <p>
                          {{
                            (parseFloat(produto.valor) * produto.quantidade)
                              .toFixed(2)
                              .replace(".", ",")
                          }}
                        </p>
                      </div>
                    </div>

                    <div v-if="produto.saborComp">
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 100%">
                          <strong>Sabores: </strong>
                        </div>
                      </div>
                      <div class="d-flex ml-2">
                        <div class="justify-content-left" style="width: 80%">
                          <p>{{ produto.saboresDesc }}</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <p>
                            {{
                              parseFloat(produto.valorSaboresDesc)
                                .toFixed(2)
                                .replace(".", ",")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="produto.adicionaisDesc">
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 100%">
                          <p>Adicionais:</p>
                        </div>
                      </div>
                      <div class="d-flex ml-2">
                        <div class="justify-content-left" style="width: 80%">
                          <p>{{ produto.adicionaisDesc }}</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <p>
                            {{
                              parseFloat(produto.precoAdicionais)
                                .toFixed(2)
                                .replace(".", ",")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        parseInt(index) == dialog.pedido.produtos.length - 1
                      "
                      class="mt-4"
                    >
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 80%">
                          <p>Valor total</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <strong>{{
                            parseFloat(dialog.pedido.preco)
                              .toFixed(2)
                              .replace(".", ",")
                          }}</strong>
                        </div>
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <p v-if="dialog.pedido.observacao">
                      Observação:
                      <strong>{{ dialog.pedido.observacao }}</strong>
                    </p>
                    <p>
                      Forma de Pagamento:
                      <strong>{{ dialog.pedido.metodoPgto }}</strong>
                    </p>
                    <p v-if="dialog.pedido.valorTroco">
                      Valor Troco:
                      <strong>{{
                        parseFloat(dialog.pedido.valorTroco)
                          .toFixed(2)
                          .replace(".", ",")
                      }}</strong>
                    </p>
                    <p>
                      Modo de entrega:
                      <strong>{{
                        dialog.pedido.valorTele ? "Tele" : "Retirada"
                      }}</strong>
                    </p>
                  </v-card-text>
                  <v-row class="d-flex justify-space-around">
                    <v-col cols="4" class="text-center">
                      <span style="color: grey">Fechar</span>
                    </v-col>
                    <v-col cols="4" class="text-center">
                      <span style="color: grey">{{
                        dialog.pedido.status ==
                        dialog.pedido.status.match("tele")
                          ? "Finalizar Pedido"
                          : "Avancar Status"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-space-around pb-2">
                    <v-col cols="4" class="text-center">
                      <v-btn
                        @click="dialog.ativo = false"
                        size="small"
                        icon="mdi-close"
                        label="Fechar"
                        color="red"
                      ></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center">
                      <v-btn
                        v-if="dialog.pedido.status == 'Aceito'"
                        @click="
                          (dialog.pedido.status = 'recebido'),
                            alteraDados(this.dialog.pedido)
                        "
                        size="small"
                        icon="mdi-clipboard-arrow-right-outline"
                        label="Fechar"
                        color="green"
                      />
                      <v-btn
                        v-if="dialog.pedido.status == 'recebido'"
                        @click="
                          (dialog.pedido.status = 'tele'),
                            enviaPDV(this.dialog.pedido),
                            alteraDados(this.dialog.pedido),
                            enviaWhats(
                              this.dialog.pedido.clienteNum,
                              this.dialog.pedido.num,
                              this.dialog.pedido.preco,
                              this.dialog.pedido.metodoPgto,
                              this.dialog.pedido.valorTele
                            )
                        "
                        size="small"
                        icon="mdi-clipboard-arrow-right-outline"
                        label="Fechar"
                        color="green"
                      />
                      <v-btn
                        v-if="dialog.pedido.status.match('tele')"
                        @click="
                          (dialog.pedido.status = 'tele'),
                            finalizaPedido(dialog.pedido),
                            (dialog.ativo = false)
                        "
                        size="small"
                        icon="mdi-clipboard-arrow-right-outline"
                        label="Fechar"
                        color="green"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-btn
                icon
                @click="
                  imprimePedido(
                    this.dialog.pedido,
                    this.dadosEmpresa,
                    this.clientes
                  )
                "
                elevation="0"
                style="
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="setDialogEdit(this.dialog.pedido)"
                elevation="0"
                style="
                  position: absolute;
                  right: 95px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon color="green">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="abreWhats(this.dialog.pedido.clienteNum)"
                elevation="0"
                style="
                  position: absolute;
                  right: 50px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon color="green">mdi-whatsapp</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-dialog>
    </v-row>
  </v-container>

  <!-- <div style="height: 100v50vhh;" class="overflow-y-auto d-flex justify-center" >
    <v-img style="height: 350px" src="https://thumbs.gfycat.com/ColorlessBitesizedKob-size_restricted.gif"></v-img>
  </div>
  <div style="height: 50vh;" class="overflow-y-auto d-flex justify-center" >
    <p>Em desenvolvimento...</p>
  </div> -->
</template>

<script>
import setDialog from "../../../libs/setDialog";
import { mapGetters } from "vuex";
import imprimeEncomenda from "../../../libs/imprimeEncomenda";
import updatePedido from "../../../libs/updatePedido";
import { useDisplay } from "vuetify";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Calendario",
  components: {},
  setup() {
    // Destructure only the keys we want to use
    const { xs, lgAndUp } = useDisplay();

    return { xs, lgAndUp };
  },
  data: () => ({
    diaMesSelecionado: new Date(),
    primeiroDiaSemana: new Date(),
    segundoDiaSemana: new Date(),
    terceiroDiaSemana: new Date(),
    quartoDiaSemana: new Date(),
    quintoDiaSemana: new Date(),
    sextoDiaSemana: new Date(),
    setimoDiaSemana: new Date(),
    attributes: [],
    pedidosPush: [],
    dialog: {
      ativo: false,
      pedido: {},
    },
    data: "",
    snackbar: false,
    text: "",
    controleSelecionado: "Semana",
    status: [],
  }),
  watch: {
    controleSelecionado(n) {
      if (n == "Mês") {
        setTimeout(() => {
          // Specify the class name of the elements to remove
          var className = "vc-week";

          // Find all elements with the specified class name
          var elements = document.getElementsByClassName(className);
          console.log(elements);
          // Check if there are at least six elements with the class
          if (elements.length == 6) {
            console.log(true);
            // Get the sixth element with the class and remove it
            var sixthElement = elements[5];
            sixthElement.parentNode.removeChild(sixthElement);
          }
        }, 200);
      }
    },
    pedidos: {
      handler() {
        this.getPedidos();
      },
      deep: true,
    },
    pedidoEdit(n) {
      if (n != null || n != "") {
        this.dialog.ativo = false;
      }
    },
    "dialog.pedido.status"() {
      this.dialog.classStatus = {
        "background-color": `${
          this.dialog.pedido.status == null
            ? "#9C27B0"
            : this.dialog.pedido.status == "Aceito"
            ? "#3F51B5"
            : this.dialog.pedido.status == "recebido"
            ? "#FFC107"
            : this.dialog.pedido.status.match("tele")
            ? "#4CAF50"
            : null
        }`,
      };
    },
    data(n) {
      n = new Date(n.split("-"));
      this.diaMesSelecionado = n;
    },
  },
  computed: {
    ...mapGetters([
      "clientes",
      "database",
      "pedidos",
      "dadosEmpresa",
      "pedidoEdit",
      "dialogFi",
    ]),
  },
  methods: {
    setDialogEdit(i) {
      setDialog("edit", i);
    },
    setDate() {
      this.diaMesSelecionado = new Date();
    },
    abreWhats(telefone) {
      window.open(
        "https://api.whatsapp.com/send?phone=" + telefone + "&text=" + "Olá"
      );
    },
    setDiasSemana() {
      (this.primeiroDiaSemana = new Date(
        this.diaMesSelecionado.setDate(
          this.diaMesSelecionado.getDate() - this.diaMesSelecionado.getDay()
        )
      )),
        (this.segundoDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              1
          )
        )),
        (this.terceiroDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              2
          )
        )),
        (this.quartoDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              3
          )
        )),
        (this.quintoDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              4
          )
        )),
        (this.sextoDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              5
          )
        )),
        (this.setimoDiaSemana = new Date(
          this.diaMesSelecionado.setDate(
            this.diaMesSelecionado.getDate() -
              this.diaMesSelecionado.getDay() +
              6
          )
        ));
    },
    imprimePedido(i, dadosEmpresa, clientes) {
      imprimeEncomenda(i, dadosEmpresa, clientes);
    },
    coloca0Antes(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    setDialog(pedido) {
      this.dialog.pedido = pedido;
      this.dialog.classStatus = {
        "background-color": `${
          this.dialog.pedido.status == null
            ? "#9C27B0"
            : this.dialog.pedido.status == "Aceito"
            ? "#3F51B5"
            : this.dialog.pedido.status == "recebido"
            ? "#FFC107"
            : this.dialog.pedido.status.match("tele")
            ? "#4CAF50"
            : null
        }`,
      };
      for (var i = 0; i < pedido.produtos.length; i++) {
        if (pedido.produtos[i].adicionaisDesc) {
          pedido.produtos[i].precoAdicionais = null;
          this.dialog.pedido = pedido;

          for (
            var c = 0;
            c < pedido.produtos[i].adicionaisSelecionados.length;
            c++
          ) {
            this.dialog.pedido.produtos[i].precoAdicionais += parseFloat(
              pedido.produtos[i].adicionaisSelecionados[c].valor.replace(
                ",",
                "."
              ) * pedido.produtos[i].quantidade
            );
          }
        }
      }
      this.dialog.ativo = true;
    },
    enviaPDV(i) {
      if (window.confirm("Deseja enviar pedido para o pdv?")) {
        i.status = "tele pdv";
        this.alteraDados(i);
        setTimeout(() => {
          for (var o = 0; o < this.pedidosPush.length; o++) {
            if (
              i.num == this.pedidosPush[o].num &&
              this.pedidosPush[o].status == "tele"
            ) {
              alert("Falha ao enviar pedido ao pdv");
            } else if (
              i.num == this.pedidosPush[o].num &&
              this.pedidosPush[o].status == "tele pdv geroucupom"
            ) {
              alert("Falha ao enviar pedido ao pdv, cupom fiscal foi gerado");
            }
          }
        }, 5000);
      } else {
        i.status = "tele";
        this.alteraDados(i);
      }
    },
    finalizaPedido(i) {
      const options = {
        timeZone: "America/Sao_Paulo",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      let currentDate = new Date().toLocaleString("en-GB", options);
      currentDate = currentDate.substring(0, 10);
      const parts = currentDate.split("/");
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      i.data = formattedDate;
      if (i.status == "tele") {
        i.status = "naoenviado";
      } else if (i.status == "tele pdv enviado") {
        i.status = "pdv";
      } else {
        i.status = "concluido";
      }
      this.alteraDados(i);
    },
    async alteraDados(data) {
      data.num = data.num.toString();
      const result = await updatePedido(this.database.cnpj, data);
      console.log(result);
      this.setCalendario(this.pedidosPush);
    },
    getPedidos() {
      var i = 0;
      this.pedidosPush = [];
      if (this.pedidos !== undefined) {
        for (i in this.pedidos) {
          if (this.pedidos[i] !== "" && this.pedidos[i].dataEntrega) {
            this.pedidosPush.push(this.pedidos[i]);
          }
        }
        this.pedidosPush.sort((a, b) => a.num - b.num);
        this.setCalendario(this.pedidosPush);
      }
    },
    /* eslint-disable */
    setCalendario(pedidosPush) {
      this.attributes = [];
      for (var i = 0; i < pedidosPush.length; i++) {
        var dataEntrega = new Date(
          pedidosPush[i].dataEntrega.replace(
            /(\d{2})-(\d{2})-(\d{4})/,
            "$2/$1/$3"
          )
        );
        let cor = null;

        if (
          this.pedidosPush[i].status != null &&
          this.pedidosPush[i].status != `cancelado` &&
          this.pedidosPush[i].status != `concluido` &&
          this.pedidosPush[i].status != `pdv` &&
          this.pedidosPush[i].status != `naoenviado` &&
          this.pedidosPush[i].status != `geroucupom`
        ) {
          this.attributes.push({
            key: i,
            bar: {
              style: {
                "background-color": `${(cor =
                  this.pedidosPush[i].status == null
                    ? "#9C27B0"
                    : this.pedidosPush[i].status == "Aceito"
                    ? "#3F51B5"
                    : this.pedidosPush[i].status == "recebido"
                    ? "#FFC107"
                    : this.pedidosPush[i].status.match("tele")
                    ? "#4CAF50"
                    : null)}`,
              },
            },
            customData: {
              title: `${this.pedidosPush[i].num}`,

              class: `bg-${(cor =
                this.pedidosPush[i].status == "Aceito"
                  ? "indigo"
                  : this.pedidosPush[i].status == "recebido"
                  ? "amber"
                  : this.pedidosPush[i].status.match("tele")
                  ? "green"
                  : null)} text-white mt-1 pl-1 pr-1 rounded-lg text-center`,
              dadosPedido: this.pedidosPush[i],
            },
            dates: new Date(
              dataEntrega.getFullYear(),
              dataEntrega.getMonth(),
              dataEntrega.getDate() + 1
            ),
          });
        }
        this.status[i];
      }
    },
    enviaWhats(numero, numerodopedido, valor, metodoPgto, valorTele) {
      var prodWpp = null;
      if (valorTele) {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e logo sera entregue. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      } else {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e aguardand retirada. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      }
    },
  },
  mounted() {
    this.getPedidos();
    this.setDiasSemana();
  },
  created() {
    //se o usuario for novo redireciona para dados cad
    if (localStorage.getItem("novoUser") == "true") {
      this.$router.push({
        name: "DadosCad",
      });
    }
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.grey {
  animation: transitionIn 0.75s;
}
@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
.icons {
  font-size: 175%;
}

:deep .column-from-end-2 .weekday-7 {
  background-color: #fff3e5f5 !important;
}
:deep .column-from-end-2 .weekday-1 {
  background-color: #fff3e5f5 !important;
}

:deep .column-from-end-2 .is-today {
  background-color: #5f5757f5 !important;
}

:deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}
:deep .custom-calendar.vc-container .vc-header {
  background-color: transparent !important;
  padding: 0px !important;
}
:deep .custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
:deep .custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
:deep .custom-calendar.vc-container .vc-day {
  /* for Firefox */

  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
:deep .tarefa {
  padding: 0 5px 3px 5px;
  margin-bottom: 1px;
  height: 130px !important;
  min-width: 20%;
  overflow-y: scroll;
}
:deep .tarefa::-webkit-scrollbar {
  display: none;
}

:deep .custom-calendar.vc-container .vc-day.weekday-1,
:deep .custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
:deep .custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
:deep .custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
:deep .custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
:deep .custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}
</style>
