<template>
    <v-dialog v-model="dialogPedido.ativo">
        <v-row>
          <v-col cols="12" md="4" class="mx-auto" style="">
            <v-card style="max-height: 80vh !important; overflow: scroll">
              <v-row>
                <v-col>
                  <v-card-title>
                    <h2>Pedido Nº {{ dialogPedido.pedido.num }}</h2>
                  </v-card-title>
                  <v-card-text class="d-flex">
                    <p class="font-weight-bold">Status:</p>
                    <p
                      class="px-2 font-weight-bold rounded-xl"
                      :style="dialogPedido.classStatus"
                    >
                      {{
                        dialogPedido.pedido.status == "Aceito"
                          ? "Aceito"
                          : dialogPedido.pedido.status == "recebido"
                          ? "Em Producao"
                          : dialogPedido.pedido.status != null && dialogPedido.pedido.status.match("tele")
                          ? "Pronto"
                          : "Recebido"
                      }}
                    </p>
                  </v-card-text>
                  <v-card-subtitle>
                    Nome:
                    {{
                       dialogPedido.pedido.dadosCliente.nome
                    }}
                  </v-card-subtitle>
                  <v-card-subtitle v-if="dialogPedido.pedido.cliente">
                    Cpf:
                    {{
                      dialogPedido.pedido.cliente
                        ? dialogPedido.pedido.cliente
                        : "Nao informado"
                    }}
                  </v-card-subtitle>
                  <v-card-subtitle v-if="!dialogPedido.pedido.cliente && dialogPedido.pedido.dadosCliente">
                    Cpf:
                    {{
                      dialogPedido.pedido.dadosCliente
                        ? dialogPedido.pedido.dadosCliente.cpf
                        : "Nao informado"
                    }}
                  </v-card-subtitle>
                  <v-card-text>
                    <p>Número: {{ dialogPedido.pedido.dadosCliente.telefone }}</p>
                    <template v-if="dialogPedido.pedido.dataEntrega">
                        Data Entrega:
                        {{
                        dialogPedido.pedido.dataEntrega.split("-").reverse().join("/")
                        }}
                        <p>
                        Hora Entrega:
                        {{
                            dialogPedido.pedido.horaEntrega.split("-").reverse().join("/")
                        }}
                        </p>
                        
                    </template>
                    <p>
                      Endereco:
                      {{
                          
                          this.dialogPedido.pedido.dadosCliente.endereco
                          ? this.dialogPedido.pedido.dadosCliente.endereco
                          : "Não Necessita"
                      }}{{ this.dialogPedido.pedido.dadosCliente.numero ? ', ' +  this.dialogPedido.pedido.dadosCliente.numero: '' }}{{ this.dialogPedido.pedido.dadosCliente.complemento ?  ', ' + this.dialogPedido.pedido.dadosCliente.complemento : ''}}
                      </p>
                  </v-card-text>
                  <v-card-text>
                    <h2>Produtos:</h2>
                  </v-card-text>
                  <v-card-text
                    v-for="(produto, index) in dialogPedido.pedido.produtos"
                    :key="produto"
                  >
                    <div class="d-flex mt-4">
                      <div class="justify-content-left" style="width: 100%">
                        <p>{{ index + 1 }} -- {{ produto.nome }}</p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="justify-content-left ml-2" style="width: 80%">
                        <p>{{ produto.quantidade }} x {{ produto.valor }}</p>
                      </div>
                      <div
                        class="justify-content-right text-left"
                        style="width: 20%"
                      >
                        <p>
                          {{
                            (parseFloat(produto.valor) * produto.quantidade)
                              .toFixed(2)
                              .replace(".", ",")
                          }}
                        </p>
                      </div>
                    </div>

                    <div v-if="produto.saborComp">
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 100%">
                          <strong>Sabores: </strong>
                        </div>
                      </div>
                      <div class="d-flex ml-2">
                        <div class="justify-content-left" style="width: 80%">
                          <p>{{ produto.saboresDesc }}</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <p>
                            {{
                              parseFloat(produto.valorSaboresDesc)
                                .toFixed(2)
                                .replace(".", ",")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="produto.adicionaisDesc">
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 100%">
                          <p>Adicionais:</p>
                        </div>
                      </div>
                      <div class="d-flex ml-2">
                        <div class="justify-content-left" style="width: 80%">
                          <p>{{ produto.adicionaisDesc }}</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <p>
                            {{
                              parseFloat(produto.precoAdicionais)
                                .toFixed(2)
                                .replace(".", ",")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        parseInt(index) == dialogPedido.pedido.produtos.length - 1
                      "
                      class="mt-4"
                    >
                      <div class="d-flex">
                        <div class="justify-content-left" style="width: 80%">
                          <p>Valor total</p>
                        </div>
                        <div
                          class="justify-content-right text-left"
                          style="width: 20%"
                        >
                          <strong>{{
                            parseFloat(dialogPedido.pedido.preco)
                              .toFixed(2)
                              .replace(".", ",")
                          }}</strong>
                        </div>
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <p v-if="dialogPedido.pedido.observacao">
                      Observação:
                      <strong>{{ dialogPedido.pedido.observacao }}</strong>
                    </p>
                    <p>
                      Forma de Pagamento:
                      <strong>{{ dialogPedido.pedido.metodoPgto }}</strong>
                    </p>
                    <p v-if="dialogPedido.pedido.valorTroco">
                      Valor Troco:
                      <strong>{{
                        parseFloat(dialogPedido.pedido.valorTroco)
                          .toFixed(2)
                          .replace(".", ",")
                      }}</strong>
                    </p>
                    <p>
                      Modo de entrega:
                      <strong>{{
                        dialogPedido.pedido.valorTele ? "Tele" : "Retirada"
                      }}</strong>
                    </p>
                  </v-card-text>
                  <v-row class="d-flex justify-space-around pb-2">
                    <v-col cols="4" class="text-center">
                      <v-btn
                        @click="closeDialog()"
                        size="small"
                        icon="mdi-close"
                        label="Fechar"
                        color="red"
                      ></v-btn>
                    </v-col>
                   
                  </v-row>
                </v-col>
              </v-row>
              <v-btn
                icon
                @click="
                  imprimePedido(
                    this.dialogPedido.pedido,
                    this.dadosEmpresa,
                    this.clientes
                  )
                "
                elevation="0"
                style="
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <!-- <v-btn
                icon
                @click="setDialogEdit(this.dialogPedido.pedido)"
                elevation="0"
                style="
                  position: absolute;
                  right: 95px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon color="green">mdi-pencil-outline</v-icon>
              </v-btn> -->
              <v-btn
                icon
                @click="abreWhats(this.dialogPedido.pedido.clienteNum)"
                elevation="0"
                style="
                  position: absolute;
                  right: 50px;
                  top: 10px;
                  cursor: pointer;
                "
              >
                <v-icon color="green">mdi-whatsapp</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-dialog>
</template>
<script>
import imprimeEncomenda from "../libs/imprimeEncomenda";
import imprimePedidos from "../libs/imprimePedidos";
import { mapGetters } from "vuex";
import { store } from "../store/store";

export default {
  name: "dialogFinanceiro",
  data: () => ({}),
  watch: {
    "dialogPedido.pedido.status"() {
        this.dialogPedido.classStatus = {
        "background-color": `${
          this.dialog.pedido.status == null
            ? "#9C27B0"
            : this.dialog.pedido.status == "Aceito"
            ? "#3F51B5"
            : this.dialog.pedido.status == "recebido"
            ? "#FFC107"
            : this.dialog.pedido.status.match("tele")
            ? "#4CAF50"
            : 'black'
        }`,
      };
    },
  },

  computed: {
    ...mapGetters(["dialogPedido", "clientes", "dadosEmpresa"]),
  },
  methods: {
    enviaWhats(numero, numerodopedido, valor, metodoPgto, valorTele) {
      var prodWpp = null;
      if (valorTele) {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e logo sera entregue. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      } else {
        prodWpp =
          "Pedido nº " +
          numerodopedido +
          "\nR$ " +
          valor +
          "\n Está pronto e aguardand retirada. \nMétodo de pagamento escolhido: " +
          metodoPgto;
        window.open(
          "https://api.whatsapp.com/send?phone=" + numero + "&text=" + prodWpp
        );
      }
    },
    imprimeEncomenda(i, dadosEmpresa, clientes) {
      imprimeEncomenda(i, dadosEmpresa, clientes);
    },
    imprimePedido(i, dadosEmpresa, clientes) {
      imprimePedidos(i, dadosEmpresa, clientes);
    },
    closeDialog(){
        store.commit("incrementDialogPedido", {ativo:false, pedido: {}});
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
<style scoped>
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>