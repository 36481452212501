import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../store/store";

export default function getPagamento(database) {
  onSnapshot(doc(db, database, "pagamento"), (doc) => {
    var data = doc.data();
    store.commit("incrementPagamento", data ? data : {
      ativo: false,
      merchant_id: "",
      merchant_key: "",
    });
    
  });
}
