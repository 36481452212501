import "vuetify/styles"; // Global CSS has to be imported
import { createApp } from "vue";

import { store } from "./store/store";

import "@mdi/font/css/materialdesignicons.css";

import VueMobileDetection from "vue-mobile-detection";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "v-calendar/dist/style.css";
import VCalendar from "v-calendar";

import App from "./App.vue";
//mask
import VueMask from "@devindex/vue-mask";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import VueHtmlToPaper from "vue-html-to-paper";

const vuetify = createVuetify({
  components,
  directives,
});

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/vuetify@3.0.1/dist/vuetify.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@3.0.1/dist/vuetify.min.js",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css",
  ],
};

//firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDdIWlYQkEqKoN8WWJnaRDOMsc3-UOAtO8",
  authDomain: "deliverysomasys-dcf58.firebaseapp.com",
  projectId: "deliverysomasys-dcf58",
  storageBucket: "deliverysomasys-dcf58.appspot.com",
  messagingSenderId: "888030115062",
  appId: "1:888030115062:web:114fafc7af9f7ad28568d7",
  measurementId: "G-JB68XN5MPD",
};

// Initialize Firebase
const app1 = initializeApp(firebaseConfig);
const db = getFirestore(app1);

const auth = initializeAuth(app1, {
  persistence: browserLocalPersistence,
});
export { app, db, auth };

//Rotas
import router from "./router/index";

const app = createApp(App);

app.use(router);

app.use(VueMask);

app.use(VCalendar, {});

app.use(VueSweetalert2);

app.use(VueMobileDetection);

app.use(vuetify);

app.use(store);

app.use(VueHtmlToPaper, options);

app.mount("#app");
