<template>
  <v-container fluid class="grey lighten-5">
    <v-row class="grey 5">
      <v-col
        cols="12"
        md="8"
        class="mx-auto"
        style="min-height: 90vh; max-height: 90vh"
      >
        <v-card id="card">
          <v-row class="mb-n15">
            <v-col class="d-flex justify-end">
              <v-btn icon elevation="0" @click="setDialog()"
                ><v-icon color="red">mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <h1 class="text-center">
            {{ this.pedidoEdit ? "Alterar Encomenda" : "Nova Encomenda" }}
          </h1>
          <v-form ref="form">
            <v-row class="px-2">
              <v-col cols="12">
                <v-card-title class="text-h6">
                  Selecione Produtos:
                </v-card-title>
              </v-col>

              <v-card
                elevation="12"
                style="width: 80%; overflow: hidden"
                v-for="(produto, index) in pedido.produtos"
                :key="produto"
                class="pl-4 mx-auto mt-4 mb-4"
              >
                <v-row>
                  <v-col cols="12" md="6" class="d-flex">
                    <v-col cols="12">
                      <v-autocomplete
                        required
                        v-model="pedido.produtos[index]"
                        @update:modelValue="
                          pedido.produtos[index] != null
                            ? (pedido.produtos[index].quantidade = 1)
                            : ''
                        "
                        variant="underlined"
                        color="red"
                        label="Selecione um Produto"
                        :items="produtosPush"
                        :item-value="
                          (item) =>
                            (item = {
                              limiteSaborUnic: item.limiteSaborUnic ? item.limiteSaborUnic : '',
                              nome: item.nome ? item.nome : '',
                              ativado: item.ativado ? item.ativado : '',
                              codbarras: item.codbarras ? item.codbarras : '',
                              descricao: item.descricao ? item.descricao : '',
                              categoria: item.categoria ? item.categoria : '',
                              valor: item.valor ? item.valor : '',
                              img_url: item.item_url ? item.item_url : null,
                              verificador: item.verificador
                                ? item.verificador
                                : '',
                              valorTamComposto: item.valorTamComposto
                                ? item.valorTamComposto
                                : '',
                              valorSaborComposto: item.valorSaborComposto
                                ? item.valorSaborComposto
                                : '',
                              saborSelecionado: [],
                              saborComp: item.saborComp ? item.saborComp : '',
                              valorTamComp: item.valorTamComp
                                ? item.valorTamComp
                                : '',
                              tamanhoComp: item.tamanhoComp
                                ? item.tamanhoComp
                                : '',
                              tamanhoUnic: item.tamanhoUnic
                                ? item.tamanhoUnic
                                : '',
                              adicionais: item.adicionais
                                ? item.adicionais
                                : null,
                              valorAdicionais: item.valorAdicionais
                                ? item.valorAdicionais
                                : null,
                            })
                        "
                        item-title="nome"
                      />
                    </v-col>
                  </v-col>
                  <v-col
                    cols="7"
                    md="4"
                    class="d-flex mt-md-6 d-flex justify-start"
                    v-if="pedido.produtos[index] != ''"
                  >
                    <v-btn
                      v-if="pedido.produtos[index].quantidade > 1"
                      elevation="0"
                      icon
                      @click="pedido.produtos[index].quantidade -= 1"
                    >
                      <v-icon size="large" color="red">
                        mdi-minus-circle
                      </v-icon>
                    </v-btn>
                    <div class="mx-4 mt-3">
                      <p>{{ pedido.produtos[index].quantidade }} x</p>
                    </div>
                    <v-btn
                      elevation="0"
                      icon
                      @click="pedido.produtos[index].quantidade += 1"
                    >
                      <v-icon size="large" color="red">
                        mdi-plus-circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="4"
                    md="1"
                    class="d-flex justify-end mt-md-6 mr-1"
                    v-if="pedido.produtos[index] != ''"
                  >
                    <div v-if="index >= 0" cols="1">
                      <v-btn
                        icon
                        elevation="0"
                        @click="pedido.produtos.push([''])"
                        ><v-icon size="large" color="red"
                          >mdi-plus-box-multiple-outline</v-icon
                        ></v-btn
                      >
                    </div>

                    <div v-if="pedido.produtos.length > 1" cols="1">
                      <v-btn
                        icon
                        elevation="0"
                        @click="pedido.produtos.splice(index, 1)"
                        ><v-icon size="large" color="red"
                          >mdi-minus-box-multiple-outline</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-col
                  cols="12"
                  v-if="pedido.produtos[index].tamanhoComp"
                  class="d-flex mt-n10"
                >
                  <v-col cols="12" md="5">
                    <v-select
                      v-model="pedido.produtos[index].valorTamComp"
                      @update:modelValue="
                        (pedido.produtos[index].valor =
                          pedido.produtos[index].valorTamComp.valor),
                          pedido.produtos[index].adicionaisDesc
                            ? this.valorAdicionais(index)
                            : pedido.produtos[index].saborSelecionado != ''
                            ? parseFloat(
                                pedido.produtos[index].saborSelecionado.valor
                              ) +
                              parseFloat(
                                pedido.produtos[index].valorTamComp.valor
                              )
                            : parseFloat(pedido.produtos[index].valorTamComp)
                      "
                      required
                      label="Tamanhos"
                      :items="pedido.produtos[index].valorTamComposto"
                      :item-title="
                        (item) => item.descricao + ' - R$' + item.valor
                      "
                      :item-value="
                        (item) =>
                          (item = {
                            valor: parseFloat(
                              item.valor.replace(',', '.')
                            ).toFixed(2),
                            descricao: item.descricao,
                            sabores: item.sabores ? item.sabores : 1,
                          })
                      "
                      variant="underlined"
                      color="red"
                    />
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  v-if="
                    pedido.produtos[index].saborComp &&
                    pedido.produtos[index].tamanhoUnic
                  "
                >
                  <p style="opacity: 60%; font-size: 15px">
                    Maximo de sabores:
                    {{
                      pedido.produtos[index].limiteSaborUnic
                        ? pedido.produtos[index].limiteSaborUnic
                        : 1
                    }} {{  }}
                  </p>

                  <v-form :ref="`form${index}`">
                    <v-select
                      multiple
                      v-model="pedido.produtos[index].saborSelecionado"
                      label="Sabores"
                      :items="pedido.produtos[index].valorSaborComposto"
                      :item-disabled="(item) => item.disabled"
                      :item-title="
                        (item) => item.descricao + ' - R$' + item.valor
                      "
                      :item-value="(item) => item"
                      variant="underlined"
                      color="red"
                      @update:modelValue="this.valorAdicionais(index)"
                      :rules="[
                        (val) =>
                          val.length <=
                            (pedido.produtos[index].limiteSaborUnic
                              ? pedido.produtos[index].limiteSaborUnic
                              : 1) ||
                          'Selecione sabores de acordo com o limite',
                        (val) =>
                          val.length != 0 || 'Selecione ao menos um sabor',
                      ]"
                    >
                    
                      <template v-slot:item="{ item, props }">
                        <v-list-item
                          :disabled="
                            !pedido.produtos[index].saborSelecionado.find(
                              (val) =>
                                JSON.stringify(val.descricao) ==
                                JSON.stringify(item.value.descricao)
                            ) &&
                            pedido.produtos[index].saborSelecionado.length ==
                              (pedido.produtos[index].limiteSaborUnic
                                ? pedido.produtos[index].limiteSaborUnic
                                : 1)
                          "
                          
                          v-bind="props"
                          :key="item.value.descricao"
                          :value="item.value"
                          :title="
                            item.value.descricao + ' - R$' + item.value.valor
                          "
                        >
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-form>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  v-if="
                    pedido.produtos[index].saborComp &&
                    pedido.produtos[index].tamanhoComp == true
                  "
                >
                  <p style="opacity: 60%; font-size: 15px">
                    Maximo de sabores:
                  </p>
                  {{ pedido.produtos[index].valorTamComp.sabores }}
                  <v-form :ref="`form${index}`">
                    <v-select
                      multiple
                      v-model="pedido.produtos[index].saborSelecionado"
                      label="Sabores"
                      :items="pedido.produtos[index].valorSaborComposto"
                      :item-disabled="(item) => item.disabled"
                      :item-title="
                        (item) => item.descricao + ' - R$' + item.valor
                      "
                      :item-value="(item) => item"
                      @update:modelValue="this.valorAdicionais(index)"
                      variant="underlined"
                      color="red"
                      :rules="[
                        (val) =>
                          val.length <=
                            (pedido.produtos[index].valorTamComp.sabores
                              ? pedido.produtos[index].valorTamComp.sabores
                              : 1) ||
                          'Selecione sabores de acordo com o limite',
                        (val) =>
                          val.length != 0 || 'Selecione ao menos um sabor',
                      ]"
                    >
                      <template v-slot:item="{ item, props }">
                        <v-list-item
                          :disabled="
                            !pedido.produtos[index].saborSelecionado.find(
                              (val) =>
                                JSON.stringify(val.descricao) ==
                                JSON.stringify(item.value.descricao)
                            ) &&
                            pedido.produtos[index].saborSelecionado.length ==
                              (pedido.produtos[index].valorTamComp.sabores
                                ? pedido.produtos[index].valorTamComp.sabores
                                : 1)
                          "
                          v-bind="props"
                          :key="item.value.descricao"
                          :value="item.value"
                          :title="
                            item.value.descricao + ' - R$' + item.value.valor
                          "
                        >
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-form>
                </v-col>

                <v-col
                  cols="12"
                  v-if="pedido.produtos[index].adicionais == 'true'"
                  class="d-flex mt-n10"
                >
                  <v-col cols="12" md="5">
                    <v-select
                      multiple
                      v-model="pedido.produtos[index].adicionaisSelecionados"
                      @update:modelValue="this.valorAdicionais(index)"
                      required
                      label="Adicionais"
                      :items="produto.valorAdicionais"
                      :item-title="
                        (item) => item.descricao + ' - R$' + item.valor
                      "
                      :item-value="(item) => item"
                      variant="underlined"
                      color="red"
                    />
                  </v-col>
                </v-col>

                <v-col
                  v-if="
                    pedido.produtos[index] != '' &&
                    pedido.produtos[index].tamanhoComp
                  "
                  cols="12"
                  class="d-flex"
                >
                  Valor Unitário:
                  {{ parseFloat(this.pedido.produtos[index].valor).toFixed(2) }}
                </v-col>
                <v-col
                  v-if="
                    pedido.produtos[index] != '' &&
                    !pedido.produtos[index].tamanhoComp
                  "
                  cols="12"
                  class="d-flex"
                >
                  Valor Unitário:
                  {{ parseFloat(this.pedido.produtos[index].valor).toFixed(2) }}
                </v-col>
              </v-card>
            </v-row>
            <v-row>
              <v-col cols="9" class="mx-auto">
                <v-text-field
                  v-model="cliente.nome"
                  color="red"
                  label="Nome *"
                  variant="underlined"
                  required
                  maxlength="60"
                  :rules="[
                    (val) =>
                      (RegExp(
                        '^[A-Za-z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]*$'
                      ).test(val) &&
                        !RegExp('[ ]{2,}').test(val)) ||
                      'Nome Inválido',
                    (val) => val.length != 0 || 'Nome Inváido',
                    (val) => val.length >= 3 || 'Nome Inváido',
                  ]"
                >
                </v-text-field>
                <v-text-field
                  v-model="cliente.cpf"
                  color="red"
                  label="CPF (somente numeros)"
                  variant="underlined"
                  pattern="[0-9]*"
                  counter
                  placeholder="999.999.999-99"
                  maxlength="11"
                  minlength="11"
                  required
                >
                </v-text-field>
                <v-text-field
                  v-model="cliente.telefone"
                  label="Celular (somente numeros) *"
                  variant="underlined"
                  color="red"
                  counter
                  placeholder="(99) 9999-99999"
                  maxlength="15"
                  minlength="14"
                  :rules="[
                    (val) =>
                      val.length > 13 || 'Preencha o telefone corretamente',
                  ]"
                  required
                ></v-text-field>

                <v-col class="text-left mb-n8">
                  <span
                    class="ml-n3"
                    style="color: grey; padding-top: 16px; margin-bottom: 16px"
                    >Precisa tele?</span
                  >
                  <div class="d-flex justify-space-between">
                    <v-checkbox
                      :error-messages="this.errorCheckboxTele"
                      v-model="precisaTele"
                      label="Sim"
                      value="true"
                      class="left"
                      color="green"
                    >
                    </v-checkbox>
                    <v-checkbox
                      v-model="precisaTele"
                      label="Não"
                      value="false"
                      class="right"
                      color="red"
                    >
                    </v-checkbox>
                  </div>
                </v-col>
               
                <template v-if="precisaTele == 'true'">
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        v-model="cliente.endereco"
                        label="Endereco"
                        variant="underlined"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                      clearable
                      clear-icon="mdi-close"
                      v-model="cliente.numero"
                      label="Número"
                      required
                      variant="underlined">
                    </v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    v-model="cliente.complemento"
                    label="Complemento"
                    variant="underlined">
                  </v-text-field>
                  <v-autocomplete
                    v-model="cliente.bairro"
                    label="Bairro *"
                    :items="bairros"
                    :item-title="(item) => item.nome"
                    :item-value="(item) => item"
                    color="red"
                    variant="underlined"
                    clearable
                    density="comfortable"
                    required
                    max-height="50px"
                    :rules="[
                      (val) => val != null || 'Selecione um Bairro',
                      (val) => val != '' || 'Selecione um Bairro',
                    ]"
                  ></v-autocomplete>
                </template>

                <v-col class="text-left">
                  <span
                    class="ml-n3"
                    style="color: grey; padding-top: 16px; margin-bottom: 16px"
                    v-if="precisaTele == 'true'"
                  >
                    Valor Tele:
                    {{
                      this.valorTele
                        ? this.valorTele
                        : "Nao Possui"
                    }}</span
                  >
                </v-col>

                <v-textarea
                  v-model="pedido.observacao"
                  label="Observações"
                  color="red"
                  variant="underlined"
                  maxlength="200"
                  :rules="[
                    (val) =>
                      (RegExp(
                        '^[A-Za-z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ,.()\n ]*$'
                      ).test(val) &&
                        !RegExp('[ ]{2,}').test(val)) ||
                      'Obervação Inválida, verifique espaçamento e caracteres',
                  ]"
                >
                  >
                </v-textarea>
                <div
                  class="text-left"
                  style="color: grey; padding-top: 16px; margin-bottom: 16px"
                >
                  <span>Data de Entrega *</span>
                  <input
                    type="date"
                    v-model="pedido.dataEntrega"
                    class="px-1"
                    style="
                      border: 0.5px solid grey;
                      border-radius: 5px;
                      width: 100%;
                      height: 56px;
                      color: grey;
                    "
                  />
                  <div style="padding-top: 32px; margin-bottom: 16px">
                    <span>Hora da Entrega *</span>
                    <input
                      v-model="pedido.horaEntrega"
                      style="
                        border: 0.5px solid grey;
                        border-radius: 5px;
                        width: 100%;
                        height: 56px;
                        color: grey;
                        font-size: 18px;
                      "
                      type="time"
                      id="appt"
                      name="appt"
                    />
                  </div>
                </div>
                <v-select
                v-if="this.dadosEmpresa.sitef.ativo"
                v-model="pedido.ambientePgto"
                :menu-props="{ overflow: 'hidden' }"
                :items="ambientesPgto"
                :color="dadosEmpresa.corBase"
                variant="underlined"
                label="Onde pagar? *"
                required
                :rules="[
                  (val) => val != '' || 'Escolha o ambiente de pagamento]',
                  (val) => val != null || 'Escolha o ambiente de pagamento',
                  (val) =>
                    val != undefined || 'Escolha o ambiente de pagamento',
                ]"
              ></v-select>
                <v-select
                  id="metodoPgto"
                  v-model="metodoPgto"
                  class="mb-10"
                  :menu-props="{ overflow: 'hidden' }"
                  :items="mutableMetodosPgto"
                  :item-value="(item) => item.nome"
                  :item-title="(item) => item.nome"
                  color="red"
                  variant="underlined"
                  label="Método de Pagamento *"
                  required
                ></v-select>

                <v-col v-if="metodoPgto == 'Dinheiro'" class="text-left">
                  <span
                    class="ml-n3"
                    style="color: grey; padding-top: 16px; margin-bottom: 16px"
                    >Precisa troco?</span
                  >
                  <div class="d-flex justify-space-between">
                    <v-checkbox
                      :error-messages="this.errorCheckboxTroco"
                      v-model="precisaTroco"
                      label="Sim"
                      value="true"
                      class="left"
                      color="green"
                    >
                    </v-checkbox>
                    <v-checkbox
                      v-model="precisaTroco"
                      label="Não"
                      value="false"
                      class="right"
                      color="red"
                    >
                    </v-checkbox>
                  </div>
                </v-col>

                <v-text-field
                  v-if="precisaTroco == 'true' && metodoPgto == 'Dinheiro'"
                  type="text"
                  name="decimal"
                  v-model="pedido.valorTroco"
                  variant="underlined"
                  label="Troco para (R$)"
                  color="red"
                  clearable
                  clear-icon="mdi-close"
                  required
                  maxlength="6"
                  :rules="[
                    (val) =>
                      RegExp('^[0-9,]*$').test(val) ||
                      'Valor precisa ser válido',
                    (val) =>
                      parseFloat(val.replace(',', '.')) >= this.precoTotal ||
                      'Troco necessita ser maior que valor total',
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <div
          style="
            background-color: #f44336 !important;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
          "
        >
          <v-btn
            color="red"
            style="width: 100%"
            class="mx-auto"
            elevation="0"
            @click="validate"
          >
            <p>Concluir Pedido R$ {{ parseFloat(precoTotal).toFixed(2) }}</p>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import setDialog from "../../../libs/setDialog";
import updatePedido from "../../../libs/updatePedido";
import insertPedido from "../../../libs/setPedido";
import { maskJs } from "mask-js";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "encomenda",
  data: () => ({
    mutableMetodosPgto: [],
    ambientesPgto: ["Pagar no app", "Pagar na entrega/retirada"],
    valorTele: "",
    bairros: [],
    produtosPush: [],
    metodoPgto: null,
    metodosPgto: [],
    precisaTroco: "false",
    precisaTele: "false",
    cliente: {
      nome: "",
      cpf: "",
      telefone: "",
      endereco: "",
      numero: "",
      complemento: "",
      bairro: ''
    },
    dadosempresa: [],
    snackbar: false,
    horarios: [],
    text: "",
    pedido: {
      valorTroco: null,
      num: null,
      cliente: null,
      metodoPgto: null,
      preco: null,
      status: null,
      data: null,
      clienteNum: null,
      observacao: null,
      dataEntrega: null,
      horaEntrega: null,
      produtos: [""],
    },
    errorCheckboxTroco: "",
    disableCheckboxTroco: false,
    errorCheckboxTele: "",
    disableCheckboxTele: false,
    precoTotal: 0,
  }),
  watch: {
   
    ambientePgto(n) {
      if (n == "Pagar na entrega/retirada") {
        this.mutableMetodosPgto = JSON.parse(JSON.stringify(this.metodosPgto));
      } else {
        this.mutableMetodosPgto = this.metodosPgto.filter(
          (val) => !val.nome.toLowerCase().match("dinheiro"),
        );
      }
    },
    "cliente.telefone"(n) {
      const cleanedPhone = n.replace(/\D/g, "");
      if (cleanedPhone.length > 10) {
        this.cliente.telefone = maskJs("(99) 9 9999-9999", n);
      } else {
        this.cliente.telefone = maskJs("(99) 9999-9999", n);
      }
    },
    "pedido.produtos": {
      handler(n) {
        if (n[0] != null) {
          this.calculaTele(this.precisaTele);
        } else {
          this.pedido.produtos = [""];
        }
      },
      deep: true,
    },
    "cliente.bairro"() {
      this.calculaTele(this.precisaTele);
    },
    precisaTele(n) {
      this.calculaTele(n);
    },
    precisaTroco(n) {
      if (n == "") {
        this.errorCheckboxTroco = "Escolha uma opção";
        this.disableCheckboxTroco = true;
      } else {
        this.errorCheckboxTroco = "";
        this.disableCheckboxTroco = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "clientes",
      "database",
      "pedidos",
      "database",
      "produtos",
      "adicionais",
      "categorias",
      "dadosEmpresa",
      "combos",
      "pedidoEdit",
    ]),
  },
  methods: {
    calculaTele(n) {
      if (n == "") {
        this.errorCheckboxTele = "Escolha uma opção";
        this.disableCheckboxTele = true;
      } else {
        this.errorCheckboxTele = "";
        this.disableCheckboxTele = false;
      }
      if (n == "true") {
        var stop = false;

        for (var grupos of this.dadosEmpresa.grupos) {
          for (var bairro of grupos.bairros) {
            if(this.cliente.bairro != ''){
              if (bairro.id == this.cliente.bairro.id) {
                this.valorTele = grupos.valor;
                stop = true;
              } else if (stop == false) {
                this.valorTele = this.dadosEmpresa.valorTele;
              }
            }
          }
        }
        for (var i = 0; i < this.pedido.produtos.length; i++) {
          this.precoTotal = 0;
          if (this.pedido.produtos[i] != "") {
            if (
              this.pedido.produtos[i].tamanhoComp &&
              !this.pedido.produtos[i].saborComp
            ) {
              this.precoTotal =
                this.precoTotal +
                parseFloat(this.pedido.produtos[i].valor) *
                  this.pedido.produtos[i].quantidade +
                parseFloat(this.valorTele);
            } else {
              this.precoTotal =
                this.precoTotal +
                parseFloat(this.pedido.produtos[i].valor) *
                  this.pedido.produtos[i].quantidade +
                parseFloat(this.valorTele);
            }
          } else {
            this.precoTotal = parseFloat(this.valorTele);
          }
        }
      } else if (n == "false") {
        this.precoTotal = 0;
        this.valorTele = 0;
        for (i = 0; i < this.pedido.produtos.length; i++) {
          if (this.pedido.produtos[i] != "") {
            if (
              this.pedido.produtos[i].tamanhoComp &&
              !this.pedido.produtos[i].saborComp
            ) {
              this.precoTotal =
                this.precoTotal +
                parseFloat(this.pedido.produtos[i].valor) *
                  this.pedido.produtos[i].quantidade;
            } else {
              this.precoTotal =
                this.precoTotal +
                parseFloat(this.pedido.produtos[i].valor) *
                  this.pedido.produtos[i].quantidade;
            }
          }
        }
      }
    },
    setDialog() {
      setDialog();
    },
    validate() {
      this.$refs.form.validate().then((val) => {
        if (val.valid) {
          this.validacoes();
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Formulário inválido",
            confirmButtonText: "Ok",
          });
        }
      });
    },
    validacoes() {
      var minDiasData = new Date();
      minDiasData.setDate(
        minDiasData.getDate() + parseInt(this.dadosEmpresa.minDiaEncomenda)
      );
      minDiasData = minDiasData.toISOString().split("T")[0];

      if (this.pedido.produtos.find((val) => val == "") == "") {
        this.$swal.fire({
          icon: "error",
          text: "Preencha os produtos corretamente",
          confirmButtonText: "Ok",
        });
      } else if (
        this.pedido.produtos.find(
          (val) => val.saborComp && val.saborSelecionado == ""
        )
      ) {
        for (let produto of this.pedido.produtos) {
          if (produto.saborComp) {
            if (produto.saborSelecionado == "") {
              this.$swal.fire({
                icon: "error",
                text: "Adicione ao menos um sabor aos produtos que necessitam",
                confirmButtonText: "Ok",
              });
            }
          }
        }
      } else if (
        this.precisaTele == "true" &&
        this.cliente.endereco.length < 8
      ) {
        this.$swal.fire({
          icon: "error",
          text: "Informe um endereço válido",
          confirmButtonText: "Ok",
        });
      } else if (
        this.pedido.dataEntrega == null ||
        this.pedido.dataEntrega == "" ||
        this.pedido.dataEntrega == undefined
      ) {
        this.$swal.fire({
          icon: "error",
          text: "Selecione a data de entrega",
          confirmButtonText: "Ok",
        });
      } else if (
        this.pedido.horaEntrega == null ||
        this.pedido.horaEntrega == "" ||
        this.pedido.horaEntrega == undefined
      ) {
        this.$swal.fire({
          icon: "error",
          text: "Selecione a hora de entrega",
          confirmButtonText: "Ok",
        });
      }
      // }else if(this.pedido.dataEntrega && this.pedido.dataEntrega  < new Date().toISOString().split('T')[0]){
      //   this.$swal.fire({
      //     icon: 'error',
      //     text: 'Data entrega precisa ser maior que data atual',
      //     confirmButtonText: 'Ok',
      //   })
      // } else if(this.pedidoEdit == '' && minDiasData > this.pedido.dataEntrega){
      //   this.$swal.fire({
      //     icon: 'error',
      //     text: `Data de entrega precisa ser no mínimo em ${this.dadosEmpresa.minDiaEncomenda} dias.`,
      //     confirmButtonText: 'Ok',
      //   })
      // }
      else if (
        this.metodoPgto == null ||
        !this.metodoPgto ||
        this.metodoPgto == ""
      ) {
        this.$swal.fire({
          icon: "error",
          text: "Selecione o método de pagamento",
          confirmButtonText: "Ok",
        });
      } else if (this.cliente.cpf == "") {
        this.$swal
          .fire({
            icon: "error",
            text: "CPF Vazio",
            showDenyButton: true,
            confirmButtonText: "Ok",
            denyButtonText: "Gerar mesmo assim",
            denyButtonColor: "#F44336",
          })
          .then((result) => {
            if (result.isDenied) {
              this.setPedidos();
            }
          });
      } else if (this.validaCpf() == false) {
        this.$swal
          .fire({
            icon: "error",
            text: "Informe um cpf válido",
            showDenyButton: true,
            confirmButtonText: "Ok",
            denyButtonText: "Gerar mesmo assim",
          })
          .then((result) => {
            if (result.isDenied) {
              this.setPedidos();
            }
          });
      } else {
        this.setPedidos();
      }
    },

    //Insere o pedido no banco de dados
    async setPedidos() {
      this.pedido.valorTele = this.valorTele
      this.pedido.dadosCliente = this.cliente;

      if (this.precisaTele == "true") {
        this.pedido.valorTele = parseFloat(this.valorTele).toFixed(
          2
        );
      }
      this.pedido.metodoPgto = this.metodoPgto;
      for (var i = 0; i < this.pedido.produtos.length; i++) {
        if (this.pedido.produtos[i].adicionaisSelecionados) {
          for (
            var o = 0;
            o < this.pedido.produtos[i].adicionaisSelecionados.length;
            o++
          ) {
            this.pedido.produtos[i].valor =
              parseFloat(this.pedido.produtos[i].valor) -
              parseFloat(
                this.pedido.produtos[i].adicionaisSelecionados[o].valor.replace(
                  ",",
                  "."
                )
              );
          }
        }

        this.pedido.produtos[i].nomeSemTamanho = this.pedido.produtos[i].nome;
        this.pedido.produtos[i].saboresDesc = "";
        this.pedido.produtos[i].valorSaboresDesc = 0;

        if (this.pedido.produtos[i].tamanhoComp) {
          this.pedido.produtos[i].nome =
            this.pedido.produtos[i].nome +
            " " +
            this.pedido.produtos[i].valorTamComp.descricao;
        }
        if (this.pedido.produtos[i].saborSelecionado) {
          for (var saborSelecionado of this.pedido.produtos[i]
            .saborSelecionado) {
            this.pedido.produtos[i].valorSaboresDesc +=
              saborSelecionado.valor.replace(",", ".") *
              this.pedido.produtos[i].quantidade;
            if (!this.pedido.produtos[i].saboresDesc) {
              this.pedido.produtos[i].saboresDesc = saborSelecionado.descricao;
            } else {
              this.pedido.produtos[i].saboresDesc +=
                ", " + saborSelecionado.descricao;
            }
          }
        }
      }
      this.pedido.preco = this.precoTotal.toString();
      this.pedido.clienteNum =
        "55" + this.cliente.telefone.replace(/[() -]/g, "");
      this.pedido.data = new Date().toJSON().substring(0, 10).replace("T", " ");
      let response = [];
      if (this.pedidoEdit) {
        this.pedido.num = this.pedido.num.toString();
        response = await updatePedido(this.database.cnpj, this.pedido);
      } else {
        this.pedido.usuario = {
          logado: false,
          usouDados: false,
          dashboard: true,
        };
        response = await insertPedido(this.database.cnpj, this.pedido);
      }
      setDialog();
      console.log(this.pedido);
      const result = response.data.result.data;
      if (response.status != 200) {
        this.$swal.fire({
          icon: "error",
          text: "Erro ao enviar pedido",
          confirmButtonColor: "#d33",
        });
      }
      this.pedido.num = result.num;

      this.enviaWhats();
      for (i = 0; i < this.pedido.produtos.length; i++) {
        this.pedido.produtos[i].nome = this.pedido.produtos[i].nomeSemTamanho
          ? this.pedido.produtos[i].nomeSemTamanho
          : this.pedido.produtos[i].nome;
      }
    },
    enviaWhats() {
      var prodWpp = null;
      this.cliente.telefone = this.cliente.telefone.replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
      this.cliente.telefone = this.cliente.telefone.replace(" ", "");
      this.cliente.telefone = "55" + this.cliente.telefone;
      for (var i = 0; i < this.pedido.produtos.length; i++) {
        if (prodWpp == null) {
          prodWpp =
            "Numero: " +
            this.pedido.num +
            "\n" +
            "Nome: " +
            this.cliente.nome +
            "\nEndereço: " +
            this.cliente.endereco + this.cliente.numero ? this.cliente.numero : '' + this.cliente.complemento ? ', ' + this.cliente.complemento : ''
            "\nMétodo de pagamento: " +
            this.pedido.metodoPgto;
          if (this.pedido.valorTroco) {
            prodWpp += "\nTroco para (R$): R$ " + this.pedido.valorTroco;
          }
          prodWpp =
            prodWpp +
            "\n" +
            "Modo de entrega: " +
            (this.valorTele != '' ? "Tele" : "Retirada");
          if (this.valorTele != '' ) {
            prodWpp =
              prodWpp +
              "\n" +
              "Valor Tele: " +
              "R$ " +
              this.valorTele;
          }
          prodWpp +=
            "\nValor total: " +
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(this.pedido.preco) +
            "\nPedido: \n\n";
          prodWpp += this.pedido.produtos[i].nomeTamanho
            ? this.pedido.produtos[i].nomeTamanho +
              " x " +
              this.pedido.produtos[i].quantidade
            : this.pedido.produtos[i].nome +
              " x " +
              this.pedido.produtos[i].quantidade;
          if (this.pedido.produtos[i].adicionaisDesc != undefined && this.pedido.produtos[i].adicionaisDesc != '' && this.pedido.produtos[i].adicionaisDesc != null) {
            prodWpp +=
              "\n" + "Adicionais: " + this.pedido.produtos[i].adicionaisDesc;
          }
          if (this.pedido.produtos[i].saboresDesc != undefined && this.pedido.produtos[i].saboresDesc != '' && this.pedido.produtos[i].saboresDesc != null) {
            prodWpp += "\n" + "Sabores: " + this.pedido.produtos[i].saboresDesc;
          }
          prodWpp += "\n\n";
        } else {
          prodWpp += this.pedido.produtos[i].nomeTamanho
            ? this.pedido.produtos[i].nomeTamanho +
              " x " +
              this.pedido.produtos[i].quantidade
            : this.pedido.produtos[i].nome +
              " x " +
              this.pedido.produtos[i].quantidade;
          if (this.pedido.produtos[i].adicionaisDesc != undefined && this.pedido.produtos[i].adicionaisDesc != '' && this.pedido.produtos[i].adicionaisDesc != null) {
            prodWpp +=
              "\n" + "Adicionais: " + this.pedido.produtos[i].adicionaisDesc;
          }
          if (this.pedido.produtos[i].saboresDesc != undefined && this.pedido.produtos[i].saboresDesc != '' && this.pedido.produtos[i].saboresDesc != null) {
            prodWpp += "\n" + "Sabores: " + this.pedido.produtos[i].saboresDesc;
          }
          prodWpp += i == this.pedido.produtos.length - 1 ? "\n" : "\n\n";
        }

        if (i == this.pedido.produtos.length - 1 && this.pedido.observacao) {
          prodWpp = prodWpp + "\n" + "Observações: \n" + this.pedido.observacao;
        }
        if (i == this.pedido.produtos.length - 1 && this.pedido.dataEntrega) {
          prodWpp =
            prodWpp +
            "\n" +
            "Data de Entrega: \n" +
            this.pedido.dataEntrega.split("-").reverse().join("/") +
            "\n" +
            "Hora de Entrega: \n" +
            this.pedido.horaEntrega;
        }
      }

      prodWpp = window.encodeURIComponent(prodWpp);
      if (this.$isMobile()) {
        window.open(
          "https://api.whatsapp.com/send?phone=" +
            this.cliente.telefone +
            "&text=" +
            prodWpp
        );
      } else {
        window.open(
          "https://api.whatsapp.com/send?phone=" +
            this.cliente.telefone +
            "&text=" +
            prodWpp
        );
      }
    },

    validaCpf() {
      if (this.cliente.cpf != null && this.cliente.cpf.length == 11) {
        var cpf = this.cliente.cpf.trim();
        var p = 0;
        var i = 0;
        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
          v1 += cpf[i] * p;
        }

        v1 = (v1 * 10) % 11;

        if (v1 == 10) {
          v1 = 0;
        }

        if (v1 != cpf[9]) {
          return false;
        }

        for (i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
          v2 += cpf[i] * p;
        }

        v2 = (v2 * 10) % 11;

        if (v2 == 10) {
          v2 = 0;
        }

        if (v2 != cpf[10]) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    valorAdicionais(index) {
      if (!this.pedido.produtos[index].tamanhoComp) {
        this.pedido.produtos[index].valor = parseFloat(
          this.produtos
            .find(
              (element) =>
                element.codbarras == this.pedido.produtos[index].codbarras
            )
            .valor.replace(",", ".")
        ).toFixed(2);
        this.pedido.possuiAdicionais = null;
        this.pedido.produtos[index].adicionaisDesc = null;

        if (this.pedido.produtos[index].adicionaisSelecionados) {
          for (
            var i = 0;
            i < this.pedido.produtos[index].adicionaisSelecionados.length;
            i++
          ) {
            this.pedido.produtos[index].adicionaisDesc = this.pedido.produtos[
              index
            ].adicionaisDesc
              ? this.pedido.produtos[index].adicionaisDesc +
                ", " +
                this.pedido.produtos[index].adicionaisSelecionados[i].descricao
              : this.pedido.produtos[index].adicionaisSelecionados[i].descricao;
            this.pedido.possuiAdicionais = true;
            this.pedido.produtos[index].valor =
              parseFloat(this.pedido.produtos[index].valor) +
              parseFloat(
                this.pedido.produtos[index].adicionaisSelecionados[
                  i
                ].valor.replace(",", ".")
              );
          }
        }
      } else {
        this.pedido.produtos[index].valorTamComp = this.pedido.produtos[index]
          .valorTamComp
          ? this.pedido.produtos[index].valorTamComp
          : this.pedido.produtos[index].tamSelecionado;
        this.pedido.produtos[index].valor = parseFloat(
          this.pedido.produtos[index].valorTamComp.valor
        );
        this.pedido.possuiAdicionais = null;
        this.pedido.produtos[index].adicionaisDesc = null;
        if (this.pedido.produtos[index].adicionaisSelecionados) {
          for (
            i = 0;
            i < this.pedido.produtos[index].adicionaisSelecionados.length;
            i++
          ) {
            this.pedido.produtos[index].adicionaisDesc = this.pedido.produtos[
              index
            ].adicionaisDesc
              ? this.pedido.produtos[index].adicionaisDesc +
                ", " +
                this.pedido.produtos[index].adicionaisSelecionados[i].descricao
              : this.pedido.produtos[index].adicionaisSelecionados[i].descricao;
            this.pedido.possuiAdicionais = true;
            this.pedido.produtos[index].valor =
              parseFloat(this.pedido.produtos[index].valor) +
              parseFloat(
                this.pedido.produtos[index].adicionaisSelecionados[
                  i
                ].valor.replace(",", ".")
              );
          }
        }
      }
      if (this.pedido.produtos[index].saborSelecionado) {
        for (
          let i = 0;
          i < this.pedido.produtos[index].saborSelecionado.length;
          i++
        ) {
          this.pedido.produtos[index].valor += parseFloat(
            this.pedido.produtos[index].saborSelecionado[i].valor.replace(
              ",",
              "."
            )
          );
        }
      }
      return this.pedido.produtos[index].valor;
    },
    async getAdicionais() {
      if (this.adicionais) {
        for (var i = 0; i < this.adicionais.length; i++) {
          if (this.adicionais[i].produtos.length > 0) {
            for (var o = 0; o < this.adicionais[i].produtos.length; o++) {
              for (var b = 0; b < this.produtosPush.length; b++) {
                if (
                  this.produtosPush[b].verificador ==
                  this.adicionais[i].produtos[o]
                ) {
                  if (
                    this.produtosPush[b].valorAdicionais &&
                    this.produtosPush[b].valorAdicionais.length > 0
                  ) {
                    if (this.adicionais[i] != undefined) {
                      this.produtosPush[b].adicionais = "true";
                      this.produtosPush[b].valorAdicionais.push({
                        descricao: this.adicionais[i].descricao,
                        valor: this.adicionais[i].valor,
                      });
                    }
                  } else {
                    if (this.adicionais[i] != undefined) {
                      this.produtosPush[b].adicionais = "true";
                      this.produtosPush[b].valorAdicionais = [];
                      this.produtosPush[b].valorAdicionais.push({
                        descricao: this.adicionais[i].descricao,
                        valor: this.adicionais[i].valor,
                      });
                    }
                  }
                }
              }
            }
          }
          if (this.adicionais[i].categorias.length > 0) {
            for (var z = 0; z < this.adicionais[i].categorias.length; z++) {
              for (var x = 0; x < this.categorias.length; x++) {
                if (this.categorias[x].id == this.adicionais[i].categorias[z]) {
                  for (b = 0; b < this.produtosPush.length; b++) {
                    if (
                      this.produtosPush[b].categoria == this.categorias[x].id
                    ) {
                      if (
                        this.produtosPush[b].valorAdicionais &&
                        this.produtosPush[b].valorAdicionais.length > 0
                      ) {
                        if (this.adicionais[i].categorias != undefined) {
                          this.produtosPush[b].adicionais = "true";
                          this.produtosPush[b].valorAdicionais.push({
                            descricao: this.adicionais[i].descricao,
                            valor: this.adicionais[i].valor,
                          });
                        }
                      } else {
                        if (this.adicionais[i].categorias != undefined) {
                          this.produtosPush[b].adicionais = "true";
                          this.produtosPush[b].valorAdicionais = [];
                          this.produtosPush[b].valorAdicionais.push({
                            descricao: this.adicionais[i].descricao,
                            valor: this.adicionais[i].valor,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    getProdutos() {
      var indexes = (indexes = this.produtos.reduce(
        (r, v, i) => r.concat(v.encomenda == true ? i : []),
        []
      ));
      var produtos = [];
      for (var i = 0; i < indexes.length; i++) {
        if (
          this.produtos[indexes[i]].ativado == "true" &&
          this.produtos[indexes[i]].tamanhoComp &&
          this.produtos[indexes[i]].saborComp == true
        ) {
          var valor = parseFloat(
            this.produtos[indexes[i]].valor.replace(",", ".")
          ).toFixed(2);
          produtos.push({
            valorBase: valor,
            nome: this.produtos[indexes[i]].nome,
            ativado: this.produtos[indexes[i]].ativado,
            categoria: this.produtos[indexes[i]].categoria,
            codbarras: this.produtos[indexes[i]].codbarras,
            delivery: this.produtos[indexes[i]].delivery
              ? this.produtos[indexes[i]].delivery
              : null,
            deliveryIF: this.produtos[indexes[i]].deliveryIF
              ? this.produtos[indexes[i]].deliveryIF
              : null,
            descricao: this.produtos[indexes[i]].descricao,
            encomenda: this.produtos[indexes[i]].encomenda
              ? this.produtos[indexes[i]].encomenda
              : null,
            encomendaIF: this.produtos[indexes[i]].encomendaIF
              ? this.produtos[indexes[i]].encomendaIF
              : null,
            img_url: this.produtos[indexes[i]].img_url,
            produtoEncomenda: this.produtos[indexes[i]].produtoEncomenda
              ? this.produtos[indexes[i]].produtoEncomenda
              : null,
            quantidade: this.produtos[indexes[i]].quantidade,
            tamanhoComp: this.produtos[indexes[i]].tamanhoComp,
            valorTamComp: this.produtos[indexes[i]].valorTamComposto[0]
              ? {
                  valor: parseFloat(
                    this.produtos[indexes[i]].valorTamComposto[0].valor
                  ).toFixed(2),
                  descricao:
                    this.produtos[indexes[i]].valorTamComposto[0].descricao,
                  sabores: this.produtos[indexes[i]].valorTamComposto[0].sabores
                    ? this.produtos[indexes[i]].valorTamComposto[0].sabores
                    : 1
                }
              : { valor: "0", descricao: "" },
            saborComp: this.produtos[indexes[i]].saborComp
              ? this.produtos[indexes[i]].saborComp
              : false,
            tamanhoUnic: this.produtos[indexes[i]].tamanhoUnic,
            valor:
              this.produtos[indexes[i]].valorTamComposto[0].valor != null ||
              this.produtos[indexes[i]].valorTamComposto[0].valor != undefined
                ? parseFloat(
                    this.produtos[indexes[i]].valorTamComposto[0].valor.replace(
                      ",",
                      "."
                    )
                  ).toFixed(2)
                : parseFloat(
                    this.produtos[indexes[i]].valor.replace(",", ".")
                  ).toFixed(2),

            valorTamComposto: this.produtos[indexes[i]].valorTamComposto,
            valorSaborComposto: this.produtos[indexes[i]].valorSaborComposto
              ? this.produtos[indexes[i]].valorSaborComposto
              : [],
            saborSelecionado: [this.produtos[indexes[i]].valorSaborComposto[0]],
            adicionaisSelecionados: null,
            verificador: this.produtos[indexes[i]].verificador,
          });
        } else if (this.produtos[indexes[i]].ativado == "true") {
          produtos.push({
            limiteSaborUnic: this.produtos[indexes[i]].limiteSaborUnic,
            nome: this.produtos[indexes[i]].nome,
            ativado: this.produtos[indexes[i]].ativado,
            categoria: this.produtos[indexes[i]].categoria,
            codbarras: this.produtos[indexes[i]].codbarras,
            delivery: this.produtos[indexes[i]].delivery
              ? this.produtos[indexes[i]].delivery
              : null,
            deliveryIF: this.produtos[indexes[i]].deliveryIF
              ? this.produtos[indexes[i]].deliveryIF
              : null,
            descricao: this.produtos[indexes[i]].descricao,
            encomenda: this.produtos[indexes[i]].encomenda
              ? this.produtos[indexes[i]].encomenda
              : null,
            encomendaIF: this.produtos[indexes[i]].encomendaIF
              ? this.produtos[indexes[i]].encomendaIF
              : null,
            img_url: this.produtos[indexes[i]].img_url,
            produtoEncomenda: this.produtos[indexes[i]].produtoEncomenda
              ? this.produtos[indexes[i]].produtoEncomenda
              : null,
            quantidade: this.produtos[indexes[i]].quantidade,
            tamanhoComp: this.produtos[indexes[i]].tamanhoComp,
            valorTamComp: this.produtos[indexes[i]].valorTamComposto[0]
              ? {
                  valor: parseFloat(
                    this.produtos[indexes[i]].valorTamComposto[0].valor
                  ).toFixed(2),
                  descricao:
                    this.produtos[indexes[i]].valorTamComposto[0].descricao,
                  sabores: this.produtos[indexes[i]].valorTamComposto[0].sabores
                    ? this.produtos[indexes[i]].valorTamComposto[0].sabores
                    : 1,
                }
              : { valor: "0", descricao: "" },
            saborComp: this.produtos[indexes[i]].saborComp
              ? this.produtos[indexes[i]].saborComp
              : false,
            tamanhoUnic: this.produtos[indexes[i]].tamanhoUnic,
            valor:
              this.produtos[indexes[i]].valorTamComposto[0].valor != null ||
              this.produtos[indexes[i]].valorTamComposto[0].valor != undefined
                ? parseFloat(
                    this.produtos[indexes[i]].valorTamComposto[0].valor.replace(
                      ",",
                      "."
                    )
                  ).toFixed(2)
                : parseFloat(
                    this.produtos[indexes[i]].valor.replace(",", ".")
                  ).toFixed(2),

            valorTamComposto: this.produtos[indexes[i]].valorTamComposto,
            valorSaborComposto: this.produtos[indexes[i]].valorSaborComposto
              ? this.produtos[indexes[i]].valorSaborComposto
              : [],
            adicionaisSelecionados: null,
            saborSelecionado: null,
            verificador: this.produtos[indexes[i]].verificador,
          });
          console.log(produtos);
        }
      }
      this.produtosPush = produtos;
      this.combos.forEach((val) => {
        if (val.encomenda && val.encomenda == true) {
          this.produtosPush.push(val);
        }
      });
      this.getAdicionais();
    },
  },
  mounted() {
    this.pedido = this.pedidoEdit
      ? JSON.parse(JSON.stringify(this.pedidoEdit))
      : this.pedido;

    this.precisaTele = this.pedido.valorTele ? "true" : "false";
    this.metodoPgto = this.pedidoEdit ? this.pedido.metodoPgto : "";
    if (this.pedidoEdit) {
      for (let produto of this.pedido.produtos) {
        produto.valorTamComp = produto.valorTamComp
          ? produto.valorTamComp
          : produto.tamSelecionado
          ? produto.tamSelecionado
          : "";

        if (produto.nomeSemTamanho) {
          produto.nome = produto.nomeSemTamanho;
        }
        if (produto.adicionaisSelecionados) {
          this.valorAdicionais(
            this.pedido.produtos.findIndex(
              (val) => val.codbarras == produto.codbarras
            )
          );
        }
      }
    }
    this.cliente = this.pedido.dadosCliente ? this.pedido.dadosCliente : this.cliente
    this.cliente.telefone = this.cliente.telefone.replace(/[^a-zA-Z0-9 ]/g, "");
    this.cliente.telefone = this.cliente.telefone.replace(" ", "");
    this.pedido.clienteNum = this.cliente.telefone
      ? "55" + this.cliente.telefone
      : "";
    if (this.pedidoEdit) {
      this.pedidoEdit.clienteNum = this.cliente.telefone
        ? "55" + this.cliente.telefone
        : "";
    }
    // this.cliente.cep =  this.cliente.cep ?  this.cliente.cep.replace(/\D/g, '') : '';
    this.cliente.telefone = this.pedido.clienteNum.substring(2);
    this.getProdutos();
    this.bairros = this.dadosEmpresa.cidades.find((val) => val.id == 1).bairros;
    var dinheiro = { id: 1, nome: "Dinheiro" };
    this.metodosPgto.push(dinheiro);
    if (this.dadosEmpresa.formasPgto) {
      this.dadosEmpresa.formasPgto.forEach((val) => {
        this.metodosPgto.push(val);
      });
    }
    this.mutableMetodosPgto = new Set(this.metodosPgto)
    this.mutableMetodosPgto = Array.from(this.mutableMetodosPgto)
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: solid;
  /* for Chrome, Safari, and Opera */
}

.grey {
  animation: transitionIn 0.75s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.icons {
  font-size: 175%;
}

.v-row .v-card {
  overflow-y: scroll;
  overflow-x: hidden;
}

#card {
  height: 90%;
}
</style>
