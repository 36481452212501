import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../store/store";

export default function getCombos(database) {
  onSnapshot(doc(db, database, "combos"), (doc) => {
    if(doc.data()){
      var data = Object.values(doc.data());
      data = data[0];
      var tempoExtra = 0;
      for (var combo of data) {
        for (var produto of combo.produtos) {
          if (produto.tempoExtra && produto.tempoExtra.ativo == true) {
            if (tempoExtra < produto.tempoExtra.dias) {
              tempoExtra = produto.tempoExtra;
            }
          }
        }
        if (tempoExtra.dias > 0) {
          combo.tempoExtra = tempoExtra;
        }
        tempoExtra = 0;
      }
      store.commit("incrementCombos", data);
    }
  });
}
