import { createStore } from "vuex";
const options = { timeZone: "America/Sao_Paulo" };
const brasiliaTime = new Date().toLocaleString("en-GB", options);
const [day, month, year] = brasiliaTime.split(",")[0].split("/");
let dataInicio = `${year}-${month.padStart(2, "0")}-01`;
let dataFim = `${year}-${month.padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
// Create a new store instance.
const store = createStore({
  state() {
    return {
      selectedDate: dataInicio + ' - ' + dataFim,
      dataFimFinanceiro: dataFim,
      buttonSearch: false,
      dataInicioFinanceiro: dataInicio,
      pedidosFinanceiro: [],
      clientes: [],
      database: null,
      produtos: [],
      categorias: [],
      pedidos: [],
      dadosEmpresa: [],
      pagamento: {
        
      },
      pagamentos: [],
      adicionais: [],
      combos: [],
      dialog: false,
      dialogFi: {
        ativo: false,
        pedido: {},
      },
      dialogPedido: {
        ativo: false,
        pedido: {},
      },
      pedido: null,
      carregando: false,
      count: 0,
      images: [],
    };
  },
  mutations: {
    incrementeSelectedData(state, dados) {
      state.selectedData = dados;
    },
    incrementButtonSearch(state, dados) {
      state.buttonSearch = dados;
    },
    incrementDataFimFinanceiro(state, dados) {
      state.dataFimFinanceiro = dados;
    },
    incrementDataInicioFinanceiro(state, dados) {
      state.dataInicioFinanceiro = dados;
    },
    incrementPedidosFinanceiro(state, dados) {
      state.pedidosFinanceiro = dados;
    },
    incrementImages(state, dados) {
      state.images = dados;
    },
    incrementPagamento(state, dados){
      state.pagamento = dados
    },
    incrementPagamentos(state, dados){
      state.pagamentos = dados
    },
    incrementCount(state, dados) {
      state.count = dados;
    },
    incrementDialogFinanceiro(state, dados) {
      state.dialogFi = dados;
    },
    incrementDialogPedido(state, dados) {
      state.dialogPedido = dados;
    },
    incrementCliente(state, dados) {
      state.clientes = dados;
    },
    incrementCarregando(state, dados) {
      state.carregando = dados;
    },
    incrementPedido(state, dados) {
      state.pedido = dados;
    },
    incrementDatabase(state, dados) {
      state.database = dados;
    },
    incrementProdutos(state, dados) {
      state.produtos = dados;
    },
    incrementCategorias(state, dados) {
      state.categorias = dados;
    },
    incrementAdicionais(state, dados) {
      state.adicionais = dados;
    },
    incrementPedidos(state, dados) {
      state.pedidos = dados;
    },
    incrementDadosEmpresa(state, dados) {
      state.dadosEmpresa = dados;
    },
    incrementDialog(state, dados) {
      state.dialog = dados;
    },
    incrementCombos(state, dados) {
      state.combos = dados;
    },
    incrementSelectedDate(state, dados){
      state.selectedDate = store._state.data.dataInicioFinanceiro  + ' - ' + store._state.data.dataFimFinanceiro + dados;
    }
  },
  getters: {
    // ...
    selectedDate() {
      return store._state.data.selectedDate;
    },
    buttonSearch() {
      return store._state.data.buttonSearch;
    },
    dataFimFinanceiro() {
      return store._state.data.dataFimFinanceiro;
    },
    dataInicioFinanceiro() {
      return store._state.data.dataInicioFinanceiro;
    },
    pedidosFinanceiro() {
      return store._state.data.pedidosFinanceiro;
    },
    pagamentos() {
      return store._state.data.pagamentos;
    },
    pagamento() {
      return store._state.data.pagamento;
    },
    images() {
      return store._state.data.images;
    },
    dialogFi() {
      return store._state.data.dialogFi;
    },
    dialogPedido() {
      return store._state.data.dialogPedido;
    },
    count() {
      return store._state.data.count;
    },
    carregando() {
      return store._state.data.carregando;
    },
    clientes() {
      return store._state.data.clientes;
    },
    pedidoEdit() {
      return store._state.data.pedido;
    },
    database() {
      return store._state.data.database;
    },
    produtos() {
      return store._state.data.produtos;
    },
    categorias() {
      return store._state.data.categorias;
    },
    adicionais() {
      return store._state.data.adicionais;
    },
    combos() {
      return store._state.data.combos;
    },
    pedidos() {
      return store._state.data.pedidos;
    },
    dadosEmpresa() {
      return store._state.data.dadosEmpresa;
    },
    dialog() {
      return store._state.data.dialog;
    },
  },
});

export { store };
