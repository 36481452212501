<template>
  <v-row class="d-flex justify-end mt-n9">
    <v-col md="2" cols="12" class="d-flex flex-column mt-1">
      <v-card
        color="white"
        elevation="0"
        class="d-flex flex-column text-center"
      >
        <div>
          <p class="text-caption">
            {{ contadorMensal }}/30
            <v-icon size="small" :color="this.contadorMensalCor"
              >mdi-chart-areaspline-variant</v-icon
            >
          </p>
        </div>
        <p class="text-caption">PEDIDOS GRÁTIS</p>

        <v-progress-linear
          class="text-white mt-4"
          style="width: 75%"
          height="5"
          rounded="1"
          :model-value="this.contadorMensal * 3"
          :color="this.contadorMensalCor"
        >
        </v-progress-linear>
      </v-card>
    </v-col>
  </v-row>
  <v-row
    class="pt-1 pb-1 d-flex justify-start"
    v-if="this.$route.path.match('/encomendas')"
  >
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/encomendas/recebimentos`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9c27b0;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ce93d8;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-alert-outline</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9c27b0;
          "
        ></div>
      </div>
      <v-card
        color="#9C27B0"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Recebimentos</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.recebidos.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/encomendas/aceitos`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #3f51b5;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9fa8da;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-check</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #3f51b5;
          "
        ></div>
      </div>
      <v-card
        color="#3F51B5"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Aceitos</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.aceitos.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/encomendas/emproducao`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffc107;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffe082;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-gas-burner</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffc107;
          "
        ></div>
      </div>

      <v-card
        color="#FFC107"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Em Producao</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.emProducao.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/encomendas/prontos`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #4caf50;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #a5d6a7;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-thumb-up-outline</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #4caf50;
          "
        ></div>
      </div>
      <v-card
        color="#4CAF50"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Prontos</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.prontos.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row
    class="pt-1 pb-1 d-flex justify-start"
    v-if="this.$route.path.match('/pedidos')"
  >
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/pedidos/recebimentos`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9c27b0;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ce93d8;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-alert-outline</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9c27b0;
          "
        ></div>
      </div>
      <v-card
        color="#9C27B0"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Recebimentos</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.recebidos.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/pedidos/emproducao`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #3f51b5;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #9fa8da;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-gas-burner</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #3f51b5;
          "
        ></div>
      </div>
      <v-card
        color="#3F51B5"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Em Producao</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.emProducao.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/pedidos/pronto`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffc107;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffe082;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-thumb-up-outline</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #ffc107;
          "
        ></div>
      </div>

      <v-card
        color="#FFC107"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Prontos</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.prontos.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
    <v-col cols="6" md="3" class="d-flex flex-column mt-1" style="cursor: pointer" @click="changeRoute(`/pedidos/tele`)">
      <div class="d-flex flex-row">
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #4caf50;
          "
        ></div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #a5d6a7;
          "
          class="text-center"
        >
          <v-icon size="small" color="white">mdi-moped-outline</v-icon>
        </div>
        <div
          style="
            min-height: 10px;
            max-height: 3vh;
            width: 34%;
            background-color: #4caf50;
          "
        ></div>
      </div>
      <v-card
        color="#4CAF50"
        style="
          min-height: 50px;
          min-width: 100%;
          border-top: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        "
        class="d-flex flex-column text-white text-overline"
      >
        <div class="ma-auto">
          <h5>Em Tele</h5>
        </div>
        <div class="ma-auto mt-n2">
          <h5>
            {{ this.tele.length }}
          </h5>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StatusCount",
  data: () => ({
    encomendas: [],
    pedidosPush: [],
    recebidos: [],
    aceitos: [],
    emProducao: [],
    prontos: [],
    contadorTeste: 20,
    tele: [],
    contadorMensal: 0,
    contadorMensalCor: "",
  }),
  watch: {
    pedidos(n) {
      this.getContador(n);
    },
    count(n){
      this.getContador(n);

    }
  },
  computed: {
    ...mapGetters(["pedidos", "count"]),
  },
  methods: {
    changeRoute(n){
      this.$router.push(n)
    },
    getContador(n) {
      (this.encomendas = []),
        (this.pedidosPush = []),
        (this.recebidos = []),
        (this.aceitos = []),
        (this.emProducao = []),
        (this.prontos = []),
        (this.tele = []);
      this.contadorMensal = this.count;

      for (var i = 0; i < n.length; i++) {
        if (this.contadorMensal > 30) {
          this.contadorMensalCor = "blue";
        } else {
          this.contadorMensalCor = "green";
        }
        if (n[i].dataEntrega && this.$route.path.match("/encomendas")) {
          this.encomendas.push(n[i]);
        } else if (!n[i].dataEntrega && this.$route.path.match("/pedidos")) {
          this.pedidosPush.push(n[i]);
        }
      }
      for (var encomenda of this.encomendas) {
        if (encomenda != "") {
          if (encomenda.status == null) {
            this.recebidos.push(1);
          } else if (encomenda.status == "Aceito") {
            this.aceitos.push(1);
          } else if (encomenda.status == "recebido") {
            this.emProducao.push(1);
          } else if (encomenda.status.match("tele")) {
            this.prontos.push(1);
          }
        }
      }
      for (var pedido of this.pedidosPush) {
        const data = new Date().toJSON().substring(0, 10).replace("T", " ");
        const dataOntem = new Date(Date.now() - 86400000)
          .toJSON()
          .substring(0, 10)
          .replace("T", " ");
        if (pedido != "") {
          if (
            data == pedido.data ||
            dataOntem == pedido.data ||
            data == pedido.data
          ) {
            if (pedido.status == null) {
              this.recebidos.push(1);
            } else if (pedido.status == "recebido") {
              this.emProducao.push(1);
            } else if (pedido.status == "pronto") {
              this.prontos.push(1);
            } else if (pedido.status.match("tele")) {
              this.tele.push(1);
            }
          }
        }
      }
    },
  },
  created() {
    this.getContador(this.pedidos);
  },
};
</script>
<style lang="scss" scoped></style>
